import styled from "styled-components";
import { Link } from "react-router-dom";

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;

  > img {
    height: 150px;
    width: 300px;
    margin-bottom: 70px;
    border-radius: 20px;
  }

  > h1 {
    color: #ed4337;
    margin-top: 25px;
    margin-bottom: 0px;
    font-size: 15px;
  }

  > form {
    display: flex;
    flex-direction: column;
    align-items: center;

    > input {
      margin-bottom: 20px;
      margin-top: 0px;
      height: 45px;
      width: 300px;
      padding: 0 15px;
      font-size: 16px;
      background: #ffffff;
      border-radius: 10px;
      border-style: none;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    > button {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      background: #a5553c;
      border-radius: 10px;
      border-style: none;
      height: 45px;
      width: 200px;
      color: #ffffff;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const LinkTo = styled(Link)`
  color: black;
  text-decoration: none;
  margin-top: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;

export const LoginDiv = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-top: 0px;
  height: 45px;
  width: 300px;
  padding: 0 15px;
  font-size: 16px;
  background: #ffffff;
  border-radius: 10px;
  border-style: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const LoginInput = styled.input`
  height: 45px;
  width: 250px;
  font-size: 16px;
  padding: 0px;
  background: #ffffff;
  border-style: none;
  border: none;
`;
