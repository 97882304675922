import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Colors } from '../../utils/theme/constants';

export const NavBar=styled.div`

    background-color: ${Colors.HeaderBackground};
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 5px;
    justify-content: space-around;
    box-shadow: 0 4px 2px -3px #666;
    /* font-size: 0.9vw; */

    @media (min-width: 420px) and (max-width: 1029px) {
        height: 136px;
        flex-wrap: wrap;
    }

    @media (max-width: 420px) {
        height: 204px;
        flex-wrap: wrap;
    }

`

export const LinkItem=styled(Link)`

    display: flex;
    flex-direction: row;
    padding: 15px;
    color: #f5f5f5; 
    text-decoration: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: ${props => props.color}
`