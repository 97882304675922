import styled from 'styled-components';

export const Container = styled.div`
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Content = styled.div`
    color: white;
    background-color: #E6AC86;
    border-radius: 10px;
    padding:30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    h3{
        margin:-10px auto 30px auto;
        font-size:22px;
    }
`;

export const TextoClicavel = styled.div`
    margin: 5px auto;
    >button{
        background-color: transparent;
        border: none;
        margin-bottom: 15px;
        font-size: 15px;
        color: white;
        
    }
    h3{
        border: none;
        margin-bottom: 15px;
        font-size: 15px;
        color: white;

    }
`

export const PreviewImagem = styled.button`
width:220px;
height:30px;
cursor:pointer;
background-color:#A5553C;
border-radius:6px;
font-size:14px;
color:white;
border:none;
`

export const Message = styled.p`
background-color: transparent;
border: none;
margin-bottom: 15px;
font-size: 15px;
color: white;
`

export const EditLink = styled.img`
width:20px;
cursor:pointer;
background-color:white;
border-bottom-right-radius:6px;
border-top-right-radius:6px;
font-size:14px;
height:30px;
padding:0 5px;
border-left:1px solid #A5553C;
`

export const LinkEditavel = styled.button`
width:190px;
height:30px;
cursor:pointer;
background-color:white;
border-bottom-left-radius:6px;
border-top-left-radius:6px;
font-size:14px;
border:none;
padding:0 5px;
overflow-x:hidden;
`

export const InputLink = styled.input`
width:180px;
height:30px;
cursor:pointer;
background-color:white;
border-bottom-left-radius:6px;
border-top-left-radius:6px;
font-size:14px;
border:none;
padding:0 5px;
overflow-x:hidden;
`

export const ContainerEdit = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:20px;
`
