import React from 'react';
import './modal-style.css'

import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

function Modal({id='modal', onClose=()=> {}, children, container}) {

    const handleOutsideClick = (e) => {
        if(e.target.id === id) onClose();
    }

    return (
        <div id={id} className='modal' onClick={handleOutsideClick}> 
            <div className= {container}>
                <button className='close' onClick={()=>onClose()} type="submit">
                    <CloseRoundedIcon fontSize="small" color="action"/>
                </button>
                <div className='content'>{children}</div>
            </div>
        </div>
    );

}
export default Modal;