import React from "react";
import Header from "../../../components/header/header";
import { useEffect, useState } from "react";
import Toast from "../../../components/Toast";
import api from "../../../services/api";
import AdicionarCategoria from "../../../components/modalAdicionar/adicionarCategoria";
import {
  Box,
  Tela,
  Buscar,
  InlineStyle,
  InlineStylePages,
  BotoesNavegar,
  Paginas,
} from "../ingredientes/ingredientes-style";
import BoxCategorias from "../../../components/box-listas/box-categorias";
import SearchIcon from "@material-ui/icons/Search";
import NamePages from "../../../components/NamePages";
import {
  HeaderTable,
  TextHeaderTable,
  ClickableArea,
  ContainerHeaderTable,
} from "../../../components/HeaderTable/styles";
import ButtonOrderBy from "../../../components/buttonOrderBy";
import { RowButtonsOrderby } from "../../../components/buttonOrderBy/styles";
import { Buscar2 } from "../usuarios/usuarios-style";
import Next from "../../../assets/next.svg";
import { Adicionar } from "../../../components/modalAdicionar/adicionar-style";
import { LinkItem } from "../../../components/header/header-style";

function Categorias() {
  const [categorias, setCategorias] = useState([]);
  const [categoriasFiltradas, setCategoriasFiltradas] = useState([]);
  const [busca, setBusca] = useState("");
  const [orderBySelect, setOrderBySelect] = useState("nome");
  const [typeOrder, setTypeOrder] = useState("ascendent");
  const [isEmpty, setIsEmpty] = useState(false);
  const [numeroDaPagina, setNumeroDaPagina] = useState(1);
  const [numeroTotalPaginas, setNumeroTotalPaginas] = useState(1);
  const [paginas] = useState([]);
  
  
  const onPressButtonOrder = (order)=>{
    console.log("order", order)
    console.log("orderBySelect", orderBySelect) 
    if(order === orderBySelect){
      typeOrder === "ascendent"? setTypeOrder("descendent"): setTypeOrder("ascendent")
    }else{
      setTypeOrder("ascendent")
      setOrderBySelect(order)
    }
  }

  const GetCategorias = async () => {
    try {
      const res = await api.get(`/api/categorias?ordering=${typeOrder === "ascendent"? "":"-"}${orderBySelect}&page=1&find=${busca}`);  
      if (res.data.results.length === 0) {
        setIsEmpty(true);
      }  else {
        setIsEmpty(false);
      }
      setCategorias(res.data.results);
      setCategoriasFiltradas(res.data.results);
      setNumeroDaPagina(1);
      setNumeroTotalPaginas(Math.ceil(res.data.count / 15));
      console.log(Math.ceil(res.data.count / 15));
      for (var i = 1; i < res.data.count / 15 + 1; i++) {
        paginas.push(i);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => GetCategorias(), 500);
    return () => clearTimeout(timer);
  }, [busca, orderBySelect,typeOrder]);

  useEffect(() => {
    setCategoriasFiltradas(
      categorias.filter((categoria) =>
        categoria.nome.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(busca.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
      )
    );
  }, [busca, categorias]);

  const PaginaAtual = async (data) => {
    if (data > 0 && data <= numeroTotalPaginas) {
      setNumeroDaPagina(data);
      const response = await api.get(
        `/api/categorias?ordering=${typeOrder === "ascendent"? "":"-"}${orderBySelect}&page=${data}&find=${busca}`
      );
      setCategorias(response.data.results);
      setCategoriasFiltradas(response.data.results);
    }
  };

  const SearchPagina = async (data) => {
    if (data === '') {
      setNumeroDaPagina(1);
      const response = await api.get(
        `/api/categorias?page=${1}&search=${busca}`
      );
      setCategorias(response.data.results);
      setCategoriasFiltradas(response.data.results);
    }

    else {
      setNumeroDaPagina(data);
      const response = await api.get(
        `/api/categorias?page=${data}&search=${busca}`
      );
      setCategorias(response.data.results);
      setCategoriasFiltradas(response.data.results);
    }
  };


  return (
    <>
      <Toast />
      <div>
        <Header />
        <Tela>
          <NamePages BackgroundColor={"#E6AC86"} NamePage={"Categorias"} />
          <InlineStyle>
            <Buscar>
              <SearchIcon />
              <input
                type="text"
                placeholder="Buscar"
                onChange={(event) => setBusca(event.target.value)}
              />
            </Buscar>
            <AdicionarCategoria GetCategorias={GetCategorias} />
          </InlineStyle>
          {isEmpty ? null : (
            <InlineStylePages>
              {numeroDaPagina > 1 && (
                <BotoesNavegar
                  type="button"
                  onClick={() => PaginaAtual(numeroDaPagina - 1)}
                >
                  <img src={Next} alt="Voltar" className="rotateimg180" />
                </BotoesNavegar>
              )}
              <Paginas>
                {numeroDaPagina > 10 ? (
                  <button onClick={() => PaginaAtual(1)}>{1}</button>
                ) : null}
                {numeroDaPagina > 10 ? (
                  <button onClick={() => PaginaAtual(2)}>{2}</button>
                ) : null}
                {numeroDaPagina > 10 ? <h3>...</h3> : null}
                <button
                  onClick={() => PaginaAtual(numeroDaPagina)}
                  style={{ backgroundColor: "#E6AC86" }}
                >
                  {numeroDaPagina}
                </button>
                {numeroDaPagina + 1 > numeroTotalPaginas ? null : (
                  <button onClick={() => PaginaAtual(numeroDaPagina + 1)}>
                    {numeroDaPagina + 1}
                  </button>
                )}
                {numeroDaPagina + 2 > numeroTotalPaginas ? null : (
                  <button onClick={() => PaginaAtual(numeroDaPagina + 2)}>
                    {numeroDaPagina + 2}
                  </button>
                )}
                {numeroDaPagina + 3 > numeroTotalPaginas ? null : (
                  <button onClick={() => PaginaAtual(numeroDaPagina + 3)}>
                    {numeroDaPagina + 3}
                  </button>
                )}
                {numeroDaPagina + 4 > numeroTotalPaginas ? null : (
                  <button onClick={() => PaginaAtual(numeroDaPagina + 4)}>
                    {numeroDaPagina + 4}
                  </button>
                )}
                {numeroDaPagina + 5 > numeroTotalPaginas ? null : (
                  <button onClick={() => PaginaAtual(numeroDaPagina + 5)}>
                    {numeroDaPagina + 5}
                  </button>
                )}
                {numeroDaPagina + 6 > numeroTotalPaginas ? null : (
                  <button onClick={() => PaginaAtual(numeroDaPagina + 6)}>
                    {numeroDaPagina + 6}
                  </button>
                )}
                {numeroDaPagina + 7 > numeroTotalPaginas ? null : (
                  <button onClick={() => PaginaAtual(numeroDaPagina + 7)}>
                    {numeroDaPagina + 7}
                  </button>
                )}
                {numeroDaPagina + 8 > numeroTotalPaginas ? null : (
                  <button onClick={() => PaginaAtual(numeroDaPagina + 8)}>
                    {numeroDaPagina + 8}
                  </button>
                )}
                {numeroDaPagina + 9 > numeroTotalPaginas ? null : (
                  <button onClick={() => PaginaAtual(numeroDaPagina + 9)}>
                    {numeroDaPagina + 9}
                  </button>
                )}
                {numeroDaPagina !== numeroTotalPaginas &&
                numeroTotalPaginas > 5 ? (
                  <h3>...</h3>
                ) : null}
                {numeroDaPagina !== numeroTotalPaginas &&
                numeroTotalPaginas > 5 ? (
                  <button onClick={() => PaginaAtual(numeroTotalPaginas)}>
                    {numeroTotalPaginas}
                  </button>
                ) : null}
              </Paginas>
              {numeroDaPagina < numeroTotalPaginas && (
                <BotoesNavegar
                  type="button"
                  onClick={() => PaginaAtual(numeroDaPagina + 1)}
                >
                  <img src={Next} alt="Avançar" />
                </BotoesNavegar>
              )}
              <Buscar2>
              <input
                type="number"
                placeholder="Página..."
                onChange={(event) => {SearchPagina(event.target.value)}}
              />
              </Buscar2>
            </InlineStylePages>
          )}
          <RowButtonsOrderby>
          <ButtonOrderBy   
          onPress={()=>{onPressButtonOrder('nome')}}                       
          title={typeOrder === "ascendent"? "clique para ordenar de forma descendente": "clique para ordenar de forma ascendete"}
           isSelected ={orderBySelect === 'nome'? true: false} 
           type="string" 
           ascendent={typeOrder}  
           text="Nome"
           />
            

            <LinkItem to="/Categorias/Ordenar">
              <Adicionar>
                <button style={{color:"white"}} >Ordenar Categorias</button>
              </Adicionar>
            </LinkItem>

        </RowButtonsOrderby>
          <HeaderTable
            backgroundColor=  "#A5553C"
          >
            <ContainerHeaderTable
              width="80"
              minWidth="100"
              justify="start"
              marginLeft={30}
              marginRight={10}
            >
              <ClickableArea
                // title={
                //   orderBySelect === "nome"
                //     ? "Retirar Ordenação"
                //     : "Ordenar por Nome"
                // }
                // onClick={() => {
                //   orderBy("nome");
                // }}
                // isSelect={orderBySelect === "nome" ? true : false}
              >
                <TextHeaderTable>Nome</TextHeaderTable>
              </ClickableArea>
            </ContainerHeaderTable>
            <ContainerHeaderTable
        // width="15"
        minWidth="90"
        justify="space-evenly"
        marginLeft={0}
        marginRight={30}
            >
              <ClickableArea>
                <TextHeaderTable>Opções</TextHeaderTable>
              </ClickableArea>
            </ContainerHeaderTable>
          </HeaderTable>
          <Box>
            {categoriasFiltradas.map((data) => (
              <div key={data.id}>
                <BoxCategorias
                  nome={data.nome}
                  imagem={data.image_url}
                  id={data.id}
                  GetCategorias={GetCategorias}
                />
              </div>
            ))}
          </Box>
        </Tela>
      </div>
    </>
  );
}
export default Categorias;
