import React, { useState, useEffect, useRef } from "react";
// import '../../global-styles.css';
import api from "../../services/api";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AiOutlinePlus } from "react-icons/ai";
import { toast } from "react-toastify";
import { toastProps } from "../../utils/toastProps.js";
import { AddButton } from "./styles";

function Busca(props) {
  const autoCompleteRef = useRef(null);
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");

  const loadOldChoices = () => {
    let arrayQuery = [];
    arrayQuery.push(query);
    arrayQuery = arrayQuery.concat(props.listOld);

    arrayQuery = arrayQuery.map((item) =>
      item.nome === undefined ? item : item.nome
    );

    return arrayQuery;
  };
  
  useEffect(() => {
    setValue(props.listOld)
  }, [props.listOld])

  useEffect(() => {
    setLoading(true);
    let active = true;
    (async () => {
      let arrayQuery = loadOldChoices();
      const responseResults = await api.get(`${props.url}?find=${arrayQuery[0]}&ordering=nome`)
      console.log("request:", `${props.url}?find=${arrayQuery[0]}&ordering=nome`)

      if (active) {
        // do map and set options
        console.log("responseResults",responseResults)
        setOptions(() => {
          const newOptions = responseResults.data?.results.map((item) => {
            return item;
          });
          return [...newOptions];
        });
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    props.onChange(response);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const changeQuery = (event, newInputValue) => {
    event.preventDefault();
    setQuery(newInputValue);
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await api.post(`${props.url}`, { nome: query });
      const response = await api.get(`${props.url}?find=${query}`);
      setOptions(
        Object.keys(response.data.results).map(
          (key) => response.data.results[key]
        )
      );
      toast.success("Novo ingrediente adicionado com sucesso", toastProps);
    } catch (error) {
      console.log(error);
      toast.error("Erro ao adicionar ingrediente", toastProps);
    }
    setLoading(false);
  };

  return (
    <div>
      <Autocomplete
        ref={autoCompleteRef}
        value={value}
        multiple
        style={{ width: 300 }}
        open={open}
        loadingText="Carregando..."
        noOptionsText="Nenhum resultado encontrado"
        disableCloseOnSelect={true}
        clearOnBlur={false}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(event, values) => {
          console.log(values);
          setResponse(values);
          setValue(values);
        }}
        inputValue={query}
        onInputChange={(event, newInputValue) => {
          changeQuery(event, newInputValue);
        }}
        getOptionLabel={(option) => option.nome}
        getOptionSelected={(option, value) => option.id === value.id}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {props.addAction && (
        <div>
          {options.length === 0 && !loading && query !== "" && (
            <AddButton onClick={handleAdd}>
              <AiOutlinePlus color="#3f51b5" />
            </AddButton>
          )}
        </div>
      )}
    </div>
  );
}
export default Busca;
