import styled from "styled-components";


export const RowContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
gap: 30px
`

export const InputContainer = styled.div`
flex: 1;
display: flex;
flex-direction: column;
`

export const LabelForInput = styled.p`
`

export const InputForModal = styled.input`
width: 100%;
height: 24px;
`

export const ButtonArea = styled.div`
margin-top: 30px;
width: 80%;
gap:50px;
display: flex;
align-items: center;
justify-content: center;

`

export const IconImage = styled.img`
width: ${props=>props.width}px;
height: ${props=>props.height}px;
cursor: pointer;
`


export const HeaderQuestionArea = styled.div`
  width: 100%;
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const OptionsArea = styled.div`
  width: 50px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BackgroundModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const TitleModalArea = styled.div`
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.p`
  font-size: 20px;
`;

export const ButtonsArea = styled.div`
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
`;

export const ButtonText = styled.p`
  font-size: 16px;
`;
export const IconOptions = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

//para alguns icones que infelizmente sao usados com botoes ao inves de img 

export const ButtonIcon = styled.button`
display: flex;
align-items: center;
justify-content: center;
`


export const ItemContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;
border: 1px solid red;
`

export const TextTable = styled.p`
font-size: ${props=> props.fontSize? props.fontSize: 16}px;
/* font-size: 1vw; */
text-align: center;
`


