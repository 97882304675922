import React, { useState } from 'react';
import {Body} from '../login/login-style';
import {Text} from './recuperar-style';
import api from '../../services/api';
import logo from '../../assets/logoFundoTransparente.png';


export default function Recuperar(){

    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");


    async function PostEmail(e){

        try{
            e.preventDefault();
            await api.post('/auth/users/reset_password/', {email: email});
        }finally{
            setMessage('Verifique sua caixa de entrada');
        }
    };

    return(
        <Body>
            <img src={logo} alt="RotuloNude"/>
            <form>
                <Text>
                    <label>Esqueci minha senha</label>
                </Text>
                <input
                    type="email"
                    placeholder="Insira seu email cadastrado"
                    id="email"
                    onChange={(event) => setEmail(event.target.value)}
                />
                <Text>
                    <p>Enviaremos um email para recadastrar sua senha</p>
                    <p>{message}</p>
                </Text>
                <button
                    type="submit"
                    onClick = {(e) => PostEmail(e)}
                >Enviar</button>
            </form>
           
        </Body>
    )
}