import styled from "styled-components";

export const BackgroundHeader = styled.div`
width: 100%;
height: 50px;
margin-bottom: 4vh;
/* border: 1px solid red; */
background-color: ${props => props.BackgroundColor};
display: flex;
align-items: center;
justify-content: center;
`

export const HeaderTitle = styled.h2`
font-size: 22px;
color: #FFFFFF;
/* border: 1px solid red; */
`