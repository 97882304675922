import React, { useState } from "react";
import "../../global-styles.css";
import Plus from "../../assets/plus.svg";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { Adicionar } from "./adicionar-style";
import api from "../../services/api";
import Modal from "../modal/modal.js";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import ReactLoading from "react-loading";
import ErrorMessage from "../ErrorMessage";
import { TextoClicavel } from "../modalAdicionar/adicionar-style.js";
import { TextAreaModal, TextDivModal } from "../TextField/styles";
import { Colors } from "../../utils/theme/constants";

function AdicionarCategoria({ GetCategorias }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [messageIsOpen, setMessageIsOpen] = useState(false);
  const [nome, setNome] = useState("");
  const [imagem, setImagem] = useState([]);
  const [nomeImagem, setNomeImagem] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  let urlImagem = "";

  const [nameErro, setNameErro] = useState(false);

  const checkErrors = () => {
    let hasErrors = false;
    if (nome === "") {
      setNameErro(true);
      hasErrors = true;
    } else {
      setNameErro(false);
    }

    return hasErrors;
  };

  const adicionarCategoria = async (e) => {
    e.preventDefault();

    if (checkErrors()) return;

    setLoading(true);
    try {
      const response = await api.get("/auth/users/me/");
      const bodyHistory = {
        user: response.data.id,
        model_name: nome,
        model_type: "Categoria",
        operation: "+",
      };
      await api.post("/api/editionhistory/", bodyHistory);
    } catch (error) {
      console.log(error);
    }

    const body = {
      nome: nome,
      image_url: nomeImagem,
    };

    try {
      const res = await api.post("/api/categorias/", body);
      GetCategorias();
      setModalIsOpen(false);
      setMessageIsOpen(true);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const uploadFile = async (e) => {
    e.preventDefault();
    setLoadingUpload(true);
    if (imagem.length === 0) {
      return true;
    }
    const typeNovo = imagem.type.substring([6]);
    await api
      .get("/api/file/upload/" + typeNovo + "/")
      .then((res) => {
        urlImagem = res.data.media_url;
        setNomeImagem(res.data.file_name);
        let foo = imagem.slice(0, imagem.size, imagem.type);
        let bar = new File([foo], res.data.file_name, {
          type: imagem.type,
        });
        const formData = new FormData();
        formData.append("file_name", res.data.file_name);
        formData.append("file", imagem);
        console.log("res.data.file_name", res.data.file_name);
        console.log("imagem", imagem);

        api
          .post("/api/file/upload/" + typeNovo + "/", formData)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
    setLoadingUpload(false);
    return true;
  };

  return (
    <Adicionar>
      <button type="button" onClick={() => setModalIsOpen(true)}>
        <p style={{ color: Colors.White }}>Adicionar Categoria</p>
        {/* <img src={Plus} alt="Mais" /> */}
      </button>

      {modalIsOpen ? (
        <Modal
          container="containerAdicionar"
          onClose={() => setModalIsOpen(false)}
        >
          <p style={{ fontSize: "20px", fontWeight: "bold" }}>
            Adicionar Categoria
          </p>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <label htmlFor="nome">
              Nome<strong style={{ color: "red" }}>*</strong>
            </label>
            <TextDivModal height="40">
              <TextAreaModal
                type="text"
                id="nome"
                value={nome}
                onChange={(event) => setNome(event.target.value)}
              />
            </TextDivModal>

            <ErrorMessage isVisible={nameErro} message="Preencha o Nome" />

            <label htmlFor="imagem">Imagem</label>
            <input
              type="file"
              onChange={(event) => setImagem(event.target.files[0])}
              accept="image/*"
            />

            {loadingUpload && (
              <ReactLoading
                type="spokes"
                color="#E6AC86"
                height={20}
                width={20}
              />
            )}
            <TextoClicavel>
              <button className="confirmar" onClick={(e) => uploadFile(e)}>
                Confirmar imagem
              </button>
            </TextoClicavel>

            <button
              type="submit"
              onClick={(e) => adicionarCategoria(e)}
              className="enviar"
              style={{ cursor: "pointer" }}
            >
              {loading ? (
                <ReactLoading type="spin" color="#fff" height={20} width={20} />
              ) : (
                "Adicionar"
              )}
            </button>
          </form>
        </Modal>
      ) : null}

      {messageIsOpen ? (
        <Modal
          container="containerAviso"
          onClose={() => {
            setMessageIsOpen(false);
            GetCategorias();
          }}
        >
          <p>Categoria Adicionada com Sucesso!</p>
        </Modal>
      ) : null}
    </Adicionar>
  );
}
export default AdicionarCategoria;
