import React, { useState } from "react";
import "../../global-styles.css";
import api from "../../services/api";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { Adicionar, TextoClicavel } from "./adicionar-style";
import Modal from "../modal/modal.js";
import axios from "axios";
import Busca from "../busca/busca.js";
import { TextField } from "@material-ui/core";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import { toastProps } from "../../utils/toastProps";
import ErrorMessage from "../ErrorMessage";
import { TextAreaModal, TextDivModal } from "../TextField/styles";
import { Colors } from "../../utils/theme/constants";

function AdicionarTrending({ getTrending }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalListaIsOpen, setModalListaIsOpen] = useState(false);
  const [modalInstagramIsOpen, setModalInstagramIsOpen] = useState(false);
  const [messageIsOpen, setMessageIsOpen] = useState(false);

  const [titulo, setTitulo] = useState("");
  const [texto, setTexto] = useState("");
  const [imagem, setImagem] = useState([]);
  const [nomeImagem, setNomeImagem] = useState("");
  const [link, setLink] = useState("");
  const [mensagemErro, setMensagemErro] = useState("");
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loading, setLoading] = useState(false);

  const [titleError, setTitleError] = useState(false);
  const [imagemError, setImagemError] = useState(false);
  const [LinkError, setLinkError] = useState(false);
  const [textError, setTextError] = useState(false);

  const checkErrors = () => {
    let hasErrors = false;

    if (titulo === "") {
      setTitleError(true);
      hasErrors = true;
    } else {
      setTitleError(false);
    }

    if (nomeImagem === "") {
      setImagemError(true);
      hasErrors = true;
    } else {
      setImagemError(false);
    }

    if (modalInstagramIsOpen && link === "") {
      setLinkError(true);
      hasErrors = true;
    } else {
      setLinkError(false);
    }

    if (modalListaIsOpen && texto === "") {
      setTextError(true);
      hasErrors = true;
    } else {
      setTextError(false);
    }

    return hasErrors;
  };

  const putImagem = async (event) => {
    event.preventDefault();
    const typeNovo = imagem.type.substring([6]);
    setNomeImagem("");
    setLoadingUpload(true);
    api
      .get("/api/file/" + typeNovo + "/")
      .then((response) => {
        const mediaUrl = response.data.media_url;
        const fileName = response.data.file_name;

        let foo = imagem.slice(0, imagem.size, imagem.type);
        let bar = new File([foo], response.data.file_name, {
          type: imagem.type,
        });

        axios
          .put(`${mediaUrl}`, bar, {
            headers: {
              "x-amz-acl": "public-read",
              "Content-Type": bar.type,
              "Content-Disposition": "attachment",
            },
          })
          .then((res) => {
            setNomeImagem(() => fileName);
            setLoadingUpload(false);
          });
      })

      .catch((err) => console.error(err));
  };

  const adicionarDestaque = async (e) => {
    e.preventDefault();

    if (checkErrors()) return;

    setLoading(true);
    try {
      const response = await api.get("/auth/users/me/");
      const bodyHistory = {
        user: response.data.id,
        model_name: titulo,
        model_type: "Trending",
        operation: "+",
      };
      await api.post("/api/editionhistory/", bodyHistory);
    } catch (error) {
      console.log(error);
    }

    const body = {
      nome: titulo,
      texto: texto,
      url_insta: link,
      image_url: nomeImagem,
    };
    try {
      await api.post("/api/trending/", body);
      setMessageIsOpen(true);
    } catch (error) {
      toast.error("Erro ao adicionar destaque", toastProps);
    }
    setLoading(false);
  };
  const handleCloseMessage = () => {
    setMessageIsOpen(false);
    getTrending();
    setModalIsOpen(false);
    setModalInstagramIsOpen(false);
    setModalListaIsOpen(false);
  };
  return (
    <Adicionar>
      <button type="button" onClick={() => setModalIsOpen(true)}>
        <p style={{color: Colors.White }}>Adicionar Destaque</p>
        {/* <AddRoundedIcon color="action" /> */}
      </button>
      {modalIsOpen ? (
        <Modal container="containerAviso" onClose={() => setModalIsOpen(false)}>
          <p style = {{fontSize: '20px', fontWeight: 'bold'}}>Adicionar Destaque</p>
          <p>Que tipo de destaque você deseja adicionar?</p>
          <div className="inline">
            <button
              type="button"
              onClick={() => setModalListaIsOpen(true)}
              className="botaoSubmit"
            >
              Adicionar texto em destaque
            </button>
            <button
              type="button"
              onClick={() => setModalInstagramIsOpen(true)}
              className="botaoSubmit"
            >
              Adicionar Post do Instagram
            </button>
          </div>
        </Modal>
      ) : null}
      {modalListaIsOpen ? (
        <Modal
          container="containerAdicionar"
          onClose={() => setModalListaIsOpen(false)}
        >
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          > 
            <p style = {{fontSize: '20px', fontWeight: 'bold'}}>Adicionar Texto em Destaque</p>
            <label id="titulo">Título<strong style={{ color: "red" }}>*</strong></label>
           <TextDivModal
           height="40"
           >
            <TextAreaModal
              style={{ width: 300}}
              id="titulo"
              type="text"
              placeholder="Insira um título para seu texto"
              onChange={(event) => setTitulo(event.target.value)}
            />
            </TextDivModal>
            <ErrorMessage isVisible={titleError} message="Preencha o titulo" />

            <label id="texto">Texto<strong style={{ color: "red" }}>*</strong></label>
            <TextDivModal
            height="40"
            >
            <TextAreaModal
              style={{ width: 300 }}
              id="texto"
              type="text"
              placeholder="Insira um texto"
              onChange={(event) => setTexto(event.target.value)}
            />
            </TextDivModal>
            <ErrorMessage isVisible={textError} message="Preencha o texto" />

            <label id="imagem" style={{ marginTop: "30px" }}>
              Imagem
            </label>
            <TextDivModal
            height="40">
            <TextAreaModal
              style={{ width: 300}}
              type="file"
              onChange={(event) => setImagem(event.target.files[0])}
              accept="image/*"
            />
            </TextDivModal>
            {loadingUpload && (
              <ReactLoading
                type="spokes"
                color="#E6AC86"
                height={20}
                width={20}
              />
            )}
            <TextoClicavel>
              <button type="button" onClick={(event) => putImagem(event)}>
                Confimar Imagem
              </button>
            </TextoClicavel>
            <button
              type="submit"
              onClick={(e) => adicionarDestaque(e)}
              className="enviar"
            >
              {loading ? (
                <ReactLoading type="spin" color="#fff" height={20} width={20} />
              ) : (
                "Adicionar"
              )}
            </button>
          </form>
          <p style={{ color: "#ED4337" }}>{mensagemErro}</p>
        </Modal>
      ) : null}
      {modalInstagramIsOpen ? (
        <Modal
          container="containerAdicionar"
          onClose={() => setModalInstagramIsOpen(false)}
        >
          <form>
            <p style = {{fontSize: '20px', fontWeight: 'bold'}}>Adicionar Post do Instagram</p>
            <label id="titulo">Titulo<strong style={{ color: "red" }}>*</strong></label>
            <TextDivModal
            height="40"
            >
            <TextAreaModal
              style={{ width: 300 }}
              type="text"
              id="titulo"
              placeholder="Insira um texto para seu link"
              onChange={(event) => setTitulo(event.target.value)}
            />
            </TextDivModal>
            <ErrorMessage isVisible={titleError} message="Preencha o titulo" />

            <label id="link">Link do Post<strong style={{ color: "red" }}>*</strong></label>
            <TextDivModal
            height="40"
            >
            <TextAreaModal
              style={{ width: 300}}
              id="link"
              type="text"
              placeholder="Insira o Link para um Post do Instagram"
              onChange={(event) => setLink(event.target.value)}
            />
            </TextDivModal>
            <ErrorMessage isVisible={LinkError} message="Preencha o link" />

            <label id="imagem">Imagem</label>
            <TextDivModal
            height="40"
            >
            <TextAreaModal
              style={{ width: 300}}
              type="file"
              onChange={(event) => setImagem(event.target.files[0])}
              accept="image/*"
            />
            </TextDivModal>
            {loadingUpload && (
              <ReactLoading
                type="spokes"
                color="#E6AC86"
                height={20}
                width={20}
              />
            )}
            <TextoClicavel>
              <button type="button" onClick={(event) => putImagem(event)}>
                {" "}
                Confimar Imagem{" "}
              </button>
            </TextoClicavel>
            <button
              type="submit"
              value="Adicionar"
              onClick={(e) => adicionarDestaque(e)}
              className="enviar"
            >
              {loading ? (
                <ReactLoading type="spin" color="#fff" height={20} width={20} />
              ) : (
                "Confirmar"
              )}
            </button>
          </form>
          <p style={{ color: "#ED4337" }}>{mensagemErro}</p>
        </Modal>
      ) : null}
      {messageIsOpen ? (
        <Modal container="containerAviso" onClose={handleCloseMessage}>
          <p>Destaque adicionado com sucesso!</p>
        </Modal>
      ) : null}
    </Adicionar>
  );
}
export default AdicionarTrending;
