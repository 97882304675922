import styled from 'styled-components';

export const Box=styled.div`
    
    width: 90vw;
    max-height: 67vh;
    border-radius: 0px 0px 10px 10px;
    padding: 10px;
    background-color: rgb(135, 159, 133);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
        gap: 12px;

    &::-webkit-scrollbar{
        width: 8px;
        background-color: transparent;
        border-radius: 50px; 
    }
    &::-webkit-scrollbar-thumb{
        border-radius: 10px;
        background-color: rgba(102, 102, 102, 0.5);
    }
   
`


export const Tela=styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const InlineStyle=styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90vw;
    margin-bottom: 0px;
    margin-top: 10px;
`

export const Buscar = styled.div`
    width: 35vw;
    max-height: 67vh;
    border-radius: 10px;
    padding: 10px;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
  
    >input{
        border: none;
        background-color: transparent;
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        margin-left: 15px;
        outline: none;
        width: 35vw;
    }
`
export const Buscar2 = styled.div`
    margin-bottom: 10px;
    margin-left: 15px;
    width: 8vw;
    max-height: 67vh;
    border-radius: 10px;
    padding: 10px;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
  
    >input{
        border: none;
        background-color: transparent;
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        margin-left: 15px;
        outline: none;
        width: 100%;
    }
`

// export const Buscar=styled.div`
//     >input{
//         border: none;
//         border-bottom: 2px solid rgba(102, 102, 102, 0.5);
//         border-radius: 1px;
//         background-color: transparent;
//         font-size: 20px;
//         font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
//     }
// `

export const NavegarNasPaginas=styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 90vw;
    margin: 0px;
    >button{
        width: 20px;
        height: 20px;
        border: none;
        padding: 3px;
        margin: 2px;
        align-items: center;
        background-color: transparent;
       
        >p{
            padding-left: 100px
        }
        >img{
            width: 15px;
            height: 15px;
            fill: red;
        }
    }
`