import styled from "styled-components";

export const DropDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8vh;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: white;
  border-radius: 7px;
  margin-top: 2vh;
  margin-bottom: 2vh;
`;

export const OptionsArea = styled.div`
display: flex;
gap: 30px;
padding: 10px 20px;
`

export const AreaDrop = styled.div`
  width: 100%;
  margin-bottom: 5vh;
`;

export const DroppableDiv = styled.div`
  background-color: #e6ac86;
  min-height: 50vh;
`;

export const H1text = styled.text`
  text-align: start;
  margin-left: 2vw;
  font-size: 20px;
  color: #0e1111;
  font-weight: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;
