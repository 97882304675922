import React from "react";
import Header from "../../../components/header/header";
import BoxUsuarios from "../../../components/box-listas/box-usuarios";
import Toast from "../../../components/Toast";
import { Box, Tela, Buscar, InlineStyle } from "./usuarios-style";
import {
  BotoesNavegar,
  Paginas,
  InlineStylePages,
} from "../ingredientes/ingredientes-style";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import Next from "../../../assets/next.svg";
import AdicionarAdmin from "../../../components/modalAdicionar/adicionarAdmin";
import { getToken } from "../../../services/auth";
import { toast } from "react-toastify";
import { toastProps } from "../../../utils/toastProps.js";
import NamePages from "../../../components/NamePages";
import {
  ClickableArea,
  ContainerHeaderTable,
  HeaderTable,
  TextHeaderTable,
} from "../../../components/HeaderTable/styles";
import SearchIcon from "@material-ui/icons/Search";
import ButtonOrderBy from "../../../components/buttonOrderBy";
import { RowButtonsOrderby } from "../../../components/buttonOrderBy/styles";
import { useHistory } from "react-router";

function Usuarios() {
  const [usuario, setusuario] = useState([]);
  const [numeroDaPagina, setNumeroDaPagina] = useState(1);
  const [numeroTotalPaginas, setNumeroTotalPaginas] = useState(1);
  const [isEmpty, setIsEmpty] = useState(false);
  const [busca, setBusca] = useState("");
  const [orderBySelect, setOrderBySelect] = useState("nome");
  const [typeOrder, setTypeOrder] = useState("ascendent");
  const history = useHistory();

  const onPressButtonOrder = (order) => {
    console.log("order", order);
    console.log("orderBySelect", orderBySelect);
    if (order === orderBySelect) {
      typeOrder === "ascendent"
        ? setTypeOrder("descendent")
        : setTypeOrder("ascendent");
    } else {
      setTypeOrder("ascendent");
      setOrderBySelect(order);
    }
  };

  // const getUsers = () => {
  //   setBusca('');
  //   api
  //     .get("/api/users/")
  //     .then(function (response) {
  //       if (response.data.results.length === 0) {
  //         setIsEmpty(true);
  //       }
  //       setusuario(response.data.results);
  //       setNumeroDaPagina(1);
  //       setNumeroTotalPaginas(Math.ceil(response.data.count / 15));
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // useEffect(() => {
  //   getUsers();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  async function fetchUsuarios() {
    setNumeroDaPagina(1);
    //grande gambiarra
    let url = "";

    if (orderBySelect === "nome") {
      if (typeOrder === "ascendent") {
        url = `/api/users?ordering=first_name,last_name&page=1&find=${busca}`;
      } else {
        url = `/api/users?ordering=-first_name,-last_name&page=1&find=${busca}`;
      }
    } else {
      url = `/api/users?ordering=${
        typeOrder === "ascendent" ? "" : "-"
      }${orderBySelect}&page=1&find=${busca}`;
    }
    console.log("url", url);
    const response = await api.get(url);
    console.log(response.data.results);
    setusuario(response.data.results);
    setNumeroDaPagina(1);
    setNumeroTotalPaginas(Math.ceil(response.data.count / 15));
  }
  useEffect(() => {
    const timer = setTimeout(() => fetchUsuarios(), 500);

    return () => clearTimeout(timer);
  }, [busca, orderBySelect, typeOrder]);

  const updateUser = async (id, firstName, lastName, email) => {
    try {
      const token = getToken();
      const editUserRequest = await api.patch(`/auth/users/${id}/`, {
        email: email,
        first_name: firstName,
        last_name: lastName,
      });

      console.log("\nedituserrequest", editUserRequest);

      if (editUserRequest.status === 200) {
        toast.success("O usuário foi editado com sucesso!", toastProps);
        console.log("\ndeu certo");
        fetchUsuarios();
      }
    } catch (error) {
      toast.error("Erro ao editar usuário!", toastProps);
      console.log("\nerro ao editar:", error);
    }
  };

  const PaginaAtual = async (data) => {
    if (data > 0 && data <= numeroTotalPaginas) {
      setNumeroDaPagina(data);
      //grande gambiarra
      let url = "";

      if (orderBySelect === "nome") {
        if (typeOrder === "ascendent") {
          url = `/api/users?ordering=first_name,last_name&find=${busca}&page=${data}`;
        } else {
          url = `/api/users?ordering=-first_name,-last_name&find=${busca}&page=${data}`;
        }
      } else {
        url = `/api/users?ordering=${
          typeOrder === "ascendent" ? "" : "-"
        }${orderBySelect}&find=${busca}&page=${data}`;
      }
      console.log("url", url);
      const response = await api.get(url);

      console.log("url requisicao2", url);
      console.log("\nresposta requisicao2", response.data.results);

      setusuario(response.data.results);
    }
  };

  return (
    <>
      <Toast />
      <Header />
      <NamePages BackgroundColor={"#879F85"} NamePage={"Usuários"} />
      <Tela>
        <InlineStyle>
          <Buscar>
            <SearchIcon />
            <input
              type="text"
              placeholder="Buscar"
              onChange={(event) => setBusca(event.target.value)}
              value={busca}
            />
          </Buscar>
          <AdicionarAdmin getUsers={fetchUsuarios} />
        </InlineStyle>
        {isEmpty ? null : (
          <InlineStylePages>
            {numeroTotalPaginas > 1 && numeroDaPagina > 1 ? (
              <BotoesNavegar
                type="button"
                onClick={() => PaginaAtual(numeroDaPagina - 1)}
              >
                <img src={Next} alt="Voltar" className="rotateimg180" />
              </BotoesNavegar>
            ) : null}
            <Paginas>
              {numeroDaPagina > 10 ? (
                <button onClick={() => PaginaAtual(1)}>{1}</button>
              ) : null}
              {numeroDaPagina > 10 ? (
                <button onClick={() => PaginaAtual(2)}>{2}</button>
              ) : null}
              {numeroDaPagina > 10 ? <h3>...</h3> : null}
              <button
                onClick={() => PaginaAtual(numeroDaPagina)}
                style={{ backgroundColor: "rgb(135, 159, 133)" }}
              >
                {numeroDaPagina}
              </button>
              {numeroDaPagina + 1 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 1)}>
                  {numeroDaPagina + 1}
                </button>
              )}
              {numeroDaPagina + 2 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 2)}>
                  {numeroDaPagina + 2}
                </button>
              )}
              {numeroDaPagina + 3 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 3)}>
                  {numeroDaPagina + 3}
                </button>
              )}
              {numeroDaPagina + 4 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 4)}>
                  {numeroDaPagina + 4}
                </button>
              )}
              {numeroDaPagina + 5 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 5)}>
                  {numeroDaPagina + 5}
                </button>
              )}
              {numeroDaPagina + 6 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 6)}>
                  {numeroDaPagina + 6}
                </button>
              )}
              {numeroDaPagina + 7 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 7)}>
                  {numeroDaPagina + 7}
                </button>
              )}
              {numeroDaPagina + 8 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 8)}>
                  {numeroDaPagina + 8}
                </button>
              )}
              {numeroDaPagina + 9 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 9)}>
                  {numeroDaPagina + 9}
                </button>
              )}
              {numeroDaPagina !== numeroTotalPaginas &&
              numeroTotalPaginas > 5 ? (
                <h3>...</h3>
              ) : null}
              {numeroDaPagina !== numeroTotalPaginas &&
              numeroTotalPaginas > 5 ? (
                <button onClick={() => PaginaAtual(numeroTotalPaginas)}>
                  {numeroTotalPaginas}
                </button>
              ) : null}
            </Paginas>
            {numeroTotalPaginas > 1 && numeroDaPagina < numeroTotalPaginas ? (
              <BotoesNavegar
                type="button"
                onClick={() => PaginaAtual(numeroDaPagina + 1)}
              >
                <img src={Next} alt="Avançar" />
              </BotoesNavegar>
            ) : null}
          </InlineStylePages>
        )}
        <RowButtonsOrderby>
          <ButtonOrderBy
            onPress={() => {
              onPressButtonOrder("nome");
            }}
            title={
              typeOrder === "ascendent"
                ? "clique para ordenar de forma descendente"
                : "clique para ordenar de forma ascendete"
            }
            isSelected={orderBySelect === "nome" ? true : false}
            type="string"
            ascendent={typeOrder}
            text="Nome"
          />
          <ButtonOrderBy
            onPress={() => {
              onPressButtonOrder("email");
            }}
            title={
              typeOrder === "ascendent"
                ? "clique para ordenar de forma descendente"
                : "clique para ordenar de forma ascendete"
            }
            isSelected={orderBySelect === "email" ? true : false}
            type="string"
            ascendent={typeOrder}
            text="Email"
          />
        </RowButtonsOrderby>
        <HeaderTable backgroundColor="#ADB9AB">
          <ContainerHeaderTable
            justify="start"
            marginLeft={30}
            marginRight={10}
            width={38}
          >
            {/* <ClickableArea
              title={
                orderBySelect === "nome"
                  ? "Retirar Ordenação"
                  : "Ordenar por Nome"
              }
              onClick={() => {
                orderBy("nome");
              }}
              isSelect={orderBySelect === "nome" ? true : false}
            > */}
            <TextHeaderTable>Nome</TextHeaderTable>
            {/* </ClickableArea> */}
          </ContainerHeaderTable>
          <ContainerHeaderTable
            justify="start"
            marginRight={10}
            width={20}
            minWidth={300}
          >
            {/* <ClickableArea
              title={
                orderBySelect === "email"
                  ? "Retirar Ordenação"
                  : "Ordenar por email"
              }
              onClick={() => {
                orderBy("email");
              }}
              isSelect={orderBySelect === "email" ? true : false}
            > */}
            <TextHeaderTable>Email</TextHeaderTable>
            {/* </ClickableArea> */}
          </ContainerHeaderTable>
          <ContainerHeaderTable
            justify="center"
            marginRight={10}
            width={20}
            minWidth={200}
          >
            {/* <ClickableArea
              title={
                orderBySelect === "Admin"
                  ? "Retirar Ordenação"
                  : "Ordenar por Admin"
              }
              onClick={() => {
                orderBy("admin");
              }}
              isSelect={orderBySelect === "admin" ? true : false}
            > */}
            <TextHeaderTable>Tipo Admin</TextHeaderTable>
            {/* </ClickableArea> */}
          </ContainerHeaderTable>
          <ContainerHeaderTable
            // width="15"
            minWidth="130"
            justify="space-evenly"
            marginLeft={0}
            marginRight={30}
          >
            {/* <ClickableArea> */}
            <TextHeaderTable>Opções</TextHeaderTable>
            {/* </ClickableArea> */}
          </ContainerHeaderTable>
        </HeaderTable>
        {isEmpty ? (
          <h1>Ainda não há dados nesta página</h1>
        ) : (
          <Box>
            {usuario.map((usuario) => (
              <div key={usuario.id}>
                <BoxUsuarios
                  first_name={usuario.first_name}
                  last_name={usuario.last_name}
                  admin={
                    usuario.is_staff === true && usuario.is_superuser === false
                      ? "Administrador"
                      : ""
                  }
                  superAdmin={
                    usuario.is_superuser === true ? "Administrador Master" : ""
                  }
                  email={usuario.email}
                  id={usuario.id}
                  getUsers={fetchUsuarios}
                  updateUser={updateUser}
                />
              </div>
            ))}
          </Box>
        )}
      </Tela>
    </>
  );
}
export default Usuarios;
