import React from "react";
import { useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
} from "@brightalley/react-beautiful-dnd";
import Toast from "../Toast";
import Header from "../header/header";
import { Box, Tela } from "../../pages/signedIn/ingredientes/ingredientes-style";
import NamePages from "../NamePages";
import { Adicionar, InlineStyle } from "../modalAdicionar/adicionar-style";
import {
  ClickableArea,
  ContainerHeaderTable,
  HeaderTable,
  TextHeaderTable,
} from "../HeaderTable/styles";
import { AreaDrop, DropDiv, DroppableDiv, H1text } from "./styles";
import { Colors } from "../../utils/theme/constants";
import { useEffect } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
import { useHistory } from 'react-router-dom';
import { toastProps } from "../../utils/toastProps";

function OrdenarCategorias() {
  const [columns, setColumns] = useState([]);
  const history = useHistory();

  const fetchCategories = async () => {
    try {
      const { data: fetchedCategories } = await api.get("/api/categorias/all/");
      console.log("fetchedCategories",fetchedCategories)
      fetchedCategories.map((item) => (item.content = item.nome));
      fetchedCategories.map((item) => (item.id = item.id.toString()));
      setColumns(fetchedCategories);
    } catch (error) {
      toast.error("Erro ao carregar categorias");
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  function onDragEnd(result) {
    let resultId = {};
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].id == result.draggableId) {
        resultId = columns[i];
      }
    }
    const filtro = columns.filter((item) => item.id != result.draggableId);
    filtro.splice(result?.destination?.index, 0, resultId);
    let columnsCopy = JSON.parse(JSON.stringify(columns));
    columnsCopy = filtro;
    setColumns(columnsCopy);
  }

  const changeOrder = async () => {
    try {
      const categorias = columns.map((item) => parseInt(item.id));
      console.log(categorias)
      const body = {
        categorias: categorias,
      };
      await api.patch("/api/categorias/dragdrop/", body);
      toast.success("Ordem alterada com sucesso", toastProps);
      history.goBack();
    } catch (error) {
      toast.error("Erro ao alterar ordem", toastProps);
    }
  };

  return (
    <>
      <Toast />
      <div>
        <Header />
        <Tela>
          <NamePages BackgroundColor={"#E6AC86"} NamePage={"Categorias"} />
          <InlineStyle>
            <Adicionar
              onClick={() => changeOrder()}
            >
              <button type="button">
                <p style={{ color: Colors.White }}>Salvar Mudanças</p>
              </button>
            </Adicionar>
          </InlineStyle>
          <HeaderTable backgroundColor="#A5553C">
            <ContainerHeaderTable
              width="80"
              minWidth="100"
              justify="start"
              marginLeft={30}
              marginRight={10}
            >
              <ClickableArea>
                <TextHeaderTable>Nome</TextHeaderTable>
              </ClickableArea>
            </ContainerHeaderTable>
            <ContainerHeaderTable
              // width="15"
              minWidth="90"
              justify="space-evenly"
              marginLeft={0}
              marginRight={30}
            ></ContainerHeaderTable>
          </HeaderTable>
          <Box>
            <DroppableDiv>
              <DragDropContext
                onDragEnd={(result) => {
                  onDragEnd(result);
                }}
              >
                <Droppable droppableId={"0"} key={"0"}>
                  {(provided) => (
                    <AreaDrop ref={provided.innerRef}>
                      {columns.map((item, index) => (
                        <Draggable
                          draggableId={item.id}
                          index={index}
                          key={item.id}
                        >
                          {(provided) => (
                            <DropDiv
                              style={{ ...provided.draggableProps.style }}
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                            >
                              <H1text>{item.content}</H1text>
                            </DropDiv>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </AreaDrop>
                  )}
                </Droppable>
              </DragDropContext>
            </DroppableDiv>
          </Box>
        </Tela>
      </div>
    </>
  );
}

export default OrdenarCategorias;

/*
<div style={{backgroundColor:"green", width: "50vw"}}>
      <DragDropContext>
      <div style={{backgroundColor:"yellow"}}>
      <Droppable droppableId={"0"}>
      {(provided)=>(
          <ModalDiv ref={provided.innerRef}>
              
              {inicialItems.map((item, index)=>(<Draggable draggableId={item.id} index={index}>{(provided)=>
              (<TreloDiv style={{...provided.draggableProps.style}} ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>{item.content}</TreloDiv>)}</Draggable>))}
              
          </ModalDiv>
        )}
      </Droppable>
      </div>
    </DragDropContext>
    </div>

*/