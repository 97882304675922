import React from 'react';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import {isAuthenticated} from '../services/auth';


import Login from '../pages/login/login.js';
import Recuperar from '../pages/recuperarSenha/recuperar.js';
import Home from '../pages/signedIn/Home';
import Produtos from '../pages/signedIn/produtos/produtos.js';
import Ingredientes from '../pages/signedIn/ingredientes/ingredientes.js';
import Requisicoes from '../pages/signedIn/requisicoes/requisicoes.js';
import Trending from '../pages/signedIn/trending/trending.js';
import Usuarios from '../pages/signedIn/usuarios/usuarios.js';
import Faq from '../pages/signedIn/faq/faq';
import AlterarSenha from '../pages/alterarSenha/alterar-senha';
import Rotulometro from '../pages/signedIn/rotulometro/rotulometro';
import Histórico from '../pages/signedIn/histórico/histórico';
import Categorias from '../pages/signedIn/categorias/categorias';
import Selos from '../pages/signedIn/selos/selos';
import OrdenarCategorias from '../components/OrdenarCategorias';
import TrendingHighlights from "../pages/signedIn/trendingsHighlights"
  
const PrivateRoute = ({ component: Component, ...rest}) => (
    <Route 
        { ... rest}
        render = {props =>
            isAuthenticated() ? (
                <Component { ... props} />
            ) : (
                <Redirect to={{ pathname: "/", state: { from: props.location}}} />
            )
        }
    />
);

function Routes(){
    return(
        <BrowserRouter>
            <Switch>
                <Route path='/' exact component={Login}/>
                <Route path='/EsqueciMinhaSenha' component={Recuperar}/>
                <Route path='/AlterarSenha/:uid/:token' component={AlterarSenha}/>
                <PrivateRoute path='/Home' component={Home}/>
                <PrivateRoute path='/Produtos' component={Produtos}/>
                <PrivateRoute path='/Ingredientes' component={Ingredientes}/>
                <PrivateRoute path='/Requisicoes' component={Requisicoes}/>
                <PrivateRoute exact path='/Trending' component={Trending}/>
                <PrivateRoute exact path='/Trending/Highlights' component={TrendingHighlights}/>
                <PrivateRoute path='/Usuarios' component={Usuarios}/>
                <PrivateRoute path='/Historico' component={Histórico}/>
                <PrivateRoute path='/Rotulometro' component={Rotulometro}/>
                <PrivateRoute path='/FAQ' component={Faq}/>
                <PrivateRoute exact path='/Categorias' component={Categorias} />
                <PrivateRoute exact path='/Categorias/Ordenar' component={OrdenarCategorias}/>
                <PrivateRoute path='/Selos' component={Selos}/>
            </Switch>
        </BrowserRouter>
    );

}
export default Routes;
