import React, { useState } from "react";
import { ListCategory, ListElement } from "./box-lista-style.js";
import api from "../../services/api.js";
//import axios from "axios";
import Cancelar from "../../assets/cancelar.svg";
import edit from "../../assets/editTable.svg";
import CreateIcon from "@material-ui/icons/Create";
import Modal from "../modal/modal.js";
import ReactLoading from "react-loading";
import { TextField } from "@material-ui/core";
import { TextAreaModal, TextDivModal } from "../TextField/styles.js";

import "../../global-styles.css";
import { toastProps } from "../../utils/toastProps.js";
import { toast } from "react-toastify";
import { ContainerHeaderTable } from "../HeaderTable/styles.js";
import { IconImage, TextTable } from "./styles.js";
import axios from "axios";

function BoxSelos({ nome, id, imagem, GetSelos }) {
  const [deletarSeloIsOpen, setDeletarSeloIsOpen] = useState(false);
  const [modalEditarIsOpen, setModalEditarIsOpen] = useState(false);
  const [novoNome, setNovoNome] = useState(nome);
  const [novaImagem, setNovaImagem] = useState(imagem);
  const [loading, setLoading] = useState(false);
  const [changeImageIn, setChangeImageIn] = useState(false);
  const [feedbackImagem, setFeedbackImagem] = useState("");

  const putImagem = async () => {

    if(!novaImagem){
      return
    }

    if (!novaImagem.type) {
      return;
    }
    const typeNovo = novaImagem.type.substring([6]);

    await api
      .get("/api/file/" + typeNovo + "/")
      .then((response) => {
        const mediaUrl = response.data.media_url;
        const fileName = response.data.file_name;

        let foo = novaImagem.slice(0, novaImagem.size, novaImagem.type);
        let bar = new File([foo], response.data.file_name, {
          type: novaImagem.type,
        });

        axios
          .put(`${mediaUrl}`, bar, {
            headers: {
              "x-amz-acl": "public-read",
              "Content-Type": bar.type,
              "Content-Disposition": "attachment",
            },
            onUploadProgress: (e) => {
              let { loaded, total } = e;
              let percent = Math.floor((loaded * 100) / total);
              setFeedbackImagem(`${percent}%/100%`);
            },
          })
          .then((res) => {
            api
              .patch(`/api/selos/${id}/`, { image_url: fileName })
              .then((response) => {
                setFeedbackImagem("Imagem Alterada com Sucesso!");
              })
              .catch((err) => {
                setFeedbackImagem("Erro Selecionando Imagem");
              });
          })
          .catch((err) => {
            setFeedbackImagem("Erro Selecionando Imagem");
          });
      })
      .catch((err) => console.error(err));
  };

  const deletarSelo = async (event) => {
    event.preventDefault();
    try {
      const response = await api.get("/auth/users/me/");
      const bodyHistory = {
        user: response.data.id,
        model_name: nome,
        model_type: "Selo",
        operation: "-",
      };
      await api.post("/api/editionhistory/", bodyHistory);
      await api.delete("api/selos/" + id + "/");
      toast.success("Selo deletado com sucesso", toastProps);
    } catch {
      toast.error("Erro ao deletar o selo", toastProps);
    }
    setDeletarSeloIsOpen(false);
    GetSelos();
  };

  const editarSelo = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await api.get("/auth/users/me/");
      const bodyHistory = {
        user: response.data.id,
        model_name: nome,
        model_type: "Selo",
        operation: "~",
      };
      await api.post("/api/editionhistory/", bodyHistory);
      const body = { nome: novoNome };
      await api.patch(`/api/selos/${id}/`, body);
      setChangeImageIn(false)
      toast.success("Selo alterado com sucesso", toastProps);
    } catch (error) {
      toast.success("Erro ao alterar Selo", toastProps);
    }

    setLoading(false);
    setModalEditarIsOpen(false);
    GetSelos();
  };

  return (
    <ListElement>
      <ContainerHeaderTable
        width="80"
        justify="start"
        marginLeft={30}
        marginRight={10}
      >
        <TextTable onClick={() => setModalEditarIsOpen(true)}>
          <b>{nome}</b>
        </TextTable>
      </ContainerHeaderTable>

      <ContainerHeaderTable
        // width="15"
        minWidth="90"
        justify="space-evenly"
        marginLeft={0}
        marginRight={30}
      >
        <IconImage
          width={20}
          height={20}
          src={edit}
          onClick={() => setModalEditarIsOpen(true)}
        ></IconImage>
        <IconImage
          width={20}
          height={20}
          src={Cancelar}
          onClick={(e) => setDeletarSeloIsOpen(true)}
        ></IconImage>
      </ContainerHeaderTable>
      {deletarSeloIsOpen ? (
        <Modal
          container="containerAvisoDeletar"
          onClose={() => setDeletarSeloIsOpen(false)}
        >
          <h2 id="textoDeletar">
            Deseja mesmo deletar este Selo de forma definitiva?
          </h2>
          <div id="botoesDeletar">
            <button id="deletar" onClick={(e) => deletarSelo(e, id)}>
              SIM
            </button>
            <button id="deletar" onClick={() => setDeletarSeloIsOpen(false)}>
              NÃO
            </button>
          </div>
        </Modal>
      ) : null}

      {modalEditarIsOpen && (
        <Modal
          container="containerEditarCategoria"
          onClose={() => setModalEditarIsOpen(false)}
        >
          <div id="divEditarCategoria">
            <p style={{ fontSize: "20px", fontWeight: "bold" }}>Editar selo</p>
            <label htmlFor="nome_selo">Nome do Selo</label>
            <TextDivModal height="40">
              <TextAreaModal
                style={{ width: "300px" }}
                value={novoNome}
                onChange={(e) => setNovoNome(e.target.value)}
                id="nome_selo"
              />
            </TextDivModal>

            {imagem && imagem !== "" ? (
              <img src={imagem} width="200" height="200" alt={nome} />
            ) : null}

            <button
              style={{
                backgroundColor: "#E6AC86",
                border: "none",
                borderRadius: "10px",
                margin: "0 auto 20px auto !important",
                padding: "5px",
                paddingBottom: "10px",
                fontSize: "13px",
                color: "white",
                width: 100 % "!important",
                height: "25px",
              }}
              onClick={() => setChangeImageIn(!changeImageIn)}
            >
              Alterar Imagem
            </button>
            {changeImageIn && (
              <div id="divAlterarImagem">
                <input
                  type="file"
                  onChange={(event) => setNovaImagem(event.target.files[0])}
                  accept="image/*"
                />
                <button onClick={() => putImagem()}>Confirmar imagem</button>
                <h6 style={{ size: "10px", margin: "0px" }}>
                  {feedbackImagem}
                </h6>
              </div>
            )}

            <button
              //value="Confirmar"
              style={{ cursor: "pointer" }}
              onClick={(event) => editarSelo(event)}
              className="enviar"
            >
              {loading ? (
                <ReactLoading type="spin" color="#fff" height={20} width={20} />
              ) : (
                "Confirmar"
              )}
            </button>
          </div>
        </Modal>
      )}
    </ListElement>
  );
}
export default BoxSelos;
