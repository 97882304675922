import styled from 'styled-components';

export const Box=styled.div`
    
    width: 90vw;
    max-height: 67vh;
    border-radius: 0px 0px 10px 10px;
    padding: 10px;
    background-color: #E6AC86;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    gap: 12px;
    margin-bottom: 5vh;

    &::-webkit-scrollbar{
        width: 8px;
        background-color: transparent;
        border-radius: 50px; 
    }
    &::-webkit-scrollbar-thumb{
        border-radius: 10px;
        background-color: rgba(102, 102, 102, 0.5);
    }
   
`


export const Tela=styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`

export const InlineStyle=styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90vw;
    margin-bottom: 10px;
    margin-top: 10px;
    flex-wrap:wrap;
`

export const InlineStylePages=styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin:10px auto 0px 5vw;
    flex-wrap:wrap;
`

export const Buscar=styled.div`
    width: 35vw;
    max-height: 67vh;
    border-radius: 10px;
    padding: 10px;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
  
    >input{
        border: none;
        background-color: transparent;
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        margin-left: 15px;
        outline: none;
        width: 35vw;
    }
`

export const NavegarNasPaginas=styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 90vw;
    margin: 0px;
    >button{
        width: 20px;
        height: 20px;
        border: none;
        padding: 3px;
        margin: 2px;
        align-items: center;
        background-color: transparent;
       
        >p{
            padding-left: 100px
        }
        >img{
            width: 15px;
            height: 15px;
            fill: red;
        }
    }
`
export const TextDivModal =styled.div`
    max-width: 600px;
    height: 6.5vh ;
    width: 90%;
    background-color: #ececec;
    display: flex;
    text-align: center;
    justify-content: center;
    border-radius: 10px;
    transition: 0.25s;
    margin-top: 10px;
    margin-bottom: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

export const BotoesNavegar=styled.button`
width: 25px;
height: 25px;
border: none;
align-items: center;
background-color: transparent;
margin-bottom:10px;
color: rgba(150, 150,150, 0.5); 
    &:hover{
        color:black;
        width:27px;
        height:27px;
        margin-left:-2px;
        margin-top:-2px;
    }
`

export const Paginas = styled.section`
margin-bottom:10px;
display:flex;
flex-direction:row;
button+button{
    margin-left:3px;
}

h3{
    
    margin:-6px 5px;

}
button{
 background-color: rgba(150, 150,150, 0.5); 
 border:none;
 width:25px;
 height:25px;
 text-align:center;
 display:flex;
 justify-content:center;
 align-items:center;
 border-radius:50px;
 &:hover{
    background-color: rgb(150, 150,150);
    width:27px;
    height:27px;
    margin-left:-2px;
    margin-top:-2px;
}
}
`

export const TextAreaModal =styled.textarea`
width: 90%;
background-color: transparent;
border: none;
height: 5vh;
`

export const InputAreaModal =styled.input`
width: 90%;
background-color: transparent;
border: none;
height: 5vh;
`