import React from 'react';
import Header from '../../../components/header/header';
import { useEffect, useState } from "react";
import api from '../../../services/api'
import AdicionarFAQ from '../../../components/modalAdicionar/adicionarFAQ';
import Toast from '../../../components/Toast';
import BoxFAQ from '../../../components/box-listas/box-faq';
import { Box, Tela, InlineStyle } from './faq-style';
import NamePages from '../../../components/NamePages';

function Faq() {

    const [faqs, setFaq] = useState([]);
    const [isEmpty, setIsEmpty] = useState(false)

    const getFaqs = () => {
        api.get('api/faq/').then( // AJEITAR AQUI DEPOIS!!!
            res => {
                setFaq(res.data.results)
                if (res.data.results.length === 0) {
                    setIsEmpty(true)
                } else {
                    setIsEmpty(false);
                }
            }
        ).catch(err => {
            console.log(err)
        });
    }

    useEffect(() => {
        getFaqs()
    }, []);

    return (
        <>
            <Toast />
            <div>
                <Header />
                <NamePages BackgroundColor={"#CE932BCC"} NamePage={"FAQ"}/>
                <Tela>
                    <InlineStyle>
                        <AdicionarFAQ getFaqs={getFaqs}/>
                    </InlineStyle>
                    {isEmpty ? <h1>Ainda não há dados nesta página</h1> : <Box>
                        {faqs.map(faq =>
                            <div key={faq.id}>
                                <BoxFAQ
                                    pergunta={faq.pergunta}
                                    resposta={faq.resposta}
                                    id={faq.id}
                                    getFaqs={getFaqs}
                                />
                            </div>
                        )}
                    </Box>}
                </Tela>
            </div>
        </>
    );
}
export default Faq;