import React from "react";
import InputPesquisa from "../../../components/InputPesquisa";
import Header from "../../../components/header/header";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import Toast from "../../../components/Toast";
import SearchIcon from "@material-ui/icons/Search";
import {
  Tela,
  InlineStyle,
  Buscar,
  Box,
  BotoesNavegar,
  Paginas,
  InlineStylePages,
} from "../ingredientes/ingredientes-style";
import { Buscar2 } from "../usuarios/usuarios-style";
import AdicionarProduto from "../../../components/modalAdicionar/adicionarProduto";
import BoxProduto from "../../../components/box-listas/box-produto";
import Next from "../../../assets/next.svg";
import NamePages from "../../../components/NamePages";
import {
  ClickableArea,
  ContainerHeaderTable,
  HeaderTable,
  TextHeaderTable,
} from "../../../components/HeaderTable/styles";
import ButtonOrderBy from "../../../components/buttonOrderBy";
import { RowButtonsOrderby } from "../../../components/buttonOrderBy/styles";
function Produtos(props) {
  const [produtos, setProdutos] = useState([]);
  const [busca, setBusca] = useState("");
  const [numeroDaPagina, setNumeroDaPagina] = useState(1);
  const [numeroTotalPaginas, setNumeroTotalPaginas] = useState(1);
  const [isEmpty, setIsEmpty] = useState(false);
  const [orderBySelect, setOrderBySelect] = useState("nome");
  const [typeOrder, setTypeOrder] = useState("ascendent");

  const data = {
    route: props.location.state,
  };

  // const getProducts = () => {
  //   setBusca("");
  //   api
  //     .get("/api/produtos/")
  //     .then(function (response) {
  //       console.log(response);
  //       if (response.data.results.length === 0) {
  //         setIsEmpty(true);
  //       } else {
  //         setIsEmpty(false);
  //       }
  //       setProdutos(response.data.results);
  //       setNumeroDaPagina(1);
  //       setNumeroTotalPaginas(Math.ceil(response.data.count / 15));
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // useEffect(() => {
  //   getProducts();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const onPressButtonOrder = (order) => {
    console.log("order", order);
    console.log("orderBySelect", orderBySelect);
    if (order === orderBySelect) {
      typeOrder === "ascendent"
        ? setTypeOrder("descendent")
        : setTypeOrder("ascendent");
    } else {
      setTypeOrder("ascendent");
      setOrderBySelect(order);
    }
  };

  async function fetchProducts() {
    setNumeroDaPagina(1);

    const response = props.location.state
      ? await api.get(
          `${props.location.state}?ordering=${
            typeOrder === "ascendent" ? "" : "-"
          }${orderBySelect}&page=1&find=${busca}`
        )
      : await api.get(
          `/api/produtos?ordering=${
            typeOrder === "ascendent" ? "" : "-"
          }${orderBySelect}&page=1&find=${busca}`
        );
    console.log(response.data, "Teste1");
    if (response.data.results.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
    setProdutos(response.data.results);
    console.log("produtos", response.data.results);
    setNumeroDaPagina(1);
    setNumeroTotalPaginas(Math.ceil(response.data.count / 15));
  }

  useEffect(() => {
    const timer = setTimeout(() => fetchProducts(), 500);
    return () => clearTimeout(timer);
  }, [busca, orderBySelect, typeOrder]);

  const PaginaAtual = async (data) => {
    if (data > 0 && data <= numeroTotalPaginas) {
      setNumeroDaPagina(data);
      const response = props.location.state
        ? await api.get(
            `${props.location.state}?ordering=${
              typeOrder === "ascendent" ? "" : "-"
            }${orderBySelect}&page=${data}&find=${busca}`
          )
        : await api.get(
            `/api/produtos?ordering=${
              typeOrder === "ascendent" ? "" : "-"
            }${orderBySelect}&page=${data}&find=${busca}`
          );

      console.log(response.data);

      setProdutos(response.data.results);
    }
  };

  return (
    <>
      <Toast />
      <Header />
      <Tela>
        <NamePages BackgroundColor={"#E6AC86"} NamePage={"Produtos"} />
        <InlineStyle>
          <Buscar>
            <SearchIcon />
            <input
              type="text"
              placeholder="Buscar"
              onChange={(event) => setBusca(event.target.value)}
              value={busca}
            />
          </Buscar>
          <AdicionarProduto getProducts={fetchProducts} />
        </InlineStyle>
        {isEmpty ? null : (
          <InlineStylePages>
            {numeroDaPagina > 1 && (
              <BotoesNavegar
                type="button"
                onClick={() => PaginaAtual(numeroDaPagina - 1)}
              >
                <img src={Next} alt="Voltar" className="rotateimg180" />
              </BotoesNavegar>
            )}
            <Paginas>
              {numeroDaPagina > 10 ? (
                <button onClick={() => PaginaAtual(1)}>{1}</button>
              ) : null}
              {numeroDaPagina > 10 ? (
                <button onClick={() => PaginaAtual(2)}>{2}</button>
              ) : null}
              {numeroDaPagina > 10 ? <h3>...</h3> : null}
              <button
                onClick={() => PaginaAtual(numeroDaPagina)}
                style={{ backgroundColor: "#E6AC86" }}
              >
                {numeroDaPagina}
              </button>
              {numeroDaPagina + 1 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 1)}>
                  {numeroDaPagina + 1}
                </button>
              )}
              {numeroDaPagina + 2 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 2)}>
                  {numeroDaPagina + 2}
                </button>
              )}
              {numeroDaPagina + 3 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 3)}>
                  {numeroDaPagina + 3}
                </button>
              )}
              {numeroDaPagina + 4 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 4)}>
                  {numeroDaPagina + 4}
                </button>
              )}
              {numeroDaPagina + 5 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 5)}>
                  {numeroDaPagina + 5}
                </button>
              )}
              {numeroDaPagina + 6 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 6)}>
                  {numeroDaPagina + 6}
                </button>
              )}
              {numeroDaPagina + 7 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 7)}>
                  {numeroDaPagina + 7}
                </button>
              )}
              {numeroDaPagina + 8 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 8)}>
                  {numeroDaPagina + 8}
                </button>
              )}
              {numeroDaPagina + 9 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 9)}>
                  {numeroDaPagina + 9}
                </button>
              )}
              {numeroDaPagina !== numeroTotalPaginas &&
              numeroTotalPaginas > 5 ? (
                <h3>...</h3>
              ) : null}
              {numeroDaPagina !== numeroTotalPaginas &&
              numeroTotalPaginas > 5 ? (
                <button onClick={() => PaginaAtual(numeroTotalPaginas)}>
                  {numeroTotalPaginas}
                </button>
              ) : null}
            </Paginas>
            {numeroDaPagina < numeroTotalPaginas && (
              <BotoesNavegar
                type="button"
                onClick={() => PaginaAtual(numeroDaPagina + 1)}
              >
                <img src={Next} alt="Avançar" />
              </BotoesNavegar>
            )}
            <Buscar2>
              <input
                type="number"
                placeholder="Página..."
                onChange={(event) => {
                  PaginaAtual(event.target.value);
                }}
              />
            </Buscar2>
          </InlineStylePages>
        )}
        <RowButtonsOrderby>
          <ButtonOrderBy
            onPress={() => {
              onPressButtonOrder("nome");
            }}
            title={
              typeOrder === "ascendent"
                ? "clique para ordenar de forma descendente"
                : "clique para ordenar de forma ascendete"
            }
            isSelected={orderBySelect === "nome" ? true : false}
            type="string"
            ascendent={typeOrder}
            text="Nome"
          />
          <ButtonOrderBy
            onPress={() => {
              onPressButtonOrder("marca");
            }}
            title={
              typeOrder === "ascendent"
                ? "clique para ordenar de forma descendente"
                : "clique para ordenar de forma ascendete"
            }
            isSelected={orderBySelect === "marca" ? true : false}
            type="string"
            ascendent={typeOrder}
            text="Marca"
          />
          <ButtonOrderBy
            onPress={() => {
              onPressButtonOrder("score");
            }}
            title={
              typeOrder === "ascendent"
                ? "clique para ordenar de forma descendente"
                : "clique para ordenar de forma ascendete"
            }
            isSelected={orderBySelect === "score" ? true : false}
            type="numeric"
            ascendent={typeOrder}
            text="Pontuação"
          />
        </RowButtonsOrderby>

        <HeaderTable backgroundColor="#A5553C">
          <ContainerHeaderTable
            width="60"
            minWidth="32"
            justify="flex-start"
            marginLeft={30}
            marginRight={10}
          >
            {/* <ClickableArea
                            title={
                              orderBySelect === "nome"
                                ? "Retirar Ordenação"
                                : "Ordenar por Nome"
                            }
                            onClick={() => {
                              orderBy("nome");
                            }}
                            isSelect={orderBySelect === "nome" ? true : false}
            > */}
            <TextHeaderTable>Nome</TextHeaderTable>
            {/* </ClickableArea> */}
          </ContainerHeaderTable>
          <ContainerHeaderTable
            justify="center"
            width="29"
            minWidth="90"
            marginLeft={0}
            marginRight={10}
          >
            {/* <ClickableArea
                            title={
                              orderBySelect === "marca"
                                ? "Retirar Ordenação"
                                : "Ordenar por Marca"
                            }
                            onClick={() => {
                              orderBy("marca");
                            }}
                            isSelect={orderBySelect === "marca" ? true : false}
            > */}
            <TextHeaderTable>Marca</TextHeaderTable>
            {/* </ClickableArea> */}
          </ContainerHeaderTable>
          <ContainerHeaderTable
            width="15"
            minWidth="90"
            justify="center"
            marginRight={10}
            marginLeft={0}
          >
            {/* <ClickableArea
                            title={
                              orderBySelect === "score"
                                ? "Retirar Ordenação"
                                : "Ordenar por Pontuação"
                            }
                            onClick={() => {
                              orderBy("score");
                            }}
                            isSelect={orderBySelect === "score" ? true : false}
            > */}
            <TextHeaderTable>Pontuação</TextHeaderTable>
            {/* </ClickableArea> */}
          </ContainerHeaderTable>
          <ContainerHeaderTable
            // width="15"
            minWidth="90"
            justify="center"
            marginLeft={0}
            marginRight={30}
          >
            {/* <ClickableArea> */}
            <TextHeaderTable>Opções</TextHeaderTable>
            {/* </ClickableArea> */}
          </ContainerHeaderTable>
        </HeaderTable>
        {isEmpty ? (
          <h1>Ainda não há dados nesta página</h1>
        ) : (
          <Box>
            {produtos?.map((produto) => (
              <div key={produto.id}>
                <BoxProduto
                  nome={produto.nome}
                  score={produto.score}
                  id={produto.id}
                  categoria={produto.categoria}
                  marca={produto.marca}
                  volume={produto.volume}
                  upc={produto.upc}
                  imagem={produto.image_url}
                  ingredientes={produto.ingredientes}
                  getProducts={fetchProducts}
                />
              </div>
            ))}
          </Box>
        )}
      </Tela>
    </>
  );
}
export default Produtos;
