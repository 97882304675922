import React from 'react'
import Modal from "../modal/modal.js";


function CloseAdvice({setModalcloseAdviceIsOpen, setModalIsOpen}) {
  return (
    <Modal
    container="containerAvisoDeletar"
    onClose={() => setModalcloseAdviceIsOpen(false)}
  >
    <h2 id="textoDeletar">
      Deseja mesmo sair? Qualquer mudança não salva será perdida!
    </h2>
    <div id="botoesDeletar">
      <button
        id="deletar"
        onClick={() => {
          setModalcloseAdviceIsOpen(false);
          setModalIsOpen(false);
        }}
      >
        SIM
      </button>
      <button
        id="deletar"
        onClick={() => setModalcloseAdviceIsOpen(false)}
      >
        NÃO
      </button>
    </div>
  </Modal>
  )
}

export default CloseAdvice