import React, { useState } from "react";
import { ListVertElement } from "./box-lista-style.js";
import "../../global-styles.css";
import Plus from "../../assets/plus.svg";
import Close from "../../assets/close.svg";
import deletar from "../../assets/cancelar.svg";
import Modal from "react-modal";
import api from "../../services/api";
import { TextField } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import edit from "../../assets/edit.svg";
import close from "../../assets/close.svg";
import { toast } from "react-toastify";
import { toastProps } from "../../utils/toastProps.js";
import {
  BackgroundModal,
  ButtonsArea,
  ButtonText,
  HeaderQuestionArea,
  OptionsArea,
  Title,
  TitleModalArea,
  IconOptions,
  ButtonIcon,
} from "./styles.js";
import { Height } from "@material-ui/icons";
import { TextAreaModal, TextDivModal } from "../TextField/styles.js";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    height: "200px",
  },
};

function BoxFAQ({ getFaqs, ...props }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalDeleteIsOpen, setmodalDeleteIsOpen] = useState(false);

  const [resposta, setresposta] = useState(props.resposta);
  const [pergunta, setpergunta] = useState(props.pergunta);

  const url = "api/faq/" + props.id + "/";

  const body = {
    pergunta: pergunta,
    resposta: resposta,
  };

  const alterarFAQ = async (e) => {
    try {
      e.preventDefault();
      await api.patch(url, body);
      toast.success("Informações alterada com sucesso!", toastProps);
    } catch (error) {
      toast.error("Erro ao alterar Informações", toastProps);
    }
    setModalIsOpen(false);
    getFaqs();
  };

  const deletarFAQ = async (e) => {
    var x = window.confirm("Deseja mesmo deletar esse FAQ?");
    if (x) {
      try {
        e.preventDefault();
        await api.delete(url);
        toast.success("Informação excluída com sucesso!", toastProps);
      } catch (error) {
        toast.error("Erro ao excluir informação!", toastProps);
      }
    }
    setModalIsOpen(false);
    getFaqs();
  };

  return (
    <ListVertElement>
      <HeaderQuestionArea>
        <p className="div-perguntas">
          <strong className="perg">{props.pergunta}</strong>
        </p>
        <OptionsArea>
          <ButtonIcon
            className="botaoTransparente"
            onClick={() => {
              setModalIsOpen(true);
            }}
          >
            <CreateIcon fontSize="small" />
          </ButtonIcon>
          <IconOptions
            onClick={() => {
              setmodalDeleteIsOpen(true);
            }}
            src={deletar}
            alt="Fechar"
          />
        </OptionsArea>
      </HeaderQuestionArea>
      <h1>{props.resposta}</h1>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        // portalClassName="popUp"
        style={{
          overlay: {
            backgroundColor: "rgba(153, 153, 153, 0.5)",
          },
          content: {
            marginLeft: '25vw',
            width: '500px',
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            backgroundColor: '#efefef'
          },
        }}
      >
        <button
          onClick={() => setModalIsOpen(false)}
          style={{
            width: "20px",
            height: "20px",
            backgroundColor: "transparent",
            border: "none",
            display: "flex",
            alignItems: "center",
            marginLeft: "auto",
          }}
        >
          <img
            src={Close}
            alt="Fechar"
            style={{
              width: "10px",
              height: "10px",
              position: "right",
            }}
          />
        </button>
        <p style = {{fontSize: '20px', fontWeight: 'bold'}}>Altere Aqui</p>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <label id="pergunta">Pergunta</label>
          <TextDivModal height= '60'>
          <TextAreaModal
            style={{ width: "300px", marginBottom: "50px" }}
            type="text"
            id="pergunta"
            value={pergunta}
            onChange={(event) => setpergunta(event.target.value)}
          />
          </TextDivModal>
          <label htmlFor="resposta">Resposta</label>
          <TextDivModal height= '80'>
          <TextAreaModal
            style={{ width: "300px", marginBottom: "50px" }}
            type="text"
            id="resposta"
            value={resposta}
            onChange={(event) => setresposta(event.target.value)}
          />
          </TextDivModal>
          <input
            type="submit"
            value="Alterar"
            onClick={(e) => alterarFAQ(e)}
            style={{
              color: "#fff",
              backgroundColor: "rgb(206, 147, 43)",
              borderRadius: "10px",
              margin: "15px",
              width: "50%",
              border: "none",
              cursor: "pointer",
            }}
          />
        </form>
      </Modal>
      <Modal
        style={customStyles}
        isOpen={modalDeleteIsOpen}
        onClose={() => setmodalDeleteIsOpen(false)}
      >
        <BackgroundModal>
          <TitleModalArea>
            <Title>Tem certeza que deseja apagar esse FAQ?</Title>
          </TitleModalArea>
          <ButtonsArea>
            <button
              onClick={(e) => deletarFAQ(e)}
              style={{
                color: "#b80d0d",
                border: "None",
                backgroundColor: "transparent",
                fontWeight: "bold",
              }}
            >
              <ButtonText>Remover</ButtonText>
            </button>
            <button
              onClick={(e) => setmodalDeleteIsOpen(false)}
              style={{
                color: "#000000",
                border: "None",
                backgroundColor: "transparent",
                fontWeight: "bold",
              }}
            >
              <ButtonText>Cancelar</ButtonText>
            </button>
          </ButtonsArea>
        </BackgroundModal>
      </Modal>
    </ListVertElement>
  );
}
export default BoxFAQ;
