import React, { useState, useEffect } from "react";
import "../../global-styles.css";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import Plus from "../../assets/plus.svg";
import { Adicionar } from "./adicionar-style";
import api from "../../services/api";
import Modal from "../modal/modal.js";
import TextField from "@material-ui/core/TextField";
import { toastProps } from "../../utils/toastProps";
import { toast } from "react-toastify";
import ErrorMessage from "../ErrorMessage";
import { TextAreaModal, TextDivModal } from "../TextField/styles";
import { Colors } from "../../utils/theme/constants";
import CloseAdvice from "../../components/CloseAdvice"

function AdicionarIngrediente({ getIngredientes }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalcloseAdviceIsOpen, setModalcloseAdviceIsOpen] = useState(false);
  const [messageIsOpen, setMessageIsOpen] = useState(false);
  const [nome, setNome] = useState("");
  const [descricao, setDescricao] = useState("");
  const [impacto, setImpacto] = useState("");
  const [score, setScore] = useState("");
  const [sinonimos, setSinonimos] = useState("");
  const [funcao, setFuncao] = useState("");
  const [referencias, setReferencias] = useState("");
  const [mensagemErro, setMensagemErro] = useState("");
  const [inci, setInci] = useState("");
  const [cas, setCas] = useState("");
  const [ec, setEc] = useState("");
  const [carcinogenese, setCarcinogenese] = useState("");
  const [toxidade, setToxidade] = useState("");
  const [potencialAlergico, setPotencialAlergico] = useState("");
  const [userId, setUserId] = useState("");
  const [verde, setVerde] = useState(false);

  const [nameErro, setNameErro] = useState(false);

  const checkErrors = () => {
    let hasErrors = false;
    if (nome === "") {
      setNameErro(true);
      hasErrors = true;
    } else {
      setNameErro(false);
    }

    return hasErrors;
  };

  const adicionarIngrediente = async (e) => {
    e.preventDefault();

    console.log("oi ", nome);
    if (checkErrors()) return;

    const body = {
      nome: nome,
      descricao: descricao !== "" ? descricao : null,
      impacto: impacto !== "" ? impacto : null,
      score: score !== "" ? score : null,
      sinonimos: sinonimos !== "" ? sinonimos : null,
      inci: inci !== "" ? inci : null,
      ec: ec !== "" ? ec : null,
      cas: cas !== "" ? cas : null,
      carcinogenese: carcinogenese !== "" ? carcinogenese : null,
      toxicidade: toxidade !== "" ? toxidade : null,
      potencial_alergenico: potencialAlergico !== "" ? potencialAlergico : null,
      funcao: funcao !== "" ? funcao : null,
      referencias: referencias !== "" ? referencias : null,
      ingrediente_verde: verde,
    };

    try {
      const response = await api.get("/auth/users/me/");

      await api.post("/api/ingredientes/", body);
      const bodyHistory = {
        user: await response.data.id,
        model_name: nome,
        model_type: "Ingrediente",
        operation: "+",
      };
      const res = await api.post("/api/editionhistory/", bodyHistory);

      setMessageIsOpen(true);
    } catch (error) {
      toast.error("Erro ao adicionar ingrediente", toastProps);
    }
  };

  useEffect(() => {
    if (score > 10) {
      setMensagemErro("Não adicione score maior que 10");
    }
  }, [score]);

  const handleClose = () => {
    getIngredientes();
    setMessageIsOpen(false);
    setModalIsOpen(false);
  };

  return (
    <Adicionar>
      <button type="button" onClick={() => setModalIsOpen(true)}>
        <p style={{ color: Colors.White }}>Adicionar Ingrediente</p>
        {/* <img src={Plus} alt="Mais" /> */}
      </button>

      {modalIsOpen ? (
        <Modal
          container="containerAdicionar"
          onClose={() => setModalcloseAdviceIsOpen(true)}
        >
          <p style={{ fontSize: "20px", fontWeight: "bold" }}>
            Adicionar Ingrediente
          </p>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <label htmlFor="nome">
              Nome<strong style={{ color: "red" }}>*</strong>
            </label>
            <TextDivModal height="50">
              <TextAreaModal
                type="text"
                id="nome"
                value={nome}
                onChange={(event) => setNome(event.target.value)}
              />
            </TextDivModal>
            <ErrorMessage isVisible={nameErro} message="Preencha o Nome" />
            <label htmlFor="inci">INCI</label>
            <TextDivModal height="50">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="inci"
                value={inci}
                onChange={(event) => setInci(event.target.value)}
              />
            </TextDivModal>
            <label htmlFor="cas">CAS</label>
            <TextDivModal height="50">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="cas"
                value={cas}
                onChange={(event) => setCas(event.target.value)}
              />
            </TextDivModal>
            <label htmlFor="ec">EC</label>
            <TextDivModal height="50">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="ec"
                value={ec}
                onChange={(event) => setEc(event.target.value)}
              />
            </TextDivModal>
            <label htmlFor="carcinogenese">Carcinogênese</label>
            <TextDivModal height="50">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="carcinogenese"
                value={carcinogenese}
                onChange={(event) => setCarcinogenese(event.target.value)}
              />
            </TextDivModal>
            <label htmlFor="toxidade">Toxidade</label>
            <TextDivModal height="50">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="toxidade"
                value={toxidade}
                onChange={(event) => setToxidade(event.target.value)}
              />
            </TextDivModal>
            <label htmlFor="potencialAlergico">Potencial Alérgico</label>
            <TextDivModal height="50">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="potencialAlergico"
                value={potencialAlergico}
                onChange={(event) => setPotencialAlergico(event.target.value)}
              />
            </TextDivModal>
            <label htmlFor="descricao">Descrição</label>
            <TextDivModal height="100">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="descricao"
                value={descricao}
                multiline={true}
                onChange={(event) => setDescricao(event.target.value)}
              />
            </TextDivModal>
            <label htmlFor="Impacto">Impacto</label>
            <TextDivModal height="100">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="Impacto"
                value={impacto}
                multiline={true}
                onChange={(event) => setImpacto(event.target.value)}
              />
            </TextDivModal>
            <label htmlFor="Score">Score</label>
            <TextDivModal height="50">
              <TextAreaModal
                style={{ width: 300 }}
                type="number"
                id="Score"
                value={score}
                InputProps={{ inputProps: { min: 0, max: 10 } }}
                onChange={(event) => setScore(event.target.value)}
              />
            </TextDivModal>
            <label id="Sinonimos">Sinônimos</label>
            <TextDivModal height="50">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="Sinonimos"
                value={sinonimos}
                onChange={(event) => setSinonimos(event.target.value)}
              />
            </TextDivModal>
            <label id="Funcao">Função</label>
            <TextDivModal height="50">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="Funcao"
                value={funcao}
                onChange={(event) => setFuncao(event.target.value)}
              />
            </TextDivModal>
            <label id="Referencias">Referências</label>
            <TextDivModal height="50">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="Referencias"
                value={referencias}
                onChange={(event) => setReferencias(event.target.value)}
              />
            </TextDivModal>

            <div className="div-verde">
              <label htmlFor="nome">Esse ingrediente é verde?</label>
              <input
                type="checkbox"
                id="nome"
                styles={{ width: "50px" }}
                value={verde}
                onChange={() => setVerde(!verde)}
              />
            </div>

            <button
              type="submit"
              onClick={(e) => adicionarIngrediente(e)}
              className="enviar"
            >
              Adicionar
            </button>
          </form>
          <p style={{ color: "#ED4337" }}>{mensagemErro}</p>
        </Modal>
      ) : null}

      {modalcloseAdviceIsOpen ? (
        <CloseAdvice
          setModalcloseAdviceIsOpen={setModalcloseAdviceIsOpen}
          setModalIsOpen={setModalIsOpen}
        />
      ) : null}

      {messageIsOpen ? (
        <Modal container="containerAviso" onClose={handleClose}>
          <p>Ingrediente Adicionado com Sucesso!</p>
        </Modal>
      ) : null}
    </Adicionar>
  );
}
export default AdicionarIngrediente;
