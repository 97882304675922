import React, { useState, useEffect } from "react";
import Header from "../../../components/header/header";
import Modal from "../../../components/modal/modal";
import Modallib  from "react-modal";
import api from "../../../services/api";
import {
  AreaDivision,
  Background,
  CircleArea,
  FTArea,
  NameArea,
  NumberCircle,
  RowDivision,
  FTBackground,
  FTBackgroundTitle,
  FTTitle,
  TextItems,
  LastItemsBackground,
  LastItems,
} from "./styles";
import {
  TextAreaModal,
  TextDivModal,
} from "../../../components/TextField/styles";
import { TextoClicavel } from "../../../components/modalAdicionar/adicionar-style";
import ReactLoading from "react-loading";
import axios from "axios";
import Close from "../../../assets/close.svg"

function Home() {
  //states para as informações
  const [quantidadeProdutos, setQuantidadeProdutos] = useState(0);
  const [quantidadeselos, setQuantidadeselos] = useState(0);
  const [quantidadeIngredientes, setQuantidadeIngredientes] = useState(0);
  const [quantidadeCategorias, setQuantidadeCategorias] = useState(0);
  const [faq, setFaq] = useState([]);
  const [trendings, setTrendings] = useState([]);

  //states para as ações de trendings
  const [trendingsModal, setTrendingsModal] = useState(false);
  const [selectTrending, setselectTrending] = useState({});
  const [alterarImagem, setAlterarImagem] = useState(false);
  const [imagem, setImagem] = useState([]);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [feedbackImagem, setFeedbackImagem] = useState("");
  const [nomeImagem, setNomeImagem] = useState();
  const [loading, setLoading] = useState(false);
  const [mensagemErro, setMensagemErro] = useState("");
  const [alertIsOpen, setAlertIsOpen] = useState(false);


  //states para as ações de Faq
  const [faqModal, setFaqModal] = useState(false);
  const [selectFaq, setselectFaq] = useState({});


  //funçoes de get para as informações
  const getFaq = async () => {
    const resposta = await api.get("api/faq/");
    console.log("resposta faq", resposta);
    if (resposta.data.count !== 0) {
      setFaq(resposta.data.results);
    }
  };

  const getTrendings = async () => {
    const resposta = await api.get("/api/trending/");
    console.log("resposta Trendings", resposta);
    if (resposta.data.count !== 0) {
      setTrendings(resposta.data);
    }
  };

  const getQuantidadeProdutos = async () => {
    try {
      const resposta = await api.get("/api/produtos/");
      console.log("resposta produtos", resposta);
      setQuantidadeProdutos(resposta.data.count);
    } catch (error) {
      console.log("erro produtos", error);
    }
  };

  const getQuantiadeSelos = async () => {
    try {
      const resposta = await api.get("/api/selos");
      console.log("resposta selos ", resposta);
      setQuantidadeselos(resposta.data.length);
    } catch (error) {
      console.log("erro selos", error);
    }
  };

  const getQuantiadeIngredientes = async () => {
    try {
      const resposta = await api.get("/api/ingredientes-sw/");
      console.log("resposta ingredientes ", resposta);
      setQuantidadeIngredientes(resposta.data.count);
    } catch (error) {
      console.log("erro Ingredientes", error);
    }
  };

  const getQuantiadeCategorias = async () => {
    try {
      const resposta = await api.get("/api/categorias/");
      console.log("resposta categoria ", resposta);
      setQuantidadeCategorias(resposta.data.count);
    } catch (error) {
      console.log("erro Ingredientes", error);
    }
  };



  //função para o modal de trendings
  const putImagem = async () => {
    const typeNovo = imagem.type.substring([6]);
    setNomeImagem("");
    setLoadingUpload(true);
    api
      .get("/api/file/" + typeNovo + "/")
      .then((response) => {
        const mediaUrl = response.data.media_url;
        const fileName = response.data.file_name;

        let foo = imagem.slice(0, imagem.size, imagem.type);
        let bar = new File([foo], response.data.file_name, {
          type: imagem.type,
        });

        axios
          .put(`${mediaUrl}`, bar, {
            headers: {
              "x-amz-acl": "public-read",
              "Content-Type": bar.type,
              "Content-Disposition": "attachment",
            },
          })
          .then((res) => {
            setNomeImagem(() => fileName);
            setLoadingUpload(false);
          });
      })

      .catch((err) => console.error(err));
  };

  const alterarDestaque = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await api.get("/auth/users/me/");
      const bodyHistory = {
        user: response.data.id,
        model_name: selectTrending.nome,
        model_type: "Trending",
        operation: "~",
      };
      await api.post("/api/editionhistory/", bodyHistory);
    } catch (error) {
      console.log(error);
    }

    const url = "/api/trending/" + selectTrending.id + "/";

    const body = {
      nome: selectTrending.nome,
      image_url: selectTrending.image_url,
      url_insta: selectTrending.url_insta,
      texto: selectTrending.texto,
    };
    try {
      await api.patch(url, body);
      setAlertIsOpen(true);
    } catch (error) {
      setMensagemErro("Erro ao Alterar Destaque");
    }
    setLoading(false);
  };

  useEffect(() => {
    getQuantidadeProdutos();
    getQuantiadeSelos();
    getQuantiadeIngredientes();
    getFaq();
    getTrendings();
    getQuantiadeCategorias();
  }, []);

  return (
    <>
      <Header />
      <Background>
        <RowDivision>
          <AreaDivision>
            <NameArea>Produtos cadastrados</NameArea>
            <CircleArea>
              <NumberCircle>{quantidadeProdutos}</NumberCircle>
            </CircleArea>
          </AreaDivision>
          <AreaDivision>
            <NameArea>Selos cadastrados</NameArea>
            <CircleArea>
              <NumberCircle>{quantidadeselos}</NumberCircle>
            </CircleArea>
          </AreaDivision>
          <AreaDivision>
            <NameArea>Ingredientes cadastrados</NameArea>
            <CircleArea>
              <NumberCircle>{quantidadeIngredientes}</NumberCircle>
            </CircleArea>
          </AreaDivision>
          <AreaDivision>
            <NameArea>Categorias cadastradas</NameArea>
            <CircleArea>
              <NumberCircle>{quantidadeCategorias}</NumberCircle>
            </CircleArea>
          </AreaDivision>
        </RowDivision>
        <FTBackground>
          <FTArea>
            <FTBackgroundTitle>
              <FTTitle>Últimos Faq`s</FTTitle>
            </FTBackgroundTitle>
            <LastItemsBackground>
              {faq[0] ? (
                <LastItems
                onClick={() => {
                  setselectFaq(faq[0]);
                  setFaqModal(true);
                }}>
                  <TextItems>{faq[0].pergunta}</TextItems>
                </LastItems>
              ) : null}
              {faq[1] ? (
                <LastItems
                onClick={() => {
                  setselectFaq(faq[1]);
                  setFaqModal(true);
                }}>
                  <TextItems>{faq[1].pergunta}</TextItems>
                </LastItems>
              ) : null}
              {faq[2] ? (
                <LastItems
                onClick={() => {
                  setselectFaq(faq[2]);
                  setFaqModal(true);
                }}>
                  <TextItems>{faq[2].pergunta}</TextItems>
                </LastItems>
              ) : null}
            </LastItemsBackground>
          </FTArea>
          <FTArea>
            <FTBackgroundTitle>
              <FTTitle>Últimos Trendings</FTTitle>
            </FTBackgroundTitle>
            <LastItemsBackground>
              {trendings[0] ? (
                <LastItems
                  onClick={() => {
                    setselectTrending(trendings[0]);
                    setTrendingsModal(true);
                  }}
                >
                  <TextItems>{trendings[0].nome}</TextItems>
                </LastItems>
              ) : null}
              {trendings[1] ? (
                <LastItems
                  onClick={() => {
                    setselectTrending(trendings[1]);
                    setTrendingsModal(true);
                  }}
                >
                  <TextItems>{trendings[1].nome}</TextItems>
                </LastItems>
              ) : null}
              {trendings[2] ? (
                <LastItems
                  onClick={() => {
                    setselectTrending(trendings[2]);
                    setTrendingsModal(true);
                  }}
                >
                  <TextItems>{trendings[2].nome}</TextItems>
                </LastItems>
              ) : null}
            </LastItemsBackground>
          </FTArea>
        </FTBackground>
        {trendingsModal ? (
          <Modal
            container="containerAdicionar"
            onClose={() => setTrendingsModal(false)}
          >
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <label id="titulo">Título</label>
              <TextDivModal height="40">
                <TextAreaModal
                disabled
                  id="titulo"
                  type="text"
                  placeholder="Insira um título para seu texto"
                  value={selectTrending.nome}
                  // onChange={(event) =>
                  //   setselectTrending({
                  //     ...selectTrending,
                  //     nome: event.target.value,
                  //   })
                  // }
                />
              </TextDivModal>
              {selectTrending.image_url && selectTrending.image_url !== "" ? (
                <img
                  src={selectTrending.image_url}
                  alt={selectTrending.nome}
                  style={{ width: "200px", height: "200px", marginTop: "30px" }}
                />
              ) : null}
              {/* <TextoClicavel>
                <button type="button" onClick={() => setAlterarImagem(true)}>
                  Alterar Imagem
                </button>
              </TextoClicavel> */}
              {/* {alterarImagem ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="file"
                    onChange={(event) => setImagem(event.target.files[0])}
                    accept="image/*"
                  />
                  {loadingUpload && (
                    <ReactLoading
                      type="spokes"
                      color="#E6AC86"
                      height={20}
                      width={20}
                    />
                  )}
                  <TextoClicavel>
                    <button type="button" onClick={(event) => putImagem(event)}>
                      {" "}
                      Confimar Imagem{" "}
                    </button>
                  </TextoClicavel>
                  <h6 style={{ size: "10px", margin: "0px" }}>
                    {feedbackImagem}
                  </h6>
                </div>
              ) : null}
              {selectTrending.texto !== "" && (
                <>
                  <label id="texto">Texto</label>
                  <textarea
                    id="texto"
                    type="text"
                    placeholder="Insira um texto"
                    value={selectTrending.texto}
                    onChange={(event) =>
                      setselectTrending({
                        ...selectTrending,
                        texto: event.target.value,
                      })
                    }
                  />
                </>
              )} */}
              {selectTrending.url_insta !== "" && (
                <>
                  <label id="link">Link do Post</label>
                  <TextDivModal height="60">
                    <TextAreaModal
                    disabled
                      style={{ width: "100%" }}
                      id="link"
                      type="text"
                      placeholder="Insira o Link para um Post do Instagram"
                      value={selectTrending.url_insta}
                      // onChange={(event) =>
                      //   setselectTrending({
                      //     ...selectTrending,
                      //     url_insta: event.target.value,
                      //   })
                      // }
                    />
                  </TextDivModal>
                </>
              )}
              {/* <button
                type="submit"
                onClick={(event) => alterarDestaque(event)}
                className="enviar"
              >
                {loading ? (
                  <ReactLoading
                    type="spin"
                    color="#fff"
                    height={20}
                    width={20}
                  />
                ) : (
                  "Confirmar"
                )}
              </button> */}
            </form>
            {/* <p style={{ color: "#ED4337" }}>{mensagemErro}</p> */}
          </Modal>
        ) : (
          <></>
        )}
        {alertIsOpen ? (
          <Modal
            container="containerAviso"
            onClose={() => setAlertIsOpen(false)}
          >
            <h4
              style={{
                alignContent: "center",
                justifyContent: "center",
                display: "flex",
                textAlign: "center",
              }}
            >
              Você alterou as informações de {selectTrending.nome} com sucesso!
            </h4>
          </Modal>
        ) : null}


        <Modallib
        isOpen={faqModal}
        onRequestClose={() => setFaqModal(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(153, 153, 153, 0.5)",
          },
          content: {
            marginLeft: '25vw',
            width: '500px',
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <button
          onClick={() =>  setFaqModal(false)}
          style={{
            width: "20px",
            height: "20px",
            backgroundColor: "trensparent",
            border: "none",
            display: "flex",
            alignItems: "center",
            marginLeft: "auto",
          }}
        >
          <img
            src={Close}
            alt="Fechar"
            style={{
              width: "10px",
              height: "10px",
              position: "right",
            }}
          />
        </button>
        <h2 style={{ marginBottom: "50px" }}>Faq</h2>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <label id="pergunta">Pergunta</label>
          <TextDivModal height= '60'>
          <TextAreaModal
          disabled
            style={{ width: "400px", marginBottom: "50px" }}
            type="text"
            id="pergunta"
            value={selectFaq.pergunta}
            // onChange={(event) => setpergunta(event.target.value)}
          />
          </TextDivModal>
          <label htmlFor="resposta">Resposta</label>
          <TextDivModal height= '160'>
          <TextAreaModal
          disabled
            style={{ width: "400px", marginBottom: "50px" }}
            type="text"
            id="resposta"
            value={selectFaq.resposta}
            // onChange={(event) => setresposta(event.target.value)}
          />
          </TextDivModal>
          {/* <input
            type="submit"
            value="Alterar"
            // onClick={(e) => alterarFAQ(e)}
            style={{
              color: "#fff",
              backgroundColor: "rgb(206, 147, 43)",
              borderRadius: "10px",
              margin: "15px",
              width: "50%",
              border: "none",
              cursor: "pointer",
            }}
          /> */}
        </form>
      </Modallib>
      </Background>
    </>
  );
}

export default Home;
