import styled from 'styled-components';

export const Box=styled.div`
    
    width: 90vw;
    max-height: 75vh;
    border-radius: 10px;
    padding: 10px;
    background-color: rgba(206, 147, 43, 0.8);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    gap: 6px;

    &::-webkit-scrollbar{
        width: 8px;
        background-color: rgba(206, 147, 43, 0.8);
        border-radius: 10px; 
    }
    &::-webkit-scrollbar-thumb{
        border-radius: 10px;
        background-color: rgba(102, 102, 102, 0.5);
    }
   
`
export const Tela=styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const InlineStyle=styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90vw;
    padding: 10px;
`