import styled from "styled-components";
import {Colors} from "../../utils/theme/constants"


export const RowButtonsOrderby = styled.div`
/* border: 1px solid red; */
width: 90vw;
padding: 10px 0px 20px 0px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
gap: 10px;
`

export const ButtonBackground = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
gap: 8px;
height: 20px;
width: 160px;
padding: 10px 20px;
border-radius: 12px;
background-color: ${props=> props.isSelected? Colors.GreyButtonsTable: Colors.White};
border:${props=> props.isSelected?`1px solid ${Colors.Black}`: `1px solid ${Colors.GreyButtonsTable}`  } ;
box-shadow: 0px 3px 3px rgba(0,0,0,0.3);
cursor: pointer;

`

export const IconImagem = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  opacity: ${props => props.opacity? props.opacity: 1 };
`;

export const ButtonText = styled.p`
font-size: 18px;
color: ${Colors.Black};
`

export const InfoType = styled.div`
/* border: 1px solid red; */
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
`

export const TextInfoType = styled.p`
color: ${Colors.Black};
font-size: 12px;
line-height: 15px;
margin: 0;
`