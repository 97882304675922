import React, { useEffect, useState } from "react";
import Header from "../../../components/header/header";
import {
  Container,
  Content,
  PreviewImagem,
  TextoClicavel,
  ContainerEdit,
  LinkEditavel,
  EditLink,
  InputLink,
} from "./rotulometro-style";
import api from "../../../services/api";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import edit from "../../../assets/edit.svg";
import check from "../../../assets/checked.svg";

function Rotulometro() {
  const [imagem, setImagem] = useState([]);
  const [mensagemFinal, setMensagemFinal] = useState("");
  const [rotulometroAtual, setRotulometroAtual] = useState("");
  const [linkCanva, setLinkCanva] = useState("");
  const [editLink, setEditLink] = useState(false);
  const [loading, setLoading] = useState(false);

  let urlImagem = "";

  const getLinkCanva = async () => {
   
    try {
      const response = await api.get("/api/canva/");
      setRotulometroAtual(response.data.rotulometro);
      setLinkCanva(response.data.canva_url);
    } catch (error) {
      console.log(error);
    }
  };

  const changeLink = async (e) => {
  
    const body = {
      canva_url: linkCanva,
    };
    try {
      const response = await api.put("/api/canva/", body);
      setLinkCanva(response.data.canva_url);
      setEditLink(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLinkCanva();
  }, []);

  const onFileUpload = async () => {
    if (!imagem.type) {
      return setMensagemFinal("Nenhum arquivo foi adicionado");
    }
    const typeNovo = imagem.type.split("/")[1];
    setLoading(true);
    api
      .get("/api/file/rotulometro/" + typeNovo + "/")
      .then((res) => {
        urlImagem = res.data.media_url;
        let foo = imagem.slice(0, imagem.size, imagem.type);
        let bar = new File([foo], res.data.file_name, {
          type: imagem.type,
        });
        axios
          .put(`${urlImagem}`, bar, {
            headers: {
              "x-amz-acl": "public-read",
              "Content-type": "application/pdf",
              "Content-Disposition": "inline",
            },
          })
          .then((res) => {
            console.log(res)
            setLoading(false);
            setMensagemFinal("Sucesso!");
          })
          .catch(() => setMensagemFinal("Erro no envio!"));
      })
      .catch(() => setMensagemFinal("Erro no envio!"));
  };

  return (
    <>
      <Header />
      <Container>
        <Content>
          <h3>Rotulometro</h3>
          <form>
            <TextField
              style={{ width: 300, marginBottom: "10px" }}
              id="imagem"
              type="file"
              onChange={(event) => setImagem(event.target.files[0])}
              accept=".pdf"
            />
            {imagem.type !== undefined && mensagemFinal === "" ? (
              <TextoClicavel>
                <button type="button" onClick={() => onFileUpload()}>
                  {loading ? "Carregando..." : "Confirmar Arquivo"}
                </button>
              </TextoClicavel>
            ) : (
              <TextoClicavel>
                <h3>{mensagemFinal}</h3>
              </TextoClicavel>
            )}
          </form>
          {editLink ? (
            <ContainerEdit>
              <InputLink
                value={linkCanva}
                onChange={(e) => setLinkCanva(e.target.value)}
              />
              <EditLink src={check} onClick={() => changeLink()} />
            </ContainerEdit>
          ) : (
            <ContainerEdit>
              <LinkEditavel onClick={() => window.open(linkCanva, "_blank")}>
                {linkCanva}
              </LinkEditavel>
              <EditLink src={edit} onClick={() => setEditLink(true)} />
            </ContainerEdit>
          )}
          <PreviewImagem onClick={() => window.open(rotulometroAtual)}>
            Documento Atual
          </PreviewImagem>
        </Content>
      </Container>
    </>
  );
}
export default Rotulometro;
