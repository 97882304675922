import React from 'react'
import { BackgroundMessage, Message } from './styles'

function ErrorMessage({ isVisible, message }) {
    return isVisible ? (
      <BackgroundMessage>
        <Message>{message}</Message>
      </BackgroundMessage>
    ) : null;
  }
export default ErrorMessage