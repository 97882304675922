import React from "react";
import {
  ButtonBackground,
  ButtonText,
  IconImagem,
  InfoType,
  TextInfoType,
} from "./styles";

import ArrowUp from "../../assets/arrowUp.svg";
import ArrowDown from "../../assets/arrowDown.svg";

function ButtonOrderBy({ title, ascendent, isSelected, type, text, onPress }) {
  return (
    <ButtonBackground
      onClick={() => {
        onPress();
      }}
      title={title}
      isSelected={isSelected}
    >
      {isSelected ? (
        ascendent === "descendent" ? (
          <>
            <IconImagem src={ArrowUp} width="15" height="15" />
            <IconImagem src={ArrowDown} width="15" height="15" opacity="0.2" />
          </>
        ) : (
          <>
            <IconImagem src={ArrowUp} width="15" height="15" opacity="0.2" />
            <IconImagem src={ArrowDown} width="15" height="15"  />
          </>
        )
      ) : (
        <>
          <IconImagem src={ArrowUp} width="15" height="15" opacity="0.2" />
          <IconImagem src={ArrowDown} width="15" height="15"  opacity="0.2"/>
        </>
      )}

      {type === "string" ? (
        <>
          <InfoType>
            <TextInfoType>A</TextInfoType>
            <TextInfoType>Z</TextInfoType>
          </InfoType>
        </>
      ) : null}
      {type === "numeric" ? (
        <>
          <InfoType>
            <TextInfoType>1</TextInfoType>
            <TextInfoType>10</TextInfoType>
          </InfoType>
        </>
      ) : null}

      <ButtonText>{text}</ButtonText>
    </ButtonBackground>
  );
}

export default ButtonOrderBy;
