import React from "react";
import Header from "../../../components/header/header";
import BoxHistorico from "../../../components/box-listas/box-historico";
import { Box, Tela, Buscar, InlineStyle } from "../usuarios/usuarios-style";
import {
  BotoesNavegar,
  Paginas,
  InlineStylePages,
} from "../ingredientes/ingredientes-style";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import Next from "../../../assets/next.svg";
import NamePages from "../../../components/NamePages";
import {
  ClickableArea,
  ContainerHeaderTable,
  HeaderTable,
  TextHeaderTable,
} from "../../../components/HeaderTable/styles";
import { RowButtonsOrderby } from "../../../components/buttonOrderBy/styles";
import ButtonOrderBy from "../../../components/buttonOrderBy";

function Histórico() {
  const [historico, setHistorico] = useState([]);
  const [numeroDaPagina, setNumeroDaPagina] = useState(1);
  const [numeroTotalPaginas, setNumeroTotalPaginas] = useState(1);
  const [isEmpty, setIsEmpty] = useState(false);
  const [orderBySelect, setOrderBySelect] = useState("nome");
  const [typeOrder, setTypeOrder] = useState("ascendent");

  const onPressButtonOrder = (order) => {
    console.log("order", order);
    console.log("orderBySelect", orderBySelect);
    if (order === orderBySelect) {
      typeOrder === "ascendent"
        ? setTypeOrder("descendent")
        : setTypeOrder("ascendent");
    } else {
      setTypeOrder("ascendent");
      setOrderBySelect(order);
    }
  };

  useEffect(() => {
    console.log(
      "requisicao",
      `/api/editionhistory?ordering=${
        typeOrder === "ascendent" ? "" : "-"
      }${orderBySelect}`
    );
    api
      .get(
        `/api/editionhistory?ordering=${
          typeOrder === "ascendent" ? "" : "-"
        }${orderBySelect}`
      )
      .then(function (response) {
        if (response.data.results.length === 0) {
          setIsEmpty(true);
        } else {
          setIsEmpty(false);
        }

        setHistorico(response.data.results);
        console.log(response.data.results);
        setNumeroDaPagina(1);
        setNumeroTotalPaginas(Math.ceil(response.data.count / 15));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [orderBySelect, typeOrder]);

  const PaginaAtual = async (data) => {
    if (data > 0 && data <= numeroTotalPaginas) {
      setNumeroDaPagina(data);
      const response = await api.get(`/api/editionhistory/?page=${data}`);
      setHistorico(response.data.results);
    }
  };

  return (
    <>
      <Header />
      <Tela>
        <NamePages BackgroundColor={"#879F85"} NamePage={"Histórico"} />
        <InlineStyle></InlineStyle>
        {isEmpty ? null : (
          <InlineStylePages>
            {numeroDaPagina > 1 && (
              <BotoesNavegar
                type="button"
                onClick={() => PaginaAtual(numeroDaPagina - 1)}
              >
                <img src={Next} alt="Voltar" className="rotateimg180" />
              </BotoesNavegar>
            )}
            <Paginas>
              {numeroDaPagina > 10 ? (
                <button onClick={() => PaginaAtual(1)}>{1}</button>
              ) : null}
              {numeroDaPagina > 10 ? (
                <button onClick={() => PaginaAtual(2)}>{2}</button>
              ) : null}
              {numeroDaPagina > 10 ? <h3>...</h3> : null}
              <button
                onClick={() => PaginaAtual(numeroDaPagina)}
                style={{ backgroundColor: "rgb(135, 159, 133)" }}
              >
                {numeroDaPagina}
              </button>
              {numeroDaPagina + 1 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 1)}>
                  {numeroDaPagina + 1}
                </button>
              )}
              {numeroDaPagina + 2 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 2)}>
                  {numeroDaPagina + 2}
                </button>
              )}
              {numeroDaPagina + 3 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 3)}>
                  {numeroDaPagina + 3}
                </button>
              )}
              {numeroDaPagina + 4 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 4)}>
                  {numeroDaPagina + 4}
                </button>
              )}
              {numeroDaPagina + 5 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 5)}>
                  {numeroDaPagina + 5}
                </button>
              )}
              {numeroDaPagina + 6 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 6)}>
                  {numeroDaPagina + 6}
                </button>
              )}
              {numeroDaPagina + 7 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 7)}>
                  {numeroDaPagina + 7}
                </button>
              )}
              {numeroDaPagina + 8 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 8)}>
                  {numeroDaPagina + 8}
                </button>
              )}
              {numeroDaPagina + 9 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 9)}>
                  {numeroDaPagina + 9}
                </button>
              )}
              {numeroDaPagina !== numeroTotalPaginas &&
              numeroTotalPaginas > 5 ? (
                <h3>...</h3>
              ) : null}
              {numeroDaPagina !== numeroTotalPaginas &&
              numeroTotalPaginas > 5 ? (
                <button onClick={() => PaginaAtual(numeroTotalPaginas)}>
                  {numeroTotalPaginas}
                </button>
              ) : null}
            </Paginas>
            {numeroDaPagina < numeroTotalPaginas && (
              <BotoesNavegar
                type="button"
                onClick={() => PaginaAtual(numeroDaPagina + 1)}
              >
                <img src={Next} alt="Avançar" />
              </BotoesNavegar>
            )}
          </InlineStylePages>
        )}
        <RowButtonsOrderby>
          <ButtonOrderBy
            onPress={() => {
              onPressButtonOrder("nome");
            }}
            title={
              typeOrder === "ascendent"
                ? "clique para ordenar de forma descendente"
                : "clique para ordenar de forma ascendete"
            }
            isSelected={orderBySelect === "nome" ? true : false}
            type="string"
            ascendent={typeOrder}
            text="Nome"
          />
        </RowButtonsOrderby>
        <HeaderTable backgroundColor="#ADB9AB">
          <ContainerHeaderTable
            // width="20"
            minWidth="200"
            justify="start"
            marginLeft={30}
            marginRight={10}
          >
            {/* <ClickableArea
              title={
                orderBySelect === "nome"
                  ? "Retirar Ordenação"
                  : "Ordenar por Nome"
              }
              isSelect={orderBySelect === "nome" ? true : false}
            > */}
            <TextHeaderTable>Nome</TextHeaderTable>
            {/* </ClickableArea> */}
          </ContainerHeaderTable>
          <ContainerHeaderTable
            width="80"
            justify="start"
            marginLeft={10}
            marginRight={700}
          >
            {/* <ClickableArea> */}
            <TextHeaderTable>Ação</TextHeaderTable>
            {/* </ClickableArea> */}
          </ContainerHeaderTable>
        </HeaderTable>
        {isEmpty ? (
          <h1>Ainda não há dados nesta página</h1>
        ) : (
          <Box>
            {historico.map((data) => (
              <div key={data.id}>
                <BoxHistorico
                  nomeUsuario={data.username}
                  nomeModelo={data.model_name}
                  tipoModelo={data.model_type.toLowerCase()}
                  operaçao={
                    data.operation === "+"
                      ? "adicionou"
                      : data.operation === "-"
                      ? "deletou"
                      : "alterou"
                  }
                  data={data.date
                    .slice(8, 10)
                    .concat(`/${data.date.slice(5, 7)}`)
                    .concat(`/${data.date.slice(0, 4)}`)}
                  hora={data.date.slice(11, 16)}
                />
              </div>
            ))}
          </Box>
        )}
      </Tela>
    </>
  );
}
export default Histórico;
