import React from 'react'
import { BackgroundHeader, HeaderTitle } from './styles'

function NamePages({NamePage, BackgroundColor}) {
  return (
    <BackgroundHeader BackgroundColor={BackgroundColor}>
      <HeaderTitle>{NamePage}</HeaderTitle>
    </BackgroundHeader>
  )
}

export default NamePages;