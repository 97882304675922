import {
  DragDropContext,
  Draggable,
  Droppable,
} from "@brightalley/react-beautiful-dnd";
import React, { useState } from "react";
import Toast from "../../../components/Toast";
import Header from "../../../components/header/header";
import {
  Box,
  InputAreaModal,
  Tela
} from "../../signedIn/ingredientes/ingredientes-style";

import {
  TextAreaModal,
  TextDivModal
} from "../../../components/TextField/styles";

import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import Cancelar from "../../../assets/cancelar.svg";
import edit from "../../../assets/editTable.svg";
import {
  ClickableArea,
  ContainerHeaderTable,
  HeaderTable,
  TextHeaderTable,
} from "../../../components/HeaderTable/styles";
import NamePages from "../../../components/NamePages";
import { IconImage } from "../../../components/box-listas/styles";
import Modal from "../../../components/modal/modal";
import {
  Adicionar,
  InlineStyle,
} from "../../../components/modalAdicionar/adicionar-style";
import api from "../../../services/api";
import { Colors } from "../../../utils/theme/constants";
import { toastProps } from "../../../utils/toastProps";
import { AreaDrop, DropDiv, DroppableDiv, H1text, OptionsArea } from "./styles";


const OrderingChoices = [
  { nome: "Mais visualizados", back: "visualizacoes" },
  { nome: "Mais scaneados", back: "scans" },
  { nome: "Melhores avaliados", back: "estrelinha" },
];

function encontrarObjetoPorChaveBack(valorChaveBack) {
  for (let i = 0; i < OrderingChoices.length; i++) {
    const objeto = OrderingChoices[i];
    if (objeto.back === valorChaveBack) {
      return objeto;
    }
  }
  return undefined;
}

function TrendingsHighlights() {
  const history = useHistory();

  const [columns, setColumns] = useState([]);
  const [columnsLenght, setcolumnsLenght] = useState(1000);
  const [deletarDestaqueIsOpen, setDeletarDestaqueIsOpen] = useState(false);
  const [modalEditVisibility, setModalEditVisibility] = useState(false);

  //poderia ser um objeto mas fiz separado e deixei assim mesmo
  const [id, setId] = useState(null);
  const [nome, setNome] = useState(""); //content
  const [marca, setMarca] = useState(null); //deve ser null quando nao tiver
  const [lenghtValue, setLenghtValue] = useState(1);
  const [categorias, setCategorias] = useState([]);
  const [orderBy, setOrderBy] = useState("");
  //nao coloquei o order by do drag and drop

  const [opcoesCategoria, setOpcoesCategoria] = useState([]);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState(undefined);
  const [opcoesOrdenacao, setOpcoesOrdenacao] = useState(OrderingChoices);
  const [ordenacaoSelecionada, setOrdenacaoSelecionada] = useState(undefined);

  const [modalIsOpen, setIsOpen] = useState(false);

  const onClickAddNewDestaque = () => {
    if (columns.length >= 4) {
      toast.error("Só é possível adicionar 4 destaques", toastProps);
      return;
    }
    isOpen(true);
  };

  const fetchDestaques = async () => {
    try {
      const { data: fetchedDestaques } = await api.get("/api/destaques/");
      console.log("fetchedDestaques", fetchedDestaques.results);
      fetchedDestaques.results.map((item) => (item.id = item.id.toString()));
      setColumns(fetchedDestaques.results);
      setcolumnsLenght(fetchedDestaques.results.length);
    } catch (error) {
      toast.error("Erro ao carregar Destaques", toastProps);
      console.log("erro", error);
    }
  };

  //pega as cotegorias
  const fetchCategories = async () => {
    try {
      const { data: fetchedCategories } = await api.get("/api/categorias/all/");
      setOpcoesCategoria(fetchedCategories);
    } catch (error) {
      toast.error("Erro ao carregar categorias", toastProps);
    }
  };

  useEffect(() => {
    fetchDestaques();
    fetchCategories();
  }, []);

  function onDragEnd(result) {
    let resultId = {};
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].id == result.draggableId) {
        resultId = columns[i];
      }
    }
    const filtro = columns.filter((item) => item.id != result.draggableId);
    filtro.splice(result?.destination?.index, 0, resultId);
    let columnsCopy = JSON.parse(JSON.stringify(columns));
    columnsCopy = filtro;
    setColumns(columnsCopy);
  }

  const onOpenModalEdit = (item) => {
    let objetosFiltrados = "";

    if (item.categorias) {
      const idsProcurados = item.categorias.split(",");
      objetosFiltrados = opcoesCategoria.filter((objeto) =>
        idsProcurados.includes(objeto.id.toString())
      );
    }

    setId(item.id);
    setNome(item.content);
    setMarca(item.marca);
    setLenghtValue(item.length);
    objetosFiltrados
      ? setCategoriaSelecionada(objetosFiltrados)
      : setCategoriaSelecionada(undefined);
    item.orderby
      ? setOrdenacaoSelecionada(encontrarObjetoPorChaveBack(item.orderby))
      : setOrdenacaoSelecionada(undefined);
    setModalEditVisibility(true);
  };

  function isOpen() {
    setId(null);
    setNome("");
    setMarca(null);
    setLenghtValue(1);
    setCategoriaSelecionada(undefined);
    setOrdenacaoSelecionada(undefined);
    setIsOpen(true);
  }

  function modalOnClose() {
    setIsOpen(false);
  }

  const changeOrder = async () => {
    try {
      const categorias = columns.map((item) => parseInt(item.id));
      console.log("aaaaa",categorias)
      const body = {
        destaques: categorias,
      };
      await api.patch("/api/destaques/dragdrop/", body);
      toast.success("Ordem alterada com sucesso");
      history.goBack();
    } catch (error) {
      toast.error("Erro ao alterar ordem");
    }
  };

  const onDeleteClick = (item) => {
    setId(item.id);
    setNome(item.content);
    setMarca(item.marca);
    setLenghtValue(item.length);
    item?.categorias
      ? setCategoriaSelecionada(item.categorias)
      : setCategoriaSelecionada(undefined);
    item.orderby
      ? setOrdenacaoSelecionada({ nome: item.orderby })
      : setOrdenacaoSelecionada(undefined);
    setDeletarDestaqueIsOpen(true);
  };

  const deleteDestaque = async () => {
    try {
      console.log("id", id);
      await api.delete(`/api/destaques/${id}`);
      await fetchDestaques();
      setDeletarDestaqueIsOpen(false);
      toast.success("Destaque apagado com sucesso");
    } catch (error) {
      toast.error("Não foi possível apagar esse Destaque");
    }
  };

  const addNewProduct = async () => {
    try {
      if(ordenacaoSelecionada?.back && lenghtValue > 0 && nome )
      {
      let idsString;
      if (categoriaSelecionada?.length > 0) {
        const ids = categoriaSelecionada.map((objeto) => objeto.id);
        idsString = ids.join(",");
      } else {
        idsString = "";
      }

      console.log("nome",nome)
      console.log("lenght",lenghtValue)
      console.log("marca",marca)
      console.log("categorias",idsString ? idsString : null,)
      console.log("orderby",ordenacaoSelecionada?.back ? ordenacaoSelecionada.back : null,)
      console.log("order_drag_drop",null)

      await api.post("/api/destaques/", {
        content: nome,
        length: lenghtValue,
        marca: marca,
        categorias: idsString ? idsString : null,
        orderby: ordenacaoSelecionada?.back ? ordenacaoSelecionada.back : null,
        order_drag_drop: null,
      });
      toast.success("Destaque adicionado com sucesso");
      setIsOpen(false);
      await fetchDestaques();
    }
    else{
      toast.error("Preencha todos os dados necessários");
    }
    } catch (error) {
      console.log("erro", error);
      toast.error("Não foi possível adicionar seu destaque");
    }
  };

  const updateProduct = async () => {
    try {
      if(ordenacaoSelecionada?.back && lenghtValue > 0 && nome )
      {
      let idsString;
      if (categoriaSelecionada?.length > 0) {
        const ids = categoriaSelecionada.map((objeto) => objeto.id);
        idsString = ids.join(",");
      } else {
        idsString = "";
      }
      console.log("nome",nome)
      console.log("lenght",lenghtValue)
      console.log("marca",marca)
      console.log("categorias",idsString ? idsString : null,)
      console.log("orderby",ordenacaoSelecionada?.back ? ordenacaoSelecionada.back : null,)
      console.log("order_drag_drop",null)
      await api.patch(`/api/destaques/${id}/`, {
        content: nome,
        length: lenghtValue,
        marca: marca,
        categorias: idsString ? idsString : null,
        orderby: ordenacaoSelecionada?.back ? ordenacaoSelecionada.back : null,
      });
      toast.success("Destaque editado com sucesso", toastProps);
      setModalEditVisibility(false);
      await fetchDestaques();
    }
    else{
      toast.error("Preencha todos os dados necessários");
    }
    } catch (error) {
      console.log("erro", error);
      toast.error("Não foi possível editar seu destaque", toastProps);
    }
  };

  return (
    <>
      <Toast />
      <div>
        <Header />
        <Tela>
          <NamePages BackgroundColor={"#E6AC86"} NamePage={"Destaques"} />
          <InlineStyle>
            <Adicionar onClick={() => changeOrder()}>
              <button type="button">
                <p style={{ color: Colors.White }}>Salvar Mudanças</p>
              </button>
            </Adicionar>
            <Adicionar
            >
              <button type="button" onClick={() => onClickAddNewDestaque()}>
                <p style={{ color: Colors.White }}>Adicionar Destaque</p>
              </button>
            </Adicionar>
          </InlineStyle>
          <HeaderTable backgroundColor="#A5553C">
            <ContainerHeaderTable
              width="80"
              justify="start"
              marginLeft={30}
              marginRight={10}
            >
              <ClickableArea
              // title={
              //   orderBySelect === "nome"
              //     ? "Retirar Ordenação"
              //     : "Ordenar por Nome"
              // }
              // onClick={() => {
              //   orderBy("nome");
              // }}
              // isSelect={orderBySelect === "nome" ? true : false}
              >
                <TextHeaderTable>Nome</TextHeaderTable>
              </ClickableArea>
            </ContainerHeaderTable>
            <ContainerHeaderTable
              minWidth="90"
              justify="center"
              marginLeft={0}
              marginRight={30}
            >
              <ClickableArea>
                <TextHeaderTable>Opções</TextHeaderTable>
              </ClickableArea>
            </ContainerHeaderTable>
          </HeaderTable>
          <Box>
            <DroppableDiv>
              <DragDropContext
                onDragEnd={(result) => {
                  onDragEnd(result);
                }}
              >
                {columns.length > 0 ? (
                  <Droppable droppableId={"0"} key={"0"}>
                    {(provided) => (
                      <AreaDrop ref={provided.innerRef}>
                        {columns.map((item, index) => (
                          <Draggable
                            draggableId={item.id}
                            index={index}
                            key={item.id}
                          >
                            {(provided) => (
                              <DropDiv
                                style={{ ...provided.draggableProps.style }}
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                <H1text>{item.content}</H1text>
                                <OptionsArea>
                                  <IconImage
                                    width={20}
                                    height={20}
                                    src={edit}
                                    onClick={() => onOpenModalEdit(item)}
                                  ></IconImage>
                                  <IconImage
                                    width={20}
                                    height={20}
                                    src={Cancelar}
                                    onClick={() => onDeleteClick(item)}
                                  ></IconImage>
                                </OptionsArea>
                              </DropDiv>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </AreaDrop>
                    )}
                  </Droppable>
                ) : (
                  <></>
                )}
              </DragDropContext>
            </DroppableDiv>
          </Box>
        </Tela>
      </div>

      {deletarDestaqueIsOpen ? (
        <Modal
          container="containerAvisoDeletar"
          onClose={() => setDeletarDestaqueIsOpen(false)}
        >
          <h2 id="textoDeletar">
            Deseja mesmo deletar este Destaque de forma definitiva?
          </h2>
          <div id="botoesDeletar">
            <button id="deletar" onClick={() => deleteDestaque()}>
              SIM
            </button>
            <button
              id="deletar"
              onClick={() => setDeletarDestaqueIsOpen(false)}
            >
              NÃO
            </button>
          </div>
        </Modal>
      ) : null}

      {modalEditVisibility ? (
        <Modal
          container="containerAdicionar"
          onClose={() => setModalEditVisibility(false)}
        >
          <p style={{ fontSize: "20px", fontWeight: "bold" }}>
            Editar Destaque
          </p>
          <label htmlFor="nome">Nome<strong style={{ color: "red" }}>*</strong></label>
          <TextDivModal height="50">
            <TextAreaModal
              style={{ width: 300 }}
              type="text"
              id="nome"
              value={nome}
              onChange={(event) => setNome(event.target.value)}
            />
          </TextDivModal>
          <label htmlFor="Marca">Marca</label>
          <TextDivModal height="50">
            <TextAreaModal
              style={{ width: 300 }}
              type="text"
              id="Marca"
              value={marca}
              onChange={(event) => setMarca(event.target.value)}
            />
          </TextDivModal>
          <label htmlFor="Lenght">Quantidade de produtos<strong style={{ color: "red" }}>*</strong></label>
          <TextDivModal height="50">
            <InputAreaModal
              style={{ width: 300 }}
              type="number"
              id="Lenght"
              min={1}
              max={30}
              value={lenghtValue}
              onChange={(event) => setLenghtValue(event.target.value)}
            />
          </TextDivModal>
          <label htmlFor="categorias">Categorias</label>
          <TextDivModal>
            <Autocomplete
              getOptionSelected={(option, value) => option.id === value.id}
              value={categoriaSelecionada}
              style={{ width: 300 }}
              options={opcoesCategoria}
              loadingText={"Carregando Categorias..."}
              onChange={(selected, values) => setCategoriaSelecionada(values)}
              autoHighlight
              multiple
              getOptionLabel={(option) => option?.nome}
              renderOption={(option) => <>{option?.nome}</>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={null}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </TextDivModal>
          <label htmlFor="categorias">Ordenação<strong style={{ color: "red" }}>*</strong></label>
          <TextDivModal>
            <Autocomplete
              style={{ width: 300 }}
              options={opcoesOrdenacao}
              value={ordenacaoSelecionada}
              loadingText={"Carregando Ordenação..."}
              onChange={(selected, values) => setOrdenacaoSelecionada(values)}
              autoHighlight
              getOptionLabel={(option) => option?.nome}
              renderOption={(option) => <>{option?.nome}</>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={null}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                  value={"ordenacaoSelecionada"}
                />
              )}
            />
          </TextDivModal>
          <button
            type="button"
            onClick={() => updateProduct()}
            className="botaoSubmit"
          >
            Salvar Edição
          </button>
        </Modal>
      ) : (
        <></>
      )}

      {modalIsOpen ? (
        <Modal container="containerAdicionar" onClose={() => modalOnClose()}>
          <p style={{ fontSize: "20px", fontWeight: "bold" }}>
            Adicionar Destaque
          </p>
          <label htmlFor="nome">Nome<strong style={{ color: "red" }}>*</strong></label>
          <TextDivModal height="50">
            <TextAreaModal
              style={{ width: 300 }}
              type="text"
              id="nome"
              value={nome}
              onChange={(event) => setNome(event.target.value)}
            />
          </TextDivModal>
          <label htmlFor="Marca">Marca</label>
          <TextDivModal height="50">
            <TextAreaModal
              style={{ width: 300 }}
              type="text"
              id="Marca"
              value={marca}
              onChange={(event) => setMarca(event.target.value)}
            />
          </TextDivModal>
          <label htmlFor="Lenght">Quantidade de produtos<strong style={{ color: "red" }}>*</strong></label>
          <TextDivModal height="50">
            <InputAreaModal
              style={{ width: 300 }}
              type="number"
              id="Lenght"
              min={1}
              max={30}
              value={lenghtValue}
              onChange={(event) => setLenghtValue(event.target.value)}
            />
          </TextDivModal>
          <label htmlFor="categorias">Categorias</label>
          <TextDivModal>
            <Autocomplete
              getOptionSelected={(option, value) => option.id === value.id}
              value={categoriaSelecionada}
              style={{ width: 300 }}
              options={opcoesCategoria}
              loadingText={"Carregando Categorias..."}
              onChange={(selected, values) => setCategoriaSelecionada(values)}
              autoHighlight
              multiple
              getOptionLabel={(option) => option?.nome}
              renderOption={(option) => <>{option?.nome}</>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={null}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </TextDivModal>
          <label htmlFor="categorias">Ordenação<strong style={{ color: "red" }}>*</strong></label>
          <TextDivModal>
            <Autocomplete
              style={{ width: 300 }}
              options={opcoesOrdenacao}
              value={ordenacaoSelecionada}
              loadingText={"Carregando Ordenação..."}
              onChange={(selected, values) => setOrdenacaoSelecionada(values)}
              autoHighlight
              getOptionLabel={(option) => option?.nome}
              renderOption={(option) => <>{option?.nome}</>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={null}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                  value={"ordenacaoSelecionada"}
                />
              )}
            />
          </TextDivModal>
          <button
            type="button"
            onClick={() => addNewProduct()}
            className="botaoSubmit"
          >
            Adicionar Destaque
          </button>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
}

export default TrendingsHighlights;
