import styled from "styled-components"

export const AddButton = styled.button`
  background: transparent;
  border: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: relative;
  top: -30px;
  left: 250px;
  transition: background 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 100;
  &:hover{
    background: #d6d6d6;
  }

  `