import React, { useState, useEffect } from "react";
import {
  Comentarios,
  ListElement,
  Numeros,
  EstiloLinha,
} from "./box-lista-style.js";
import "./score-style.css";
import "../../global-styles.css";
import api from "../../services/api.js";
import Modal from "../modal/modal.js";
import { TextoClicavel } from "../modalAdicionar/adicionar-style.js";
import axios from "axios";
import Busca from "../busca/busca.js";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Cancelar from "../../assets/cancelar.svg";
import edit from "../../assets/editTable.svg";
import CreateIcon from "@material-ui/icons/Create";
import { toast } from "react-toastify";
import { toastProps } from "../../utils/toastProps.js";
import {
  TextAreaModal,
  TextDivModal,
  TextInputModal,
} from "../TextField/styles.js";
import { IconImage, ItemContainer, TextTable } from "./styles.js";
import { ContainerHeaderTable } from "../HeaderTable/styles.js";
import CloseAdvice from "../CloseAdvice/index.js";

function BoxProduto({ getProducts, ...props }) {
  let tipoDeScore;

  if (props.score >= 8) {
    tipoDeScore = "Risco";
  }
  if (props.score <= 7 && props.score >= 6) {
    tipoDeScore = "Moderado";
  }
  if (props.score >= 4 && props.score <= 5) {
    tipoDeScore = "Ok";
  }
  if (props.score <= 3) {
    tipoDeScore = "Bom";
  }

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [modalcloseAdviceIsOpen, setModalcloseAdviceIsOpen] = useState(false);
  const [likes, setLikes] = useState(props.likes);
  const [numeroComentarios, setNumeroComentarios] = useState(
    props.numeroComentarios
  );
  const [media, setMedia] = useState(props.media);
  const [categoria, setCategoria] = useState([]);
  const [subCategorias, setSubCategorias] = useState([]);
  const [categoriaAlterada, setCategoriaAlterada] = useState([]);
  const [subCategoriaAlterada, setSubCategoriaAlterada] = useState([]);
  const [novaCategoria, setNovaCategoria] = useState("");
  const [novoScore, setNovoScore] = useState(props.score);
  const [marca, setMarca] = useState(props.marca);
  const [volume, setVolume] = useState(props.volume);
  const [score, setScore] = useState(props.score);
  const [upc, setUpc] = useState(props.upc);
  const [imagem, setImagem] = useState(props.imagem);
  const [ingredientes, setIngredientes] = useState([]);
  const [mensagemErro, setMensagemErro] = useState("");
  const [alterarImagem, setAlterarImagem] = useState(false);
  const [feedbackImagem, setFeedbackImagem] = useState("");
  const [ingredientesNovos, setIngredientesNovos] = useState([]);
  const [ingredienteAlterado, setIngredienteAlterado] = useState(false);
  const [categoriaFoiAlterada, setCategoriaFoiAlterada] = useState(false);
  const [certificados, setCertificados] = useState([]);
  const [adicionarCategoriaIsOpen, setAdicionarCategoriaIsOpen] =
    useState(false);
  const [alterarIngredientesIsOpen, setAlterarIngredientesIsOpen] =
    useState(false);
  const [alterarCategoriasIsOpen, setAlterarCategoriasIsOpen] = useState(false);
  const [alterarCertificadosIsOpen, setAlterarCertificadosIsOpen] =
    useState(false);
  const [deletarProdutoIsOpen, setDeletarProdutoIsOpen] = useState(false);
  const [comentariosIsOpen, setComentariosIsOpen] = useState(false);
  const [adicionarIngredientesIsOpen, setAdicionarIngredientesIsOpen] =
    useState(false);
  const [comentarios, setComentarios] = useState([]);
  const [categoriasFinais, setCategoriasFinais] = useState([]);
  const [certificadosFinais, setCertificadosFinais] = useState([]);
  const [opcoesCategoria, setOpcoesCategoria] = useState([]);
  const [opcoesSubcategoria, setOpcoesSubcategoria] = useState([]);
  const [scoreIngrediente, setScoreIngrediente] = useState("");
  const [nome, setNome] = useState(props.nome);
  const [funcao, setFuncao] = useState("");
  const [referencias, setReferencias] = useState("");
  const [descricao, setDescricao] = useState("");
  const [impacto, setImpacto] = useState("");
  const [sinonimos, setSinonimos] = useState("");
  const [inci, setInci] = useState("");
  const [cas, setCas] = useState("");
  const [ec, setEc] = useState("");
  const [carcinogenese, setCarcinogenese] = useState("");
  const [toxidade, setToxidade] = useState("");
  const [potencialAlergico, setPotencialAlergico] = useState("");
  const [opcoesCertificados, setOpcoesCertificados] = useState([]);

  const url = "/api/produtos/" + props.id + "/";

  const getCertificados = async () => {
    try {
      const res = await api.get("/api/selos/");
      setOpcoesCertificados(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (modalIsOpen) {
      getCertificados();
    }
  }, [modalIsOpen]);

  const alterarProduto = async (e) => {
    e.preventDefault();
    if (nome === "") {
      setMensagemErro("Insira um nome");
      return;
    }
    if (categoriasFinais.length === 0) {
      setMensagemErro("Insira uma categoria");
      return;
    }
    if (marca === "") {
      setMensagemErro("Insira uma marca");
      return;
    }
    if (upc === "") {
      setMensagemErro("Insira um código de barras");
      return;
    }
    let ingredientesId = [];
    ingredientesId =
      ingredientesNovos.length > 0
        ? ingredientesNovos.map((ingrediente) => {
            return ingrediente.id;
          })
        : ingredientes.map((ingrediente) => {
            return ingrediente.id;
          });

    const body = {
      nome: nome,
      categorias: categoriasFinais,
      marca: marca,
      volume: volume,
      upc: upc,
      selos:
        certificadosFinais.length > 0
          ? certificadosFinais.map((certificado) => {
              return certificado.id;
            })
          : certificados.map((certificado) => {
              return certificado.id;
            }),
      ingredientes: ingredientesId,
    };

    try {
      const response = await api.get("/auth/users/me/");
      const bodyHistory = {
        user: response.data.id,
        model_name: props.nome,
        model_type: "Produto",
        operation: "~",
      };
      await api.patch(`/api/produtos/${props.id}/`, body);
      await api.post("/api/editionhistory/", bodyHistory);
      setModalIsOpen(false);
      setAlertIsOpen(true);
      setMensagemErro("");
    } catch {
      setMensagemErro("Erro ao Alterar Produto");
    }
  };

  const adicionarIngrediente = async (e) => {
    e.preventDefault();
    const body = {
      nome: nome,
      descricao: descricao,
      impacto: impacto,
      score: scoreIngrediente,
      sinonimos: sinonimos,
      inci: inci,
      ec: ec,
      cas: cas,
      carcinogenese: carcinogenese,
      toxidade: toxidade,
      potencial_alergico: potencialAlergico,
      funcao: funcao,
      referencias: referencias,
    };

    try {
      const res = await api.get("/auth/users/me/");
      const bodyHistory = {
        user: res.data.id,
        model_name: nome,
        model_type: "Ingrediente",
        operation: "+",
      };
      await api.post("/api/editionhistory/", bodyHistory);
      const response = await api.post("/api/ingredientes/", body);
      setAdicionarIngredientesIsOpen(false);
      await ingredientes.push(response.data.id);
    } catch (error) {
      setMensagemErro(error.response?.data?.upc);
      toast.error("Erro ao adicionar ingredientes", toastProps);
    }
  };

  const getInfoProduto = async () => {
    setIngredientesNovos([]);
    try {
      const response = await api.get(url);
      setLikes(response.data.likes);
      setNumeroComentarios(response.data.comentarios);
      setMedia(response.data.media);
      setCategoria(response.data.main_category);
      setSubCategorias(response.data.categorias);
      setMarca(response.data.marca);
      setVolume(response.data.volume);
      setUpc(response.data.upc);
      setIngredientes(response.data.ingredientes);
      setCertificados(response.data.selos);
      let idsCategoria = [];
      idsCategoria[0] = response.data.main_category.id;
      response.data.categorias.forEach((cat) => idsCategoria.push(cat.id));
      setCategoriasFinais(idsCategoria);
      setModalIsOpen(true);
    } catch (error) {
      setMensagemErro(error.message);
    }
  };

  const putImagem = async () => {
    if (!imagem.type) {
      return;
    }
    const typeNovo = imagem.type.substring([6]);

    await api
      .get("/api/file/upload/" + typeNovo + "/")
      .then(async (res) => {
        let foo = imagem.slice(0, imagem.size, imagem.type);
        // let bar = new File([foo], res.data.file_name, {
        //   type: imagem.type,
        // });
        const formData = new FormData();
        formData.append("file_name", res.data.file_name);
        formData.append("file", imagem);
        console.log("res.data.file_name", res.data.file_name);
        console.log("imagem", imagem);

        await api
          .post("/api/file/upload/" + typeNovo + "/", formData)
          .then((response) => {
            api
              .patch(`/api/produtos/${props.id}/`, {
                image_url: res.data.file_name,
              })
              .then((response) => {
                setFeedbackImagem("Imagem Alterada com Sucesso!");
              })
              .catch((err) => {
                setFeedbackImagem("Erro Selecionando Imagem");
              });
          })
          .catch((err) => {
            setFeedbackImagem("Erro Selecionando Imagem");
          });
      })
      .catch((err) => console.error(err));
  };

  let nomeReduzido = props.nome.substring(0, 50);

  if (props.nome.length >= 50)
    nomeReduzido = props.nome.substring(0, 40) + "...";

  useEffect(() => {
    if (score > 10) {
      setMensagemErro("Não adicione score maior que 10");
    }
  }, [score]);

  const fetchCategories = async () => {
    try {
      const { data: fetchedCategories } = await api.get("/api/categorias/all/");
      setOpcoesCategoria(fetchedCategories);
      setOpcoesSubcategoria(fetchedCategories);
    } catch (error) {
      toast.error("Erro ao carregar categorias", toastProps);
    }
  };

  useEffect(() => {
    if (alterarCategoriasIsOpen === true) {
      fetchCategories();
    }
  }, [alterarCategoriasIsOpen]);

  const adicionarCategoria = async () => {
    const body = {
      nome: novaCategoria,
    };
    api.post("/api/categorias/", body);
  };

  const deletarProduto = async (e, id) => {
    e.preventDefault();
    try {
      const response = await api.get("/auth/users/me/");
      const bodyHistory = {
        user: response.data.id,
        model_name: props.nome,
        model_type: "Produto",
        operation: "-",
      };
      await api.post("/api/editionhistory/", bodyHistory);
      await api.delete("api/produtos/" + id + "/");
      toast.success("O produto foi deletado com sucesso!", toastProps);
    } catch (error) {
      toast.error("Erro ao deletar o produto!", toastProps);
    }
    setDeletarProdutoIsOpen(false);
    getProducts();
  };

  let ingredientesId = [];

  const eventhandlerIngrediente = (data) => {
    ingredientesId = data;
  };

  function ListaIngredientes() {
    return (
      <ul>
        {ingredientes.map((ingrediente, index) => {
          return (
            <ol key={index}>
              {ingrediente.nome} <b>{ingrediente.score}</b>
            </ol>
          );
        })}
      </ul>
    );
  }

  function ListaIngredientesNovos() {
    return (
      <ul>
        {ingredientesNovos.map((ingrediente, index) => {
          return (
            <ol key={index}>
              {ingrediente.nome} <b>{ingrediente.score}</b>
            </ol>
          );
        })}
      </ul>
    );
  }

  const getComentarios = async () => {
    try {
      const response = await api.get(url + "reviews/");
      setComentariosIsOpen(true);
      setComentarios(response.data.results);
    } catch (error) {
      setMensagemErro(error.message);
    }
  };

  const cancelarDeletar = (e) => {
    e.preventDefault();
    setDeletarProdutoIsOpen(false);
  };

  useEffect(() => {
    let categoriasId = [];

    categoriasId[0] = categoriaAlterada?.id;

    subCategoriaAlterada.forEach((subcategoria) => {
      categoriasId.push(subcategoria.id);
    });

    setCategoriasFinais(categoriasId);
  }, [subCategoriaAlterada, categoriaAlterada]);

  return (
    <ListElement>
      <ContainerHeaderTable
        width="60"
        minWidth="32"
        justify="flex-start"
        marginLeft={30}
        marginRight={10}
      >
        <TextTable fontSize="18" onClick={() => getInfoProduto()}>
          {nomeReduzido}
        </TextTable>
      </ContainerHeaderTable>
      <ContainerHeaderTable
        justify="center"
        width="29"
        minWidth="90"
        marginLeft={0}
        marginRight={10}
      >
        <TextTable fontSize="18" onClick={() => getInfoProduto()}>
          {props.marca}
        </TextTable>
      </ContainerHeaderTable>
      <ContainerHeaderTable
        width="15"
        minWidth="90"
        justify="center"
        marginRight={10}
        marginLeft={0}
      >
        <TextTable fontSize="18" onClick={() => getInfoProduto()}>
          Pontuação: <b className={`${tipoDeScore} score`}>{props.score}</b>
        </TextTable>
      </ContainerHeaderTable>
      <ContainerHeaderTable
        // width="15"
        minWidth="90"
        justify="space-evenly"
        marginLeft={0}
        marginRight={30}
      >
        <IconImage
          width={20}
          height={20}
          src={edit}
          onClick={() => getInfoProduto()}
        ></IconImage>
        <IconImage
          width={20}
          height={20}
          src={Cancelar}
          onClick={(e) => setDeletarProdutoIsOpen(true)}
        ></IconImage>
      </ContainerHeaderTable>

      {deletarProdutoIsOpen ? (
        <Modal
          container="containerAvisoDeletar"
          onClose={() => setDeletarProdutoIsOpen(false)}
        >
          <h2 id="textoDeletar">
            Deseja mesmo deletar este produto de forma definitiva? Ele será
            deletado da Lista de Produtos e Lista de Categorias.
          </h2>
          <div id="botoesDeletar">
            <button id="deletar" onClick={(e) => deletarProduto(e, props.id)}>
              SIM
            </button>
            <button id="deletar" onClick={(e) => cancelarDeletar(e)}>
              NÃO
            </button>
          </div>
        </Modal>
      ) : null}
      {modalIsOpen ? (
        <Modal
          container="containerAdicionar"
          onClose={() => setModalcloseAdviceIsOpen(true)}
        >
          <p style={{ fontSize: "20px", fontWeight: "bold" }}>Editar Produto</p>
          <label htmlFor="nome">Nome</label>
          <TextDivModal height="40">
            <TextAreaModal
              id="nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              style={{ width: 300 }}
            />
          </TextDivModal>
          {props.imagem && props.imagem !== "" ? (
            <img
              src={props.imagem}
              alt={props.nome}
              style={{ width: "200px", height: "200px" }}
            />
          ) : null}
          <TextoClicavel>
            <button type="button" onClick={() => setAlterarImagem(true)}>
              Alterar Imagem
            </button>
          </TextoClicavel>
          {alterarImagem ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <input
                type="file"
                onChange={(event) => setImagem(event.target.files[0])}
                accept="image/*"
              />
              <TextoClicavel>
                <button type="button" onClick={() => putImagem()}>
                  Confimar Imagem
                </button>
              </TextoClicavel>
              <h6 style={{ size: "10px", margin: "0px" }}>{feedbackImagem}</h6>
            </div>
          ) : null}
          <Numeros>
            <p>Score: {score}</p>
            <p>Número de likes: {likes}</p>
            <p>
              Número de comentários:
              <button
                type="button"
                onClick={() => getComentarios(true)}
                style={{
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  border: "none",
                  margin: 0,
                }}
              >
                {numeroComentarios}
              </button>
            </p>
            <p>Média: {media}</p>
          </Numeros>
          <form>
            {/* <label htmlFor="marca">Score</label>
            <TextDivModal height="40" style={{ width: "60px" }}>
              <TextInputModal
                type="number"
                id="score"
                value={novoScore}
                onChange={(event) => setNovoScore(event.target.value)}
              />
            </TextDivModal> */}
            <label htmlFor="marca">
              Marca<strong style={{ color: "red" }}>*</strong>
            </label>
            <TextDivModal height="40">
              <TextAreaModal
                type="text"
                id="marca"
                value={marca}
                onChange={(event) => setMarca(event.target.value)}
              />
            </TextDivModal>
            <label htmlFor="volume">Volume</label>
            <TextDivModal height="40">
              <TextAreaModal
                type="text"
                id="volume"
                value={volume || ""}
                onChange={(event) => setVolume(event.target.value)}
              />
            </TextDivModal>

            <label htmlFor="upc">
              Código de Barras<strong style={{ color: "red" }}>*</strong>
            </label>
            <TextDivModal height="40">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="upc"
                value={upc || ""}
                onChange={(event) => setUpc(event.target.value)}
              />
            </TextDivModal>
            <label htmlFor="certificados">Selos</label>
            <ul>
              {certificadosFinais.length ? (
                certificadosFinais.map((data, index) => {
                  return <ol key={index}>{data.nome}</ol>;
                })
              ) : certificados.length ? (
                certificados.map((data, index) => {
                  return <ol key={index}>{data.nome}</ol>;
                })
              ) : (
                <ol>Nenhum Selo cadastrado</ol>
              )}
            </ul>
            <TextoClicavel>
              <button
                type="button"
                onClick={() => setAlterarCertificadosIsOpen(true)}
              >
                Alterar Certificados
              </button>
            </TextoClicavel>
            <label htmlFor="categorias">Categoria</label>
            {categoriaFoiAlterada ? (
              <>
                <ol style={{ fontSize: 14 }}>{categoriaAlterada.nome}</ol>
                <br />
              </>
            ) : categoria ? (
              <>
                <ol style={{ fontSize: 14 }}>{categoria.nome}</ol>
                <br />
              </>
            ) : (
              <>
                <ol>Não há categorias</ol>
                <br />
              </>
            )}

            <label htmlFor="categorias">Subcategorias</label>
            <ul style={{ border: "solid #E6AC86", borderRadius: "10px" }}>
              {categoriaFoiAlterada ? (
                subCategoriaAlterada.map((categorias) => {
                  return <ol key={categorias.id}>- {categorias.nome}</ol>;
                })
              ) : subCategorias && subCategorias.length > 0 ? (
                subCategorias.map((categorias) => {
                  return <ol key={categorias.id}>- {categorias.nome}</ol>;
                })
              ) : (
                <ol>Nenhuma subcategoria cadastrada</ol>
              )}
            </ul>
            <TextoClicavel>
              <button
                type="button"
                onClick={() => setAlterarCategoriasIsOpen(true)}
              >
                Alterar Categorias
              </button>
            </TextoClicavel>
            <label htmlFor="ingredientes">Ingredientes</label>
            {ingredienteAlterado ? (
              <ListaIngredientesNovos />
            ) : (
              <ListaIngredientes />
            )}
            <TextoClicavel>
              <button
                type="button"
                onClick={() => setAlterarIngredientesIsOpen(true)}
              >
                Alterar Ingredientes
              </button>
            </TextoClicavel>
            {/* <TextoClicavel>
              <button
                type="button"
                onClick={() => setAdicionarIngredientesIsOpen(true)}
              >
                Adicionar Novo Ingrediente
              </button>
            </TextoClicavel> */}
            <input
              style={{ cursor: "pointer" }}
              type="submit"
              value="Salvar"
              onClick={(e) => (
                alterarProduto(e),
                setIngredienteAlterado(false),
                setCategoriaFoiAlterada(false)
              )}
              className="submitForm"
            />
          </form>
          <p style={{ color: "#ED4337" }}>{mensagemErro}</p>
        </Modal>
      ) : null}

      {alertIsOpen ? (
        <Modal container="containerAviso" onClose={() => setAlertIsOpen(false)}>
          <h4
            style={{
              alignContent: "center",
              justifyContent: "center",
              display: "flex",
              textAlign: "center",
            }}
          >
            Você alterou as informações de {props.nome} com sucesso!
          </h4>
        </Modal>
      ) : null}

      {adicionarCategoriaIsOpen ? (
        <Modal
          container="containerAviso"
          onClose={() => setAdicionarCategoriaIsOpen(false)}
        >
          <p>Adicione uma nova categoria</p>
          <form>
            <label id="nova">Nova Categoria</label>
            <input
              id="nova"
              type="text"
              value={novaCategoria}
              onChange={(event) => setNovaCategoria(event.target.value)}
            />
            <input
              type="button"
              value="Adicionar"
              style={{ cursor: "pointer" }}
              onClick={(e) => adicionarCategoria(e)}
              className="submitForm"
            />
          </form>
        </Modal>
      ) : null}

      {modalcloseAdviceIsOpen ? (
        <CloseAdvice
          setModalcloseAdviceIsOpen={setModalcloseAdviceIsOpen}
          setModalIsOpen={setModalIsOpen}
        />
      ) : null}

      {alterarIngredientesIsOpen ? (
        <Modal
          container="containerAdicionar"
          onClose={() => setAlterarIngredientesIsOpen(false)}
        >
          <form>
            <p style={{ fontSize: "20px", fontWeight: "bold" }}>
              Alterar Ingrediente
            </p>
            <TextDivModal style={{ width: "300px" }}>
              <Busca
                url="/api/ingredientes-sw/"
                listOld={ingredientes}
                addAction
                onChange={eventhandlerIngrediente}
              />
            </TextDivModal>
            {/* <p>Ingredientes Anteriormente:</p>
            {ingredientesNovos.length > 0 ? (
              <ListaIngredientesNovos />
            ) : (
              <ListaIngredientes />
            )} */}
            <input
              type="button"
              value="Confirmar"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIngredientesNovos(ingredientesId);
                setAlterarIngredientesIsOpen(false);
                setIngredienteAlterado(true);
                setIngredientes(ingredientesId);
              }}
              className="submitForm"
            />
          </form>
        </Modal>
      ) : null}

      {alterarCategoriasIsOpen ? (
        <Modal
          container="containerAdicionar"
          onClose={() => setAlterarCategoriasIsOpen(false)}
        >
          <form>
            <p style={{ fontSize: "20px", fontWeight: "bold" }}>
              Alterar Categorias
            </p>
            <label htmlFor="categoria">Categoria</label>
            <TextDivModal>
              <Autocomplete
                style={{ width: 300 }}
                options={opcoesCategoria}
                autoHighlight
                getOptionLabel={(option) => option.nome}
                onChange={(selected, values) => setCategoriaAlterada(values)}
                renderOption={(option) => (
                  <React.Fragment>{option.nome}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={null}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </TextDivModal>
            <label htmlFor="categorias">Subcategorias</label>
            <TextDivModal>
              <Autocomplete
                style={{ width: 300 }}
                options={opcoesSubcategoria}
                loadingText={"Carregando Subcategorias..."}
                onChange={(selected, values) => setSubCategoriaAlterada(values)}
                autoHighlight
                multiple
                getOptionLabel={(option) => option?.nome}
                renderOption={(option) => <>{option?.nome}</>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={null}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </TextDivModal>
            <TextoClicavel>
              <button
                type="button"
                onClick={() => setAdicionarCategoriaIsOpen(true)}
              >
                Nova Categoria
              </button>
            </TextoClicavel>
            <p>Categoria Anteriormente:</p>
            {categoria ? <p>{categoria.nome}</p> : <p>Não há categorias</p>}
            <p>Subcategorias Anteriormente:</p>
            <ul style={{ border: "solid #E6AC86", borderRadius: "10px" }}>
              {subCategorias ? (
                subCategorias.map((categorias) => {
                  return <ol key={categorias.id}>{categorias.nome}</ol>;
                })
              ) : (
                <ol>Nenhuma categoria cadastrada</ol>
              )}
            </ul>
            <input
              type="button"
              value="Confirmar"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setAlterarCategoriasIsOpen(false);
                setCategoriaFoiAlterada(true);
              }}
              className="submitForm"
            />
          </form>
        </Modal>
      ) : null}
      {comentariosIsOpen ? (
        <Modal
          container="containerAdicionar"
          onClose={() => setComentariosIsOpen(false)}
        >
          <p>Comentários</p>
          {comentarios.length === 0 && (
            <p>Não há comentários nesse produto ainda</p>
          )}
          {comentarios.map((comentario) => (
            <Comentarios key={comentario.id}>
              <p>
                Título: <b>{comentario.titulo}</b>
              </p>
              <EstiloLinha>
                <p>Nome de usuário: {comentario.usuario}</p>
                <p>{comentario.tempo_decorrido}</p>
              </EstiloLinha>
              <p>Número de likes: {comentario.likes}</p>
              <p>{comentario.texto}</p>
              <button
                style={{
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  border: "none",
                }}
              >
                <DeleteOutlineIcon fontSize="small" color="action" />
              </button>
            </Comentarios>
          ))}
        </Modal>
      ) : null}

      {alterarCertificadosIsOpen ? (
        <Modal
          container="containerAdicionar"
          onClose={() => setAlterarCertificadosIsOpen(false)}
        >
          <form>
            <p style={{ fontSize: "20px", fontWeight: "bold" }}>
              Alterar Certificados
            </p>
            <label htmlFor="certificados">Selos</label>
            <TextDivModal>
              <Autocomplete
                style={{ width: 300 }}
                options={opcoesCertificados}
                autoHighlight
                multiple
                getOptionLabel={(option) => option?.nome}
                onChange={(selected, values) => setCertificadosFinais(values)}
                renderOption={(option) => (
                  <React.Fragment>{option?.nome}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={null}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </TextDivModal>

            <p>Selos Anteriormente:</p>
            <ul>
              {certificados.length ? (
                certificados.map((data, index) => {
                  return <ol key={index}>{data.nome}</ol>;
                })
              ) : (
                <ol>Nenhum selo cadastrado</ol>
              )}
            </ul>
            <input
              type="button"
              value="Confirmar"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setAlterarCertificadosIsOpen(false);
              }}
              className="submitForm"
            />
          </form>
        </Modal>
      ) : null}
    </ListElement>
  );
}
export default BoxProduto;
