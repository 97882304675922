import React, { useState, useEffect } from "react";
import { ListElement } from "./box-lista-style.js";
import "./score-style.css";
import "../../global-styles.css";
import api from "../../services/api.js";
import Modal from "../modal/modal.js";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import Cancelar from "../../assets/cancelar.svg";
import edit from "../../assets/editTable.svg";
import CreateIcon from "@material-ui/icons/Create";
import { toast } from "react-toastify";
import { toastProps } from "../../utils/toastProps.js";
import { TextAreaModal, TextDivModal } from "../../components/TextField/styles";
import { ContainerHeaderTable } from "../HeaderTable/styles.js";
import { TextTable, IconImage } from "./styles.js";
import CloseAdvice from "../CloseAdvice/index.js";

const getUrl = (id) => {
  return `/api/ingredientes/${id}/produtos/`;
};

function BoxIngredientes({ getIngredientes, ...props }) {
  let tipoDeScore;
  if (!props.score) {
    tipoDeScore = "NA";
  } else if (props.score >= 8) {
    tipoDeScore = "Risco";
  } else if (props.score <= 7 && props.score >= 6) {
    tipoDeScore = "Moderado";
  } else if (props.score >= 4 && props.score <= 5) {
    tipoDeScore = "Ok";
  } else if (props.score <= 3) {
    tipoDeScore = "Bom";
  }

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalcloseAdviceIsOpen, setModalcloseAdviceIsOpen] = useState(false);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [descricao, setDescricao] = useState(props.descricao);
  const [impacto, setImpacto] = useState(props.impacto);
  const [score, setScore] = useState(props.score);
  const [sinonimos, setSinonimos] = useState(props.sinonimos);
  const [funcao, setFuncao] = useState(props.funcao);
  const [nome, setNome] = useState(props.nome);
  const [inci, setInci] = useState(props.inci);
  const [cas, setCas] = useState(props.cas);
  const [ec, setEc] = useState(props.ec);
  const [potencialAlergenico, setPotencialAlergenico] = useState(
    props.potencial_alergenico
  );
  const [toxicidade, setToxicidade] = useState(props.toxicidade);
  const [carcinogenese, setCarcinogenese] = useState(props.carcinogenese);
  const [referencias, setReferencias] = useState(props.referencias);
  const [mensagemErro, setMensagemErro] = useState("");
  const [deletarIngredienteIsOpen, setDeletarIngredienteIsOpen] =
    useState(false);
  const [verde, setVerde] = useState(props.verde);
  const [produtos, setProdutos] = useState([]);
  const [produtosNovos, setProdutosNovos] = useState([]);

  const alterarIngrediente = async (e) => {
    e.preventDefault();

    const body = {
      nome: nome,
      descricao: descricao,
      impacto: impacto,
      score: score,
      ec: ec,
      inci: inci,
      cas: cas,
      toxicidade: toxicidade,
      carcinogenese: carcinogenese,
      potencial_alergenico: potencialAlergenico,
      sinonimos: sinonimos,
      funcao: funcao,
      referencias: referencias,
      ingrediente_verde: verde,
    };
    try {
      const response = await api.get("/auth/users/me/");
      const bodyHistory = {
        user: response.data.id,
        model_name: nome,
        model_type: "Ingrediente",
        operation: "~",
      };
      const res = await api.post("/api/editionhistory/", bodyHistory);
      await api.patch("/api/ingredientes/" + props.id + "/", body);
      setAlertIsOpen(true);
    } catch (error) {
      setMensagemErro(error.message);
    }
  };

  const cancelarDeletar = (e) => {
    e.preventDefault();
    setDeletarIngredienteIsOpen(false);
  };

  const getInfoIngredientes = async () => {
    setProdutosNovos([]);
    try {
      console.log(props.id);
      const response = await api.get(getUrl(props.id));
      setProdutos(response.data.results);
    } catch (error) {
      console.log("erro");
    }
  };

  function ListaProdutos() {
    return (
      <ul>
        {produtos?.map((produto, index) => {
          return (
            <ol key={index}>
              {produto.nome} <b>{produto.score}</b>
            </ol>
          );
        })}
      </ul>
    );
  }

  useEffect(() => {
    getInfoIngredientes();
  }, []);

  function ListaProdutosNovos() {
    return (
      <ul>
        {produtosNovos?.map((produto, index) => {
          return (
            <ol key={index}>
              {produto.nome} <b>{produto.score}</b>
            </ol>
          );
        })}
      </ul>
    );
  }

  const deletarIngrediente = async (e, id) => {
    e.preventDefault();
    try {
      const response = await api.get("/auth/users/me/");
      const bodyHistory = {
        user: response.data.id,
        model_name: nome,
        model_type: "Ingrediente",
        operation: "-",
      };
      await api.post("/api/editionhistory/", bodyHistory);
      await api.delete("api/ingredientes/" + id + "/");
      toast.success("Ingrediente excluido com sucesso!", toastProps);
    } catch (error) {
      toast.error("Erro ao excluir ingredientes!", toastProps);
    }
    getIngredientes();
  };

  let nomeReduzido = props.nome.substring(0, 50);

  if (props.nome.length >= 50)
    nomeReduzido = props.nome.substring(0, 50) + "...";

  useEffect(() => {
    if (score > 10) {
      setMensagemErro("Não adicione score maior que 10");
    }
  }, [score]);

  return (
    <ListElement>
      <ContainerHeaderTable
        width="65"
        minWidth="100"
        justify="start"
        marginLeft={30}
        marginRight={10}
      >
        <TextTable onClick={() => setModalIsOpen(true)}>
          {nomeReduzido}
        </TextTable>
      </ContainerHeaderTable>
      <ContainerHeaderTable
        width="25"
        // minWidth="130"
        justify="center"
        marginRight={10}
        marginLeft={0}
      >
        <TextTable id="meio" onClick={() => setModalIsOpen(true)}>
          <b className={`${tipoDeScore} score`}>
            {props.score ?? "Não Avaliado"}
          </b>
        </TextTable>
      </ContainerHeaderTable>
      <ContainerHeaderTable
        // width="15"
        minWidth="90"
        justify="space-evenly"
        marginLeft={0}
        marginRight={30}
      >
        <IconImage
          width={20}
          height={20}
          src={edit}
          onClick={() => setModalIsOpen(true)}
        ></IconImage>
        <IconImage
          width={20}
          height={20}
          src={Cancelar}
          onClick={(e) => setDeletarIngredienteIsOpen(true)}
        ></IconImage>
      </ContainerHeaderTable>
      {deletarIngredienteIsOpen ? (
        <Modal
          container="containerAvisoDeletar"
          onClose={() => setDeletarIngredienteIsOpen(false)}
        >
          <h2 id="textoDeletar">
            Deseja mesmo deletar este ingrediente de forma definitiva? Ele será
            apagado de todos os {produtos.length} produtos em que está presente
            e da Lista de Ingredientes.
          </h2>
          <div id="botoesDeletar">
            <button
              id="deletar"
              onClick={(e) => deletarIngrediente(e, props.id)}
            >
              SIM
            </button>
            <button id="deletar" onClick={(e) => cancelarDeletar(e)}>
              NÃO
            </button>
          </div>
        </Modal>
      ) : null}
      {deletarIngredienteIsOpen ? (
        <Modal
          container="containerAvisoDeletar"
          onClose={() => setDeletarIngredienteIsOpen(false)}
        >
          <h2 id="textoDeletar">
            Deseja mesmo deletar este ingrediente de forma definitiva? Ele será
            apagado de todos os {produtos.length} produtos em que está presente
            e da Lista de Ingredientes.
          </h2>
          <div id="botoesDeletar">
            <button
              id="deletar"
              onClick={(e) => deletarIngrediente(e, props.id)}
            >
              SIM
            </button>
            <button id="deletar" onClick={(e) => cancelarDeletar(e)}>
              NÃO
            </button>
          </div>
        </Modal>
      ) : null}
      {modalIsOpen ? (
        <Modal
          container="containerAdicionar"
          onClose={() => setModalcloseAdviceIsOpen(true)}
        >
          <p style={{ fontSize: "20px", fontWeight: "bold" }}>
            Editar Ingrediente
          </p>
          <label htmlFor="nome">Nome</label>
          <TextDivModal height="50">
            <TextAreaModal
              style={{ width: 300 }}
              type="text"
              id="nome"
              value={nome}
              onChange={(event) => setNome(event.target.value)}
            />
          </TextDivModal>
          <label htmlFor="inci">INCI</label>
          <TextDivModal height="50">
            <TextAreaModal
              style={{ width: 300 }}
              type="text"
              id="inci"
              value={inci}
              onChange={(event) => setInci(event.target.value)}
            />
          </TextDivModal>
          <label htmlFor="cas">CAS</label>
          <TextDivModal height="50">
            <TextAreaModal
              style={{ width: 300 }}
              type="text"
              id="cas"
              value={cas}
              onChange={(event) => setCas(event.target.value)}
            />
          </TextDivModal>
          <label htmlFor="ec">EC</label>
          <TextDivModal height="50">
            <TextAreaModal
              style={{ width: 300 }}
              type="text"
              id="ec"
              value={ec}
              onChange={(event) => setEc(event.target.value)}
            />
          </TextDivModal>
          <label htmlFor="carcinogenese">Carcinogenese</label>
          <TextDivModal height="50">
            <TextAreaModal
              style={{ width: 300 }}
              type="text"
              id="carcinogenese"
              value={carcinogenese}
              onChange={(event) => setCarcinogenese(event.target.value)}
            />
          </TextDivModal>
          <label htmlFor="toxicidade">Toxicidade</label>
          <TextDivModal height="50">
            <TextAreaModal
              style={{ width: 300 }}
              type="text"
              id="toxicidade"
              value={toxicidade}
              onChange={(event) => setToxicidade(event.target.value)}
            />
          </TextDivModal>
          <label htmlFor="potencialAlergico">Potencial Alergenico</label>
          <TextDivModal height="50">
            <TextAreaModal
              style={{ width: 300 }}
              type="text"
              id="potencialAlergico"
              value={potencialAlergenico}
              onChange={(event) => setPotencialAlergenico(event.target.value)}
            />
          </TextDivModal>

          <form
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <label htmlFor="descricao">Descrição</label>
            <TextDivModal height="100">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="descricao"
                multiline
                value={descricao}
                onChange={(event) => setDescricao(event.target.value)}
              />
            </TextDivModal>
            <label htmlFor="Impacto">Impacto</label>
            <TextDivModal height="100">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="Impacto"
                multiline
                value={impacto}
                onChange={(event) => setImpacto(event.target.value)}
              />
            </TextDivModal>
            <label htmlFor="Score">Score</label>
            <TextDivModal height="50">
              <TextAreaModal
                style={{ width: 300 }}
                type="number"
                id="Score"
                value={score}
                InputProps={{ inputProps: { min: 0, max: 10 } }}
                onChange={(event) => setScore(event.target.value)}
              />
            </TextDivModal>
            <label id="Sinonimos">Sinônimos</label>
            <TextDivModal height="50">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="Sinonimos"
                value={sinonimos}
                onChange={(event) => setSinonimos(event.target.value)}
              />
            </TextDivModal>
            <label id="Funcao">Função</label>
            <TextDivModal height="50">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="Funcao"
                value={funcao}
                onChange={(event) => setFuncao(event.target.value)}
              />
            </TextDivModal>
            <label id="Referencias">Referências</label>
            <TextDivModal height="50">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="Referencias"
                value={referencias}
                onChange={(event) => setReferencias(event.target.value)}
              />
            </TextDivModal>
            <div className="div-verde">
              <label htmlFor="nome">Esse ingrediente é verde?</label>
              <input
                type="checkbox"
                id="nome"
                value={verde}
                onChange={() => setVerde(!verde)}
                checked={verde}
              />
            </div>
            <input
              style={{ cursor: "pointer" }}
              type="submit"
              value="Alterar"
              onClick={(e) => alterarIngrediente(e)}
              className="submitForm"
            />
          </form>
          <p style={{ color: "#ED4337" }}>{mensagemErro}</p>
          <label htmlFor="produtos_da_categoria">
            {" "}
            Produtos que possuem esse ingrediente
          </label>
          <div onClick={() => getInfoIngredientes()}>
            {produtosNovos.length > 0 ? ListaProdutosNovos() : ListaProdutos()}
          </div>
          <p></p>
        </Modal>
      ) : null}

      {modalcloseAdviceIsOpen ? (
        <CloseAdvice
          setModalcloseAdviceIsOpen={setModalcloseAdviceIsOpen}
          setModalIsOpen={setModalIsOpen}
        />
      ) : null}

      {alertIsOpen ? (
        <Modal
          container="containerAdicionar"
          onClose={() => setAlertIsOpen(false)}
        >
          <h4>Você alterou as informações de {props.nome} para:</h4>
          <p>
            <b>Descrição Anterior:</b> {props.descricao}{" "}
          </p>
          <p>
            <b>Nova Descrição:</b> {descricao}
          </p>
          <p>
            <b>Impacto Anterior:</b> {props.impacto}
          </p>
          <p>
            <b>Novo Impacto:</b> {impacto}
          </p>
          <p>
            <b>Score Anterior:</b> {props.score}
          </p>
          <p>
            <b>Novo Score:</b> {score}
          </p>
          <p>
            <b>Sinônimos Anteriores:</b> {props.sinonimos}
          </p>
          <p>
            <b>Novos Sinônimos:</b> {sinonimos}
          </p>
          <p>
            <b>Ingrediente Verde anterior:</b> {props.verde ? "Sim" : "Não"}
          </p>
          <p>
            <b>Ingrediente Verde anterior:</b> {verde ? "Sim" : "Não"}
          </p>
        </Modal>
      ) : null}
    </ListElement>
  );
}
export default BoxIngredientes;
