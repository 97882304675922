import styled from 'styled-components';

export const Text=styled.div`

    align-items: center;
    display: flex;
    flex-direction: column;
    
    >label{
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        display: flex;
        align-items: center;
        color: #0E1111;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;        font-style: normal;
        margin-bottom: 20px;
    }

    >p{
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 28px;
        
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;        font-style: normal;
        color: #A5553C;
        margin-bottom: 20px;
        margin-top: 0px;
    }
`