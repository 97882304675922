import React from  'react';
import {ListHistoryElement} from './box-lista-style.js';
import './score-style.css';
import '../../global-styles.css';



function BoxHistorico(props) {
    return(
            <ListHistoryElement>
                <h1><b>{props.nomeUsuario}</b> {props.operaçao} o {props.tipoModelo} {props.nomeModelo} no dia {props.data} às {props.hora}</h1>
            </ListHistoryElement>
    );

}
export default BoxHistorico;