export const TOKEN_KEY = "token";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) ? true : false;

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const onSignIn = token => {
    localStorage.setItem(TOKEN_KEY, token);
};

export const onSignOut = () => {
    localStorage.removeItem(TOKEN_KEY);
};