import React from 'react'
import { SearchContainer, SearchInput } from './styles'

function InputPesquisa({onChange, value, placeholder, type}) {
  return (
    
        <SearchInput onChange = {onChange} value = {value} placeholder = {placeholder} type = {type}></SearchInput>
    
  )
}

export default InputPesquisa