import styled from "styled-components";

export const HeaderTable = styled.div`
/* border: 3px solid red; */
  width: 90vw;
  padding: 0px 10px;
  border-radius: 10px 10px 0px 0px;
  background-color: ${props => props.backgroundColor};
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

export const ContainerHeaderTable = styled.div`
/* border: 1px solid red; */
  display: flex;
  align-items: center;
  width: ${(props) => props.width}%;
  min-width: ${(props) => props.minWidth}px;
  justify-content: ${(props) => (props.justify ? props.justify : "center")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 0)}px;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : 0)}px;
`;

export const ClickableArea = styled.div`
  color: ${(props) => (props.isSelect ? "#c92f2f" : "#000000")};
  cursor: pointer;
`

export const TextHeaderTable = styled.p`
/* border: 1px solid red; */
  font-size: 20px;
  color: white;
  font-weight: bold;
  /* font-size: 1vw; */

`;
