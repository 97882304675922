import React from "react";
import Header from "../../../components/header/header";
import BoxIngredientes from "../../../components/box-listas/box-ingredientes";
import Toast from "../../../components/Toast";
import SearchIcon from "@material-ui/icons/Search";
import {
  Box,
  Tela,
  Buscar,
  InlineStyle,
  BotoesNavegar,
  Paginas,
  InlineStylePages,
} from "./ingredientes-style";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import AdicionarIngrediente from "../../../components/modalAdicionar/adicionarIngrediente";
import Next from "../../../assets/next.svg";
import NamePages from "../../../components/NamePages";
import { Buscar2 } from "../usuarios/usuarios-style";

import {
  ClickableArea,
  ContainerHeaderTable,
  HeaderTable,
  TextHeaderTable,
} from "../../../components/HeaderTable/styles";
import ButtonOrderBy from "../../../components/buttonOrderBy";
import { RowButtonsOrderby } from "../../../components/buttonOrderBy/styles";

function Ingredientes() {
  const [ingrediente, setIngrediente] = useState([]);
  const [busca, setBusca] = useState("");
  //const [ingredienteFiltrado, setIngredienteFiltrado] = useState([]);
  const [numeroDaPagina, setNumeroDaPagina] = useState(1);
  const [numeroTotalPaginas, setNumeroTotalPaginas] = useState(1);
  const [isEmpty, setIsEmpty] = useState(false);
  const [paginas] = useState([]);
  const [orderBySelect, setOrderBySelect] = useState("nome")
  const [typeOrder, setTypeOrder] = useState("ascendent")
  
  
  const onPressButtonOrder = (order)=>{
    console.log("order", order)
    console.log("orderBySelect", orderBySelect) 
    if(order === orderBySelect){
      typeOrder === "ascendent"? setTypeOrder("descendent"): setTypeOrder("ascendent")
    }else{
      setTypeOrder("ascendent")
      setOrderBySelect(order)
    }
  }

  const getIngredientes = () => {
    setBusca("");
    api
      .get("/api/ingredientes-sw/")
      .then(function (response) {
        if (response.data.results.length === 0) {
          setIsEmpty(true);
        }  else {
          setIsEmpty(false);
        }
        setIngrediente(response.data.results);
        setNumeroDaPagina(1);
        setNumeroTotalPaginas(Math.ceil(response.data.count / 15));
        console.log(Math.ceil(response.data.count / 15));
        for (var i = 1; i < response.data.count / 15 + 1; i++) {
          paginas.push(i);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   getIngredientes();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [paginas]);



  async function fetchIngredients() {
    setNumeroDaPagina(1);
    const response = await api.get(
      `/api/ingredientes-sw?ordering=${typeOrder === "ascendent"? "":"-"}${orderBySelect}&page=1&find=${busca}`
    );
    if (response.data.results.length === 0) {
      setIsEmpty(true);
    }  else {
      setIsEmpty(false);
    }
    setIngrediente(response.data.results);
    setNumeroDaPagina(1);
    setNumeroTotalPaginas(Math.ceil(response.data.count / 15));
    console.log(Math.ceil(response.data.count / 15));
    for (var i = 1; i < response.data.count / 15 + 1; i++) {
      paginas.push(i);
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => fetchIngredients(), 500);
    return () => clearTimeout(timer);
  }, [busca, orderBySelect, typeOrder]);

  const PaginaAtual = async (data) => {
    if (data > 0 && data <= numeroTotalPaginas) {
      setNumeroDaPagina(data);
      const response = await api.get(
        `/api/ingredientes-sw?ordering=${typeOrder === "ascendent"? "":"-"}${orderBySelect}&page=${data}&find=${busca}`
      );
      console.log("aqui", response.data.results);
      setIngrediente(response.data.results);
    }
  };

  const SearchPagina = async (data) => {
    if (data === '') {
      setNumeroDaPagina(1);
      const response = await api.get(
        `/api/ingredientes-sw?page=${1}&search=${busca}`
      );
      setIngrediente(response.data.results);
    }

    else {
      setNumeroDaPagina(data);
      const response = await api.get(
        `/api/ingredientes-sw?page=${data}&search=${busca}`
      );
      setIngrediente(response.data.results);
    }
  };

  return (
    <>
      <Toast />
      <Header />
      <Tela>
        <NamePages BackgroundColor={"#E6AC86"} NamePage={"Ingredientes"} />
        <InlineStyle>
          <Buscar>
            <SearchIcon />
            <input
              type="text"
              placeholder="Buscar"
              onChange={(event) => setBusca(event.target.value)}
            />
          </Buscar>
          <AdicionarIngrediente getIngredientes={fetchIngredients} />
        </InlineStyle>
        {isEmpty ? null : (
          <InlineStylePages>
            {numeroDaPagina > 1 && (
              <BotoesNavegar
                type="button"
                onClick={() => PaginaAtual(numeroDaPagina - 1)}
              >
                <img src={Next} alt="Voltar" className="rotateimg180" />
              </BotoesNavegar>
            )}
            <Paginas>
              {numeroDaPagina > 10 ? (
                <button onClick={() => PaginaAtual(1)}>{1}</button>
              ) : null}
              {numeroDaPagina > 10 ? (
                <button onClick={() => PaginaAtual(2)}>{2}</button>
              ) : null}
              {numeroDaPagina > 10 ? <h3>...</h3> : null}
              <button
                onClick={() => PaginaAtual(numeroDaPagina)}
                style={{ backgroundColor: "#E6AC86" }}
              >
                {numeroDaPagina}
              </button>
              {numeroDaPagina + 1 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 1)}>
                  {numeroDaPagina + 1}
                </button>
              )}
              {numeroDaPagina + 2 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 2)}>
                  {numeroDaPagina + 2}
                </button>
              )}
              {numeroDaPagina + 3 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 3)}>
                  {numeroDaPagina + 3}
                </button>
              )}
              {numeroDaPagina + 4 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 4)}>
                  {numeroDaPagina + 4}
                </button>
              )}
              {numeroDaPagina + 5 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 5)}>
                  {numeroDaPagina + 5}
                </button>
              )}
              {numeroDaPagina + 6 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 6)}>
                  {numeroDaPagina + 6}
                </button>
              )}
              {numeroDaPagina + 7 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 7)}>
                  {numeroDaPagina + 7}
                </button>
              )}
              {numeroDaPagina + 8 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 8)}>
                  {numeroDaPagina + 8}
                </button>
              )}
              {numeroDaPagina + 9 > numeroTotalPaginas ? null : (
                <button onClick={() => PaginaAtual(numeroDaPagina + 9)}>
                  {numeroDaPagina + 9}
                </button>
              )}
              {numeroDaPagina !== numeroTotalPaginas &&
              numeroTotalPaginas > 5 ? (
                <h3>...</h3>
              ) : null}
              {numeroDaPagina !== numeroTotalPaginas &&
              numeroTotalPaginas > 5 ? (
                <button onClick={() => PaginaAtual(numeroTotalPaginas)}>
                  {numeroTotalPaginas}
                </button>
              ) : null}
            </Paginas>
            {numeroDaPagina < numeroTotalPaginas && (
              <BotoesNavegar
                type="button"
                onClick={() => PaginaAtual(numeroDaPagina + 1)}
              >
                <img src={Next} alt="Avançar" />
              </BotoesNavegar>
            )}
            <Buscar2>
            <input
              type="number"
              placeholder="Página..."
              onChange={(event) => {SearchPagina(event.target.value)}}
            />
            </Buscar2>
          </InlineStylePages>
        )}
          <RowButtonsOrderby>
          <ButtonOrderBy   
          onPress={()=>{onPressButtonOrder('nome')}}                       
          title={typeOrder === "ascendent"? "clique para ordenar de forma descendente": "clique para ordenar de forma ascendete"}
           isSelected ={orderBySelect === 'nome'? true: false} 
           type="string" 
           ascendent={typeOrder}  
           text="Nome"
           />
          <ButtonOrderBy 
          onPress={()=>{onPressButtonOrder('score')}} 
          title={typeOrder === "ascendent"? "clique para ordenar de forma descendente": "clique para ordenar de forma ascendete"}
          isSelected ={orderBySelect === 'score'? true: false} type="numeric" ascendent={typeOrder}  text="Pontuação"/>
        </RowButtonsOrderby>
        <HeaderTable
          backgroundColor=  "#A5553C"
        >
          <ContainerHeaderTable
            width="65"
            minWidth="100"
            justify="start"
            marginLeft={30}
            marginRight={10}
          >
            {/* <ClickableArea
              title={
                orderBySelect === "nome"
                  ? "Retirar Ordenação"
                  : "Ordenar por Nome"
              }
              isSelect={orderBySelect === "nome" ? true : false}
              onClick={() => {
                orderBy("nome");
              }}
            > */}
              <TextHeaderTable>Nome</TextHeaderTable>
            {/* </ClickableArea> */}
          </ContainerHeaderTable>
          <ContainerHeaderTable
            width="25"
            minWidth="130"
            justify="center"
            marginRight={10}
            marginLeft={0}
          >
            {/* <ClickableArea
              title={
                orderBySelect === "nome"
                  ? "Retirar Ordenação"
                  : "Ordenar por Pontuação"
              }
              isSelect={orderBySelect === "score" ? true : false}
              onClick={() => {
                orderBy("score");
              }}
            > */}
              <TextHeaderTable>Pontuação</TextHeaderTable>
            {/* </ClickableArea> */}
          </ContainerHeaderTable>
          <ContainerHeaderTable
            // width="15"
            minWidth="90"
            justify="center"
            marginLeft={0}
            marginRight={30}
          >
            {/* <ClickableArea> */}
              <TextHeaderTable>Opções</TextHeaderTable>
            {/* </ClickableArea> */}
          </ContainerHeaderTable>
        </HeaderTable>
        {isEmpty ? (
          <h1>Ainda não há dados nesta página</h1>
        ) : (
          <Box>
            {ingrediente.map((ingrediente) => (
              <div key={ingrediente.id}>
                <BoxIngredientes
                  nome={ingrediente.nome}
                  score={ingrediente.score}
                  descricao={ingrediente.descricao}
                  carcinogenese={ingrediente.carcinogenese}
                  ec={ingrediente.ec}
                  inci={ingrediente.inci}
                  cas={ingrediente.cas}
                  toxicidade={ingrediente.toxicidade}
                  potencial_alergenico={ingrediente.potencial_alergenico}
                  impacto={ingrediente.impacto}
                  sinonimos={ingrediente.sinonimos}
                  funcao={ingrediente.funcao}
                  referencias={ingrediente.referencias}
                  id={ingrediente.id}
                  verde={ingrediente.ingrediente_verde}
                  getIngredientes={fetchIngredients}
                />
              </div>
            ))}
          </Box>
        )}
      </Tela>
    </>
  );
}
export default Ingredientes;
