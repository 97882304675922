import React, { useState } from "react";
import "../../global-styles.css";
import { ListCategory, ListElement } from "./box-lista-style";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { makeStyles } from "@material-ui/core/styles";
import Cancelar from "../../assets/cancelar.svg";
import edit from "../../assets/editTable.svg";
import { TextoClicavel } from "../modalAdicionar/adicionar-style.js";
import Modal from "../modal/modal.js";
import api from "../../services/api.js";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import ReactLoading from "react-loading";
import CreateIcon from "@material-ui/icons/Create";
import { toast } from "react-toastify";
import { toastProps } from "../../utils/toastProps";
import { TextAreaModal, TextDivModal } from "../TextField/styles";
import { ContainerHeaderTable } from "../HeaderTable/styles";
import { IconImage, TextTable } from "./styles";

function BoxTrending({ getTrending, ...props }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [alterarImagem, setAlterarImagem] = useState(false);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [deletarTrendingIsOpen, setDeletarTrendingIsOpen] = useState(false);
  const [titulo, setTitulo] = useState(props.nome);
  const [texto, setTexto] = useState(props.texto);
  const [link, setLink] = useState(props.url_insta);
  const [imagem, setImagem] = useState([]);
  const [nomeImagem, setNomeImagem] = useState();
  const [feedbackImagem, setFeedbackImagem] = useState("");
  const [mensagemErro, setMensagemErro] = useState("");
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loading, setLoading] = useState(false);

  const alterarDestaque = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await api.get("/auth/users/me/");
      const bodyHistory = {
        user: response.data.id,
        model_name: props.nome,
        model_type: "Trending",
        operation: "~",
      };
      await api.post("/api/editionhistory/", bodyHistory);
    } catch (error) {
      console.log(error);
    }

    const url = "/api/trending/" + props.id + "/";

    const body = {
      nome: titulo,
      image_url: nomeImagem,
      url_insta: link,
      texto: texto,
    };
    try {
      await api.patch(url, body);
      setAlertIsOpen(true);
    } catch (error) {
      setMensagemErro("Erro ao Alterar Destaque");
    }
    setLoading(false);
  };

  const putImagem = async () => {
    const typeNovo = imagem.type.substring([6]);
    setNomeImagem("");
    setLoadingUpload(true);
    api
      .get("/api/file/" + typeNovo + "/")
      .then((response) => {
        const mediaUrl = response.data.media_url;
        const fileName = response.data.file_name;

        let foo = imagem.slice(0, imagem.size, imagem.type);
        let bar = new File([foo], response.data.file_name, {
          type: imagem.type,
        });

        axios
          .put(`${mediaUrl}`, bar, {
            headers: {
              "x-amz-acl": "public-read",
              "Content-Type": bar.type,
              "Content-Disposition": "attachment",
            },
          })
          .then((res) => {
            setNomeImagem(() => fileName);
            setLoadingUpload(false);
          });
      })

      .catch((err) => console.error(err));
  };

  let nomeReduzido = props.nome.substring(0, 50);

  if (props.nome.length >= 50)
    nomeReduzido = props.nome.substring(0, 50) + "...";

  const deletarTrending = async (event, id) => {
    event.preventDefault();
    try {
      const response = await api.get("/auth/users/me/");
      const bodyHistory = {
        user: response.data.id,
        model_name: props.nome,
        model_type: "Trending",
        operation: "-",
      };
      await api.post("/api/editionhistory/", bodyHistory);
      await api.delete("api/trending/" + id + "/");
      toast.success("O Destaque foi deletado com sucesso!", toastProps);
    } catch (error) {
      toast.error("Erro ao deletar Destaque!", toastProps);
    }
    getTrending();
  };

  return (
    <ListElement>
      <ContainerHeaderTable
        width="80"
        justify="start"
        marginLeft={30}
        marginRight={10}
      >
        <TextTable onClick={() => setModalIsOpen(true)}>{props.nome}</TextTable>
      </ContainerHeaderTable>
      <ContainerHeaderTable
        // width="15"
        minWidth="90"
        justify="space-evenly"
        marginLeft={0}
        marginRight={30}
      >
        <IconImage
          width={20}
          height={20}
          src={edit}
          onClick={() => setModalIsOpen(true)}
        ></IconImage>
        <IconImage
          width={20}
          height={20}
          src={Cancelar}
          onClick={(e) => setDeletarTrendingIsOpen(true)}
        ></IconImage>
      </ContainerHeaderTable>
      {deletarTrendingIsOpen ? (
        <Modal
          container="containerAvisoDeletar"
          onClose={() => setDeletarTrendingIsOpen(false)}
        >
          <h2 id="textoDeletar">
            Deseja mesmo deletar este Trending de forma definitiva?
          </h2>
          <div id="botoesDeletar">
            <button id="deletar" onClick={(e) => deletarTrending(e, props.id)}>
              SIM
            </button>
            <button
              id="deletar"
              onClick={() => setDeletarTrendingIsOpen(false)}
            >
              NÃO
            </button>
          </div>
        </Modal>
      ) : null}

      {modalIsOpen ? (
        <Modal
          container="containerAdicionar"
          onClose={() => setModalIsOpen(false)}
        >
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p style = {{fontSize: '20px', fontWeight: 'bold'}}>Editar Destaque</p>
            <label id="titulo">Título</label>
            <TextDivModal height="40">
              <TextAreaModal
                id="titulo"
                type="text"
                placeholder="Insira um título para seu texto"
                value={titulo}
                onChange={(event) => setTitulo(event.target.value)}
              />
            </TextDivModal>
            {props.imagem && props.imagem !== "" ? (
              <img
                src={props.imagem}
                alt={props.nome}
                style={{ width: "200px", height: "200px", marginTop: "30px" }}
              />
            ) : null}
            <TextoClicavel>
              <button type="button" onClick={() => setAlterarImagem(true)}>
                Alterar Imagem
              </button>
            </TextoClicavel>
            {alterarImagem ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <input
                  type="file"
                  onChange={(event) => setImagem(event.target.files[0])}
                  accept="image/*"
                />
                {loadingUpload && (
                  <ReactLoading
                    type="spokes"
                    color="#E6AC86"
                    height={20}
                    width={20}
                  />
                )}
                <TextoClicavel>
                  <button type="button" onClick={(event) => putImagem(event)}>
                    {" "}
                    Confimar Imagem{" "}
                  </button>
                </TextoClicavel>
                <h6 style={{ size: "10px", margin: "0px" }}>
                  {feedbackImagem}
                </h6>
              </div>
            ) : null}
            {props.texto !== "" && (
              <>
                <label id="texto">Texto</label>
                <textarea
                  id="texto"
                  type="text"
                  placeholder="Insira um texto"
                  value={texto}
                  onChange={(event) => setTexto(event.target.value)}
                />
              </>
            )}
            {props.url_insta !== "" && (
              <>
                <label id="link">Link do Post</label>
                <TextDivModal height="60">
                  <TextAreaModal
                    style={{ width: "100%" }}
                    id="link"
                    type="text"
                    placeholder="Insira o Link para um Post do Instagram"
                    value={link}
                    onChange={(event) => setLink(event.target.value)}
                  />
                </TextDivModal>
              </>
            )}
            <button
              type="submit"
              onClick={(event) => alterarDestaque(event)}
              className="enviar"
            >
              {loading ? (
                <ReactLoading type="spin" color="#fff" height={20} width={20} />
              ) : (
                "Confirmar"
              )}
            </button>
          </form>
          <p style={{ color: "#ED4337" }}>{mensagemErro}</p>
        </Modal>
      ) : null}
      {alertIsOpen ? (
        <Modal container="containerAviso" onClose={() => setAlertIsOpen(false)}>
          <h4
            style={{
              alignContent: "center",
              justifyContent: "center",
              display: "flex",
              textAlign: "center",
            }}
          >
            Você alterou as informações de {props.nome} com sucesso!
          </h4>
        </Modal>
      ) : null}
    </ListElement>
  );
}

export default BoxTrending;
