import styled from 'styled-components';


export const Background = styled.div`
width: 100%;
min-height: calc(100vh - 61px); 
display: flex;
flex-direction: column;
align-items:center;
justify-content: flex-start;
`
export const RowDivision = styled.div`
/* border: 1px solid red; */
width: 100%;
min-height: 250px;
background-color: #879F85;
display: flex;
align-items: center;
justify-content: space-evenly;
flex-wrap: wrap;
padding: 10px 0px;
`

export const AreaDivision = styled.div`
/* border: 1px solid red; */
display: flex;
align-items: center;
justify-content: space-between;
flex-direction: column;
`

export const NameArea = styled.p`
font-size: 20px;
`

export const CircleArea = styled.div`
width: 120px;
height: 120px;
border-radius:50%;
border: 1.5px solid #E6AC86;
display: flex;
align-items: center;
justify-content: center;
box-Shadow: 0px 5px 10px rgba(0, 0, 0, 0.45);
`

export const NumberCircle = styled.p`
font-size: 30px;
color: white;
`





export const FTBackground = styled.div`
flex: 1;
width: 100%;
display: flex;
flex-direction: row;
/* background-color:#E6AC86 ;   */
/* border: 1px solid red; */
/* border-radius: 10px 10px 0px 0px; */

`

export const FTArea = styled.div`
flex: 1;//metade da tela

background-color:#E6AC86 ;
display: flex;
flex-direction: column;
align-items: center;
justify-content: start;
padding: 10px ;
/* border: 1px solid red; */
`

export const FTBackgroundTitle = styled.div`
width: 100%;
height: 40px;
display: flex;
align-items: center;
justify-content: center;
background-color:#E6AC86 ;
/* border-radius: 10px 10px 0px 0px; */
`

export const FTTitle = styled.p`
font-size: 20px;
color: white;
`


export const LastItemsBackground = styled.div`
/* border: 1px solid red; */
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
gap: 15px;
overflow-y:auto ;

`
export const LastItems = styled.div`
width: 90%;
background-color: white;
border-radius: 10px;
padding: 15px 10px;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
cursor: pointer;
`

// export const InfoArea = styled.div`
// width: 100%;
// border: 1px solid red;
// display: flex;
// fle

// `

export const TextItems = styled.p`
font-size: ${props => props.fontSize? props.fontSize: 16}px;
margin-top: ${props => props.marginTop? props.marginTop: 0}px;
margin-bottom: ${props => props.marginBottom? props.marginBottom: 0}px;
margin-left: ${props => props.marginLeft? props.marginLeft: 0}px;
`



