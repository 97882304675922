import React, { useState } from "react";
import "../../global-styles.css";
import Plus from "../../assets/plus.svg";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { Adicionar } from "./adicionar-style";
import api from "../../services/api";
import Modal from "../modal/modal.js";
import { TextField } from "@material-ui/core";
import { toastProps } from "../../utils/toastProps";
import { toast } from "react-toastify";
import ErrorMessage from "../ErrorMessage";
import { TextAreaModal, TextDivModal } from "../TextField/styles";
import { Colors } from "../../utils/theme/constants";

function AdicionarAdmin({ getUsers }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setemail] = useState("");
  const [mensagemErro, setMensagemErro] = useState("");

  const [firstNameErro, setFirstNameErro] = useState(false);
  const [lastNameErro, setLastNameErro] = useState(false);
  const [emailErro, setEmailErro] = useState(false);

  const checkErrors = () => {
    let hasErrors = false;

    if (first_name === "") {
      setFirstNameErro(true);
      hasErrors = true;
    } else {
      setFirstNameErro(false);
    }

    if (last_name === "") {
      setLastNameErro(true);
      hasErrors = true;
    } else {
      setLastNameErro(false);
    }

    if (email === "") {
      setEmailErro(true);
      hasErrors = true;
    } else {
      setEmailErro(false);
    }

    return hasErrors;
  };

  const adicionarAdmin = async (e) => {
    e.preventDefault();

    if (checkErrors()) return;

    const body = {
      first_name: first_name,
      last_name: last_name,
      email: email,
    };
    try {
      e.preventDefault();
      await api.post("/api/admincreate/", body);
      toast.success("Sucesso ao adicionar administrador!", toastProps);
      await api.post("/auth/users/reset_password/", { email: email });
    } catch (error) {
      toast.error("Erro ao adicionar administrador!", toastProps);
    }
    setModalIsOpen(false);
    getUsers();
  };

  return (
    <Adicionar>
      <button
        type="button"
        onClick={() => setModalIsOpen(true)}
        style={{ backgroundColor: "rgb(135,159,133)" }}
      >
        <p style={{ color: Colors.White }}>Adicionar Administrador</p>
        {/* <img src={Plus} alt="Mais" /> */}
      </button>

      {modalIsOpen ? (
        <Modal
          container="containerAdicionar"
          onClose={() => setModalIsOpen(false)}
        >
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: "20px", fontWeight: "bold" }}>
              Adicionar Usuário Administrador
            </p>
            <label htmlFor="first_name">
              Nome<strong style={{ color: "red" }}>*</strong>
            </label>
            <TextDivModal height="40">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="first_name"
                value={first_name}
                onChange={(event) => setFirstName(event.target.value)}
              />
            </TextDivModal>
            <ErrorMessage isVisible={firstNameErro} message="Preencha o nome" />
            <label htmlFor="last_name">
              Sobrenome<strong style={{ color: "red" }}>*</strong>
            </label>
            <TextDivModal height="40">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="last_name"
                value={last_name}
                onChange={(event) => setLastName(event.target.value)}
              />
            </TextDivModal>
            <ErrorMessage
              isVisible={lastNameErro}
              message="Preencha o sobrenome"
            />
            <label htmlFor="email">
              E-mail<strong style={{ color: "red" }}>*</strong>
            </label>
            <TextDivModal height="40">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="email"
                value={email}
                onChange={(event) => setemail(event.target.value)}
              />
            </TextDivModal>
            <ErrorMessage isVisible={emailErro} message="Preencha o email" />
            <input
              type="submit"
              value="Adicionar"
              onClick={(e) => adicionarAdmin(e)}
              style={{ cursor: "pointer" }}
              className="submitForm"
            />
          </form>
          <p style={{ color: "#ED4337" }}>{mensagemErro}</p>
        </Modal>
      ) : null}
    </Adicionar>
  );
}
export default AdicionarAdmin;
