import React, {useState} from  'react';
import '../../global-styles.css';
import Modal from 'react-modal';
import {getToken} from '../../services/auth';
import Plus from '../../assets/plus.svg';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Close from '../../assets/close.svg';
import {Adicionar, InlineStyle} from './adicionar-style';
import api from '../../services/api';
import { TextField } from '@material-ui/core';
import { toast } from 'react-toastify';
import { toastProps } from '../../utils/toastProps';
import ErrorMessage from '../ErrorMessage';
import { TextAreaModal, TextDivModal } from '../TextField/styles';
import { width } from '@material-ui/system';
import { Colors } from '../../utils/theme/constants';

Modal.setAppElement('#root');

function AdicionarFAQ({getFaqs}){
 
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [pergunta, setPergunta] = useState("")
    const [resposta, setResposta] = useState("");

    const [askErro, setAskErro] = useState(false);
    const [answerErro, setaAnswerErro] = useState(false);


    const checkErrors = () => {
      let hasErrors = false;
      
      if (pergunta === "") {
        setAskErro(true);
        hasErrors = true;
      } else {
        setAskErro(false);
      }
  
      if(resposta === ""){
        setaAnswerErro(true)
        hasErrors = true;
      }else{
        setaAnswerErro(false)
      }

      return hasErrors;
    };



    const  adicionarFAQ = async (e) => {
        e.preventDefault();

        if(checkErrors()) return;

        const body = {
            "pergunta": pergunta,
            "resposta": resposta,
        };
        try{
            e.preventDefault();
            await api.post('api/faq/', body);
            toast.success('FAQ adicionada com sucesso!', toastProps);

        }catch(error){
            toast.error('Erro ao adicionar FAQ!', toastProps);
        }
        setModalIsOpen(false)
        getFaqs()
    };

    return(
        <Adicionar >
            <InlineStyle>
            <button type="button" onClick={()=> setModalIsOpen(true)} style={{backgroundColor:'rgba(206, 147, 43, 0.8)'}}>
                <p style={{color: Colors.White }}>Adicionar FAQ</p>
                {/* <img src={Plus} alt="Mais" /> */}
            </button>
            </InlineStyle>
        <Modal isOpen={modalIsOpen} onRequestClose={()=> setModalIsOpen(false)}
            style={
                {overlay:{
                    backgroundColor: 'rgba(153, 153, 153, 0.5)'
                },
                content: {
                    marginLeft: '25vw',
                    width: '500px',
                    border: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    backgroundColor: '#efefef'
                }
                }
            }
        >
           
            <button 
                onClick={()=> setModalIsOpen(false)}
                style={{
                    width: '20px',
                    height: '20px',
                    backgroundColor: 'transparent',
                    border:'none',
                    display:'flex',
                    alignItems: 'center',
                    marginLeft: 'auto'
                    }}
            >
                <img 
                    src={Close} 
                    alt="Fechar"
                    style={{
                        width: '10px',
                        height: '10px',
                        position: 'right'                        
                    }}
                />
            </button>
            <p style = {{fontSize: '20px', fontWeight: 'bold'}}>Adicionar FAQ</p>
            <form style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <label htmlFor="pergunta">Pergunta<strong style={{ color: "red" }}>*</strong></label>
                <TextDivModal height = '60'>
                <TextAreaModal
                    style={{ width: 300, marginBottom: '50px'}} 
                    type="text"
                    id="pergunta"
                    value={pergunta}
                    onChange={(event)=> setPergunta(event.target.value)}
                />
                </TextDivModal>
                      <ErrorMessage isVisible={askErro} message="Preencha a pergunta" />

                <label htmlFor="resposta">Resposta<strong style={{ color: "red" }}>*</strong></label> 
                <TextDivModal height = '80'>
                <TextAreaModal
                    style={{ width: 300, marginBottom: '50px' }} 
                    type="text" 
                    id="resposta" 
                    value={resposta}
                    onChange={(event)=> setResposta(event.target.value)}
                /> 
                </TextDivModal>
                <ErrorMessage isVisible={answerErro} message="Preencha a resposta" />
                <input 
                    type="submit" 
                    value="Adicionar" 
                    onClick={(e)=> adicionarFAQ(e) }
                    style={
                        {color: '#fff', 
                        backgroundColor: 'rgb(206, 147, 43)', 
                        borderRadius: '10px',
                        margin: '15px',
                        width: '50%',
                        border:'none',
                        cursor:'pointer'                   
                    }}
                />
            </form>
        </Modal>
        </Adicionar>
    )
}
export default AdicionarFAQ;