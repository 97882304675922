import React, { useEffect, useState } from "react";
import { ListElement, ListUsersElement } from "./box-lista-style.js";
import "./score-style.css";
import "../../global-styles.css";
import api from "../../services/api.js";
import Cancelar from "../../assets/cancelar.svg";
import Confirmar from "../../assets/confirm.svg";
import edit from "../../assets/editTable.svg";
import doAdmin from "../../assets/doAdmin.png";
import undoAdmin from "../../assets/undoAdmin.png";
import editar from "../../assets/edit.svg";
import ModalDev from "../modal/modal.js";
import { toast } from "react-toastify";
import { toastProps } from "../../utils/toastProps.js";
import Modal from "../modal/modal.js";
import CreateIcon from "@material-ui/icons/Create";
import { TextAreaModal, TextDivModal } from "../TextField/styles.js";

import {
  BackgroundModal,
  ButtonArea,
  InputContainer,
  InputForModal,
  LabelForInput,
  RowContainer,
  IconImage,
  ButtonIcon,
  TextTable,
} from "./styles.js";
import { ContainerHeaderTable } from "../HeaderTable/styles.js";
import { async } from "q";

function BoxUsuarios({
  first_name,
  last_name,
  email,
  admin,
  id,
  superAdmin,
  getUsers,
  updateUser,
}) {
  const [bloquearUserIsOpen, setBloquearUserIsOpen] = useState(false);
  const [habilitarUserIsOpen, setHabilitarUserIsOpen] = useState(false);
  const [modalEditUserIsOpen, setModalEditUserIsOpen] = useState(false);
  const [modalDeleteUserIsOpen, setModalDeleteUserIsOpen] = useState(false);

  const [firstNameState, setFirstNameState] = useState(first_name);
  const [lastNameState, setLastNameState] = useState(last_name);
  const [emailState, setEmailState] = useState(email);

  const bloquearUser = async (e, id) => {
    try {
      e.preventDefault();
      const body = {
        is_staff: false,
      };
      await api.patch(`/auth/users/${id}/`, body);
      toast.success("O usuário foi bloqueado com sucesso!", toastProps);
    } catch (error) {
      toast.error("Erro ao bloquear usuário!", toastProps);
    }
    setBloquearUserIsOpen(false);
    getUsers();
  };

  const cancelarBloquear = (e) => {
    e.preventDefault();
    setBloquearUserIsOpen(false);
  };

  const habilitarUser = async (e, id) => {
    try {
      e.preventDefault();
      const body = {
        is_staff: true,
      };
      await api.patch(`/auth/users/${id}/`, body);
      toast.success("O usuário foi promovido a administrador!", toastProps);
    } catch (error) {
      toast.error("Erro ao promover usuário!", toastProps);
    }
    setHabilitarUserIsOpen(false);
    getUsers();
  };

  const cancelarHabilitar = (e) => {
    e.preventDefault();
    setHabilitarUserIsOpen(false);
  };

  const deleteUser = async () => {
    try {
      const response = await api.delete(`/api/usuarios/${id}/deletar/`);
      console.log("response", response.data);
      toast.success("O usuário foi deletado com sucesso!", toastProps);
      getUsers();
    } catch (error) {
      toast.error("Erro ao deletar usuário!", toastProps);
    } finally {
      setModalDeleteUserIsOpen(false);
    }
  };

  useEffect(() => {
    console.log(superAdmin, "1");
  }, []);

  return (
    <ListElement>
      <ContainerHeaderTable
        justify="start"
        marginLeft={30}
        marginRight={10}
        width={38}
      >
        <TextTable>
          <b>{first_name + " " + last_name}</b>
        </TextTable>
      </ContainerHeaderTable>
      <ContainerHeaderTable
        justify="start"
        marginRight={10}
        width={20}
        minWidth={300}
      >
        <TextTable>{email}</TextTable>
      </ContainerHeaderTable>

      <ContainerHeaderTable
        justify="center"
        marginRight={10}
        width={20}
        minWidth={200}
      >
        <TextTable>
          <b>
            {admin} {superAdmin}
          </b>
        </TextTable>
      </ContainerHeaderTable>
      <ContainerHeaderTable
        // width="15"
        minWidth="130"
        justify="flex-start"
        marginLeft={0}
        marginRight={30}
      >
        <IconImage
          title="Editar Usuário"
          width={20}
          height={20}
          src={edit}
          style={{ marginRight: "30px" }}
          onClick={() => setModalEditUserIsOpen(true)}
        ></IconImage>
        {admin === "Administrador" ? (
          <IconImage
            title="Tirar administrador"
            width={30}
            height={30}
            src={undoAdmin}
            style={{ marginRight: "30px" }}
            onClick={() => setBloquearUserIsOpen(true)}
          ></IconImage>
        ) : admin === "" && superAdmin === "" ? (
          <IconImage
            title="Tornar administrador"
            width={30}
            height={30}
            src={doAdmin}
            style={{ marginRight: "30px" }}
            onClick={() => setHabilitarUserIsOpen(true)}
          ></IconImage>
        ) : null}

        {!superAdmin ? (
          <IconImage
            title="Excluir Usuário"
            width={20}
            height={20}
            src={Cancelar}
            onClick={() => setModalDeleteUserIsOpen(true)}
          ></IconImage>
        ) : (
          <></>
        )}
      </ContainerHeaderTable>
      {bloquearUserIsOpen ? (
        <ModalDev
          container="containerAvisoDeletar"
          onClose={() => setBloquearUserIsOpen(false)}
        >
          <h2 id="textoDeletar">Deseja mesmo bloquear este usuário?</h2>
          <div id="botoesDeletar">
            <button id="deletar" onClick={(e) => bloquearUser(e, id)}>
              SIM
            </button>
            <button id="deletar" onClick={(e) => cancelarBloquear(e)}>
              NÃO
            </button>
          </div>
        </ModalDev>
      ) : null}
      {habilitarUserIsOpen ? (
        <ModalDev
          container="containerAvisoDeletar"
          onClose={() => setHabilitarUserIsOpen(false)}
        >
          <h2 id="textoDeletar">
            Deseja mesmo habilitar este usuário como administrador?
          </h2>
          <div id="botoesDeletar">
            <button id="deletar" onClick={(e) => habilitarUser(e, id)}>
              SIM
            </button>
            <button id="deletar" onClick={(e) => cancelarHabilitar(e)}>
              NÃO
            </button>
          </div>
        </ModalDev>
      ) : null}
      {modalDeleteUserIsOpen ? (
        <ModalDev
          container="containerAvisoDeletar"
          onClose={() => setModalDeleteUserIsOpen(false)}
        >
          <h2 id="textoDeletar">
            Deseja mesmo excluir permanentemente esse usuário?
          </h2>
          <div id="botoesDeletar">
            <button
              id="deletar"
              onClick={() => {
                deleteUser();
              }}
            >
              SIM
            </button>
            <button
              id="deletar"
              onClick={() => setModalDeleteUserIsOpen(false)}
            >
              NÃO
            </button>
          </div>
        </ModalDev>
      ) : null}
      {modalEditUserIsOpen ? (
        <Modal
          container="containerAdicionar"
          onClose={() => setModalEditUserIsOpen(false)}
        >
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: "20px", fontWeight: "bold" }}>
              Adicionar usuário administrador
            </p>
            <label htmlFor="first_name">
              Nome<strong style={{ color: "red" }}>*</strong>
            </label>
            <TextDivModal height="40">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="first_name"
                value={firstNameState}
                onChange={(event) => setFirstNameState(event.target.value)}
              />
            </TextDivModal>
            {/* <ErrorMessage isVisible={firstNameErro} message="Preencha o nome" /> */}
            <label htmlFor="last_name">
              Sobrenome<strong style={{ color: "red" }}>*</strong>
            </label>
            <TextDivModal height="40">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="last_name"
                value={lastNameState}
                onChange={(event) => setLastNameState(event.target.value)}
              />
            </TextDivModal>
            {/* <ErrorMessage isVisible={lastNameErro} message="Preencha o sobrenome" /> */}
            <label htmlFor="email">
              E-mail<strong style={{ color: "red" }}>*</strong>
            </label>
            <TextDivModal height="40">
              <TextAreaModal
                style={{ width: 300 }}
                type="text"
                id="email"
                value={emailState}
                onChange={(event) => setEmailState(event.target.value)}
              />
            </TextDivModal>
            {/* <ErrorMessage isVisible={emailErro} message="Preencha o email" /> */}
            <input
              type="submit"
              value="Adicionar"
              onClick={(e) => {
                updateUser(id, firstNameState, lastNameState, emailState);
                setModalEditUserIsOpen(false);
              }}
              style={{ cursor: "pointer" }}
              className="submitForm"
            />
          </form>
        </Modal>
      ) : null}
    </ListElement>
  );
}
export default BoxUsuarios;
