import React from "react";
import Header from "../../../components/header/header";
import { useEffect, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import api from "../../../services/api";
import Toast from "../../../components/Toast";
import AdicionarSelo from "../../../components/modalAdicionar/adicionarSelo";
import {
  Box,
  Tela,
  Buscar,
  InlineStyle,
} from "../ingredientes/ingredientes-style";
import BoxSelos from "../../../components/box-listas/box-selos";
import NamePages from "../../../components/NamePages";
import {
  HeaderTable,
  TextHeaderTable,
  ClickableArea,
  ContainerHeaderTable,
} from "../../../components/HeaderTable/styles";
import { RowButtonsOrderby } from "../../../components/buttonOrderBy/styles";
import ButtonOrderBy from "../../../components/buttonOrderBy";

function Selos() {
  const [selos, setSelos] = useState([]);
  const [selosFiltradas, setSelosFiltradas] = useState([]);
  const [busca, setBusca] = useState("");
  const [orderBySelect, setOrderBySelect] = useState("nome");
  const [typeOrder, setTypeOrder] = useState("ascendent")
  
  const onPressButtonOrder = (order)=>{
    console.log("order", order)
    console.log("orderBySelect", orderBySelect) 
    if(order === orderBySelect){
      typeOrder === "ascendent"? setTypeOrder("descendent"): setTypeOrder("ascendent")
    }else{
      setTypeOrder("ascendent")
      setOrderBySelect(order)
    }
  }

  const GetSelos = async () => {
    try {
      const res = await api.get(`/api/selos?ordering=${typeOrder === "ascendent"? "":"-"}${orderBySelect}`);
      setSelos(res.data);
      setSelosFiltradas(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetSelos();
  }, [orderBySelect,typeOrder ]);



  useEffect(() => {
    setSelosFiltradas(
      selos.filter((selo) =>
        selo.nome.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(busca.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
      )
    );
  }, [busca, selos]);

  return (
    <>
      <Toast />
      <div>
        <Header />
        <Tela>
          <NamePages BackgroundColor={"#E6AC86"} NamePage={"Selos"} />
          <InlineStyle>
            <Buscar>
              <SearchIcon />
              <input
                type="text"
                placeholder="Buscar"
                onChange={(event) => setBusca(event.target.value)}
              />
            </Buscar>
            <AdicionarSelo GetSelos={GetSelos} />
          </InlineStyle>
          <RowButtonsOrderby>
          <ButtonOrderBy   
          onPress={()=>{onPressButtonOrder('nome')}}                       
          title={typeOrder === "ascendent"? "clique para ordenar de forma descendente": "clique para ordenar de forma ascendete"}
           isSelected ={orderBySelect === 'nome'? true: false} 
           type="string" 
           ascendent={typeOrder}  
           text="Nome"
           />
        </RowButtonsOrderby>
          <HeaderTable
            backgroundColor=  "#A5553C"
          >
            <ContainerHeaderTable
              width="80"
              justify="start"
              marginLeft={30}
              marginRight={10}
            >
              <ClickableArea
                // title={
                //   orderBySelect === "nome"
                //     ? "Retirar Ordenação"
                //     : "Ordenar por Nome"
                // }
                // onClick={() => {
                //   orderBy("nome");
                // }}
                // isSelect={orderBySelect === "nome" ? true : false}
              >
                <TextHeaderTable>Nome</TextHeaderTable>
              </ClickableArea>
            </ContainerHeaderTable>
            <ContainerHeaderTable
            minWidth="90"
            justify="center"
            marginLeft={0}
            marginRight={30}
            >
              <ClickableArea>
                <TextHeaderTable>Opções</TextHeaderTable>
              </ClickableArea>
            </ContainerHeaderTable>
          </HeaderTable>
          <Box>
            {selosFiltradas.map((data) => (
              <div key={data.id}>
                <BoxSelos
                  nome={data.nome}
                  imagem={data.image_url}
                  id={data.id}
                  GetSelos={GetSelos}
                />
              </div>
            ))}
          </Box>
        </Tela>
      </div>
    </>
  );
}
export default Selos;
