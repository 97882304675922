import React from 'react';
import {NavBar, LinkItem} from './header-style';
import { Link } from 'react-router-dom';
import Logo from '../../assets/logo-rotuloNude-nude.png';
import Sair from '../../assets/logout.svg';
import {onSignOut} from '../../services/auth';
import { useLocation } from 'react-router-dom';
import { Colors } from '../../utils/theme/constants';

function Header(){
    const location = useLocation();
    



    return(
        <NavBar>
            <Link to='/Home'>
                <img 
                    src={Logo} 
                    alt="Homepage"
                    width="30" 
                    height="30"              
                />
            </Link>
            <LinkItem color={location.pathname === "/Produtos"? Colors.HeaderText: Colors.White} to='/Produtos'>Produtos</LinkItem>
            <LinkItem color={location.pathname === "/Ingredientes"? Colors.HeaderText: Colors.White} to='/Ingredientes'>Ingredientes</LinkItem>
            <LinkItem color={location.pathname === "/Selos"? Colors.HeaderText: Colors.White} to='/Selos'>Selos</LinkItem>
            <LinkItem color={location.pathname === "/Categorias"? Colors.HeaderText: Colors.White} to='/Categorias'>Categorias</LinkItem>
            <LinkItem color={location.pathname === "/Requisicoes"? Colors.HeaderText: Colors.White} to='/Requisicoes'>Requisições</LinkItem>
            <LinkItem color={location.pathname === "/Trending"? Colors.HeaderText: Colors.White} to='/Trending'>Trending</LinkItem>
            <LinkItem color={location.pathname === "/Usuarios"? Colors.HeaderText: Colors.White} to='/Usuarios'>Usuários</LinkItem>
            <LinkItem color={location.pathname === "/Historico"? Colors.HeaderText: Colors.White} to='/Historico'>Histórico</LinkItem>
            <LinkItem color={location.pathname === "/FAQ"? Colors.HeaderText: Colors.White} to='/FAQ'>FAQ</LinkItem>
            <LinkItem color={location.pathname === "/Rotulometro"? Colors.HeaderText: Colors.White} to='/Rotulometro'>Rotulometro</LinkItem>
            <Link to='/' onClick={()=> {onSignOut()}}>
                <img 
                    src={Sair} 
                    alt="Sair"
                    width="15" 
                    height="15"
                />
            </Link>
        </NavBar>
    )
}
export default Header;