import axios from 'axios';
import {getToken} from './auth.js';

const api = axios.create({

    baseURL: process.env.REACT_APP_API,
    
});

api.interceptors.request.use(async (config) => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }
  return config;
});

export default api;