import React from "react";
import Header from "../../../components/header/header";
import { useEffect, useState } from "react";
import Toast from "../../../components/Toast";
import api from "../../../services/api";
import {
  Tela,
  InlineStyle,
  Box,
  BotoesNavegar,
  Paginas,
  InlineStylePages,
} from "../ingredientes/ingredientes-style";
import Next from "../../../assets/next.svg";
import BoxRequisicao from "../../../components/box-listas/box-requisicao";
import { toast } from "react-toastify";
import { toastProps } from "../../../utils/toastProps";
import NamePages from "../../../components/NamePages";
import { Buscar2 } from "../usuarios/usuarios-style";
import {
  ClickableArea,
  HeaderTable,
  TextHeaderTable,
  ContainerHeaderTable,
} from "../../../components/HeaderTable/styles";
import { BackgroundHeader } from "../../../components/NamePages/styles";
import ButtonOrderBy from "../../../components/buttonOrderBy";
import { RowButtonsOrderby } from "../../../components/buttonOrderBy/styles";

function Requisicoes() {
  const [requisicao, setRequisicao] = useState([]);
  const [numeroDaPagina, setNumeroDaPagina] = useState(1);
  const [numeroTotalPaginas, setNumeroTotalPaginas] = useState(1);
  const [isEmpty, setIsEmpty] = useState(false);
  const [orderBySelect, setOrderBySelect] = useState("nome");
  const [typeOrder, setTypeOrder] = useState("ascendent");

  const onPressButtonOrder = (order) => {
    console.log("order", order);
    console.log("orderBySelect", orderBySelect);
    if (order === orderBySelect) {
      typeOrder === "ascendent"
        ? setTypeOrder("descendent")
        : setTypeOrder("ascendent");
    } else {
      setTypeOrder("ascendent");
      setOrderBySelect(order);
    }
  };

  const getRecomendations = () => {
    console.log(
      "requisicao",
      `/api/sugestoes?ordering=${
        typeOrder === "ascendent" ? "" : "-"
      }${orderBySelect}`
    );
    api
      .get(
        `/api/sugestoes?ordering=${
          typeOrder === "ascendent" ? "" : "-"
        }${orderBySelect}`
      )
      .then(function (response) {
        if (response.data.results.length === 0) {
          setIsEmpty(true);
        } else {
          setIsEmpty(false);
        }
        console.log("\nresposta", response.data.results);
        setRequisicao(
          response.data.results.filter((req) => req.recusado === false)
        );
        console.log(response.data.results, "teste");
        setNumeroDaPagina(1);
        setNumeroTotalPaginas(Math.ceil(response.data.count / 15));
      })
      .catch((err) => {
        toast.error("Erro ao carregar as requisições", toastProps);
      });
  };
  useEffect(() => {
    getRecomendations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBySelect, typeOrder]);

  const PaginaAtual = async (data) => {
    if (data > 0 && data <= numeroTotalPaginas) {
      setNumeroDaPagina(data);
      const response = await api.get(
        `/api/sugestoes?ordering=${
          typeOrder === "ascendent" ? "" : "-"
        }${orderBySelect}&page=${data}`
      );
      setRequisicao(
        response.data.results.filter((req) => req.recusado === false)
      );
      console.log(response.data.results, "testando");
    }
  };

  const SearchPagina = async (data) => {
    if (data === "") {
      setNumeroDaPagina(1);
      const response = await api.get(`/api/sugestoes/?page=${1}`);
      setRequisicao(
        response.data.results.filter((req) => req.recusado === false)
      );
      console.log(response.data.results, "Oi123");
    } else {
      setNumeroDaPagina(data);
      const response = await api.get(`/api/sugestoes/?page=${data}`);
      setRequisicao(
        response.data.results.filter((req) => req.recusado === false)
      );
      console.log(response.data.results, "Oi123");
    }
  };

  return (
    <>
      <Toast />
      <div>
        <Header />
        <NamePages BackgroundColor={"#879F85"} NamePage={"Requisições"} />
        <Tela>
          <InlineStyle></InlineStyle>
          {isEmpty ? null : (
            <InlineStylePages>
              {numeroDaPagina > 1 ? (
                <BotoesNavegar
                  type="button"
                  onClick={() => PaginaAtual(numeroDaPagina - 1)}
                >
                  <img src={Next} alt="Voltar" className="rotateimg180" />
                </BotoesNavegar>
              ) : null}
              <Paginas>
                {numeroDaPagina > 10 ? (
                  <button onClick={() => PaginaAtual(1)}>{1}</button>
                ) : null}
                {numeroDaPagina > 10 ? (
                  <button onClick={() => PaginaAtual(2)}>{2}</button>
                ) : null}
                {numeroDaPagina > 10 ? <h3>...</h3> : null}
                <button
                  onClick={() => PaginaAtual(numeroDaPagina)}
                  style={{ backgroundColor: "rgb(135, 159, 133)" }}
                >
                  {numeroDaPagina}
                </button>
                {numeroDaPagina + 1 > numeroTotalPaginas ? null : (
                  <button onClick={() => PaginaAtual(numeroDaPagina + 1)}>
                    {numeroDaPagina + 1}
                  </button>
                )}
                {numeroDaPagina + 2 > numeroTotalPaginas ? null : (
                  <button onClick={() => PaginaAtual(numeroDaPagina + 2)}>
                    {numeroDaPagina + 2}
                  </button>
                )}
                {numeroDaPagina + 3 > numeroTotalPaginas ? null : (
                  <button onClick={() => PaginaAtual(numeroDaPagina + 3)}>
                    {numeroDaPagina + 3}
                  </button>
                )}
                {numeroDaPagina + 4 > numeroTotalPaginas ? null : (
                  <button onClick={() => PaginaAtual(numeroDaPagina + 4)}>
                    {numeroDaPagina + 4}
                  </button>
                )}
                {numeroDaPagina + 5 > numeroTotalPaginas ? null : (
                  <button onClick={() => PaginaAtual(numeroDaPagina + 5)}>
                    {numeroDaPagina + 5}
                  </button>
                )}
                {numeroDaPagina + 6 > numeroTotalPaginas ? null : (
                  <button onClick={() => PaginaAtual(numeroDaPagina + 6)}>
                    {numeroDaPagina + 6}
                  </button>
                )}
                {numeroDaPagina + 7 > numeroTotalPaginas ? null : (
                  <button onClick={() => PaginaAtual(numeroDaPagina + 7)}>
                    {numeroDaPagina + 7}
                  </button>
                )}
                {numeroDaPagina + 8 > numeroTotalPaginas ? null : (
                  <button onClick={() => PaginaAtual(numeroDaPagina + 8)}>
                    {numeroDaPagina + 8}
                  </button>
                )}
                {numeroDaPagina + 9 > numeroTotalPaginas ? null : (
                  <button onClick={() => PaginaAtual(numeroDaPagina + 9)}>
                    {numeroDaPagina + 9}
                  </button>
                )}
                {numeroDaPagina !== numeroTotalPaginas &&
                numeroTotalPaginas > 5 ? (
                  <h3>...</h3>
                ) : null}
                {numeroDaPagina !== numeroTotalPaginas &&
                numeroTotalPaginas > 5 ? (
                  <button onClick={() => PaginaAtual(numeroTotalPaginas)}>
                    {numeroTotalPaginas}
                  </button>
                ) : null}
              </Paginas>
              {numeroTotalPaginas > numeroDaPagina ? (
                <BotoesNavegar
                  type="button"
                  onClick={() => PaginaAtual(numeroDaPagina + 1)}
                >
                  <img src={Next} alt="Avançar" />
                </BotoesNavegar>
              ) : null}
              <Buscar2>
                <input
                  type="number"
                  placeholder="Página..."
                  onChange={(event) => {
                    SearchPagina(event.target.value);
                  }}
                />
              </Buscar2>
            </InlineStylePages>
          )}
          <RowButtonsOrderby>
            <ButtonOrderBy
              onPress={() => {
                onPressButtonOrder("nome");
              }}
              title={
                typeOrder === "ascendent"
                  ? "clique para ordenar de forma descendente"
                  : "clique para ordenar de forma ascendete"
              }
              isSelected={orderBySelect === "nome" ? true : false}
              type="string"
              ascendent={typeOrder}
              text="Nome"
            />
            <ButtonOrderBy
              onPress={() => {
                onPressButtonOrder("marca");
              }}
              title={
                typeOrder === "ascendent"
                  ? "clique para ordenar de forma descendente"
                  : "clique para ordenar de forma ascendete"
              }
              isSelected={orderBySelect === "marca" ? true : false}
              type="string"
              ascendent={typeOrder}
              text="Marca"
            />
          </RowButtonsOrderby>
          <HeaderTable backgroundColor="#ADB9AB">
            <ContainerHeaderTable
              width="32"
              justify="start"
              marginLeft={30}
              marginRight={10}
            >
              {/* <ClickableArea
                title={
                  orderBySelect === "nome"
                    ? "Retirar Ordenação"
                    : "Ordenar por Nome"
                }
                onClick={() => {
                  orderBy("nome");
                }}
                isSelect={orderBySelect === "nome" ? true : false}
              > */}
              <TextHeaderTable>Nome</TextHeaderTable>
              {/* </ClickableArea> */}
            </ContainerHeaderTable>
            <ContainerHeaderTable
              width="29"
              justify="center"
              marginLeft={0}
              marginRight={10}
            >
              {/* <ClickableArea
                title={
                  orderBySelect === "marca"
                    ? "Retirar Ordenação"
                    : "Ordenar por Marca"
                }
                onClick={() => {
                  orderBy("marca");
                }}
                isSelect={orderBySelect === "marca" ? true : false}
              > */}
              <TextHeaderTable>Marca</TextHeaderTable>
              {/* </ClickableArea> */}
            </ContainerHeaderTable>
            <ContainerHeaderTable
              // width="15"
              minWidth="130"
              justify="space-evenly"
              marginLeft={0}
              marginRight={30}
            >
              {/* <ClickableArea> */}
              <TextHeaderTable>Opções</TextHeaderTable>
              {/* </ClickableArea> */}
            </ContainerHeaderTable>
          </HeaderTable>
          {isEmpty ? (
            <h1>Ainda não há dados nesta página</h1>
          ) : (
            <Box style={{ backgroundColor: "#879F85" }}>
              {requisicao.map((sugestao) => (
                <div key={sugestao.id}>
                  <BoxRequisicao
                    id={sugestao.id}
                    nome={sugestao.nome}
                    marca={sugestao.marca}
                    categoria={sugestao.categoria}
                    descricao={sugestao.descricao}
                    imagem1={sugestao.image1_url}
                    imagem2={sugestao.image_url}
                    score={sugestao.score}
                    ingredientes={sugestao.ingredientes_texto}
                    upc={sugestao.upc}
                    volume={sugestao.volume}
                    name={sugestao.usuario?.first_name}
                    email={sugestao.usuario?.email}
                    getRecomendations={getRecomendations}
                  />
                </div>
              ))}
            </Box>
          )}
        </Tela>
      </div>
    </>
  );
}
export default Requisicoes;
