import styled from 'styled-components';


export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: none;
  height: 50px;
  width: 270px;
  text-align: start;

  border-radius: 30px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;

  background-color: #F6F5F5;
  border: none;
  padding-left: 20px;
  outline: none;
  transition: 0.25s;
   
`
export const SearchInput = styled.div`
    border: none;
    background-color: transparent;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    margin-left: 15px;
    outline: none;
`