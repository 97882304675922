import styled from 'styled-components';

export const Adicionar=styled.div`
    display: flex;
    align-items: center;
    >p{
        margin-right: 10px;
    }
    >button{
        display: flex;
        justify-content: space-around;
        /* width: 230px; */
        height: 50px;
        background-color: #E6AC86;
        border-radius: 20px;
        border: none;
        padding: 20px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        align-items: center;
        >img{
            width: 15px;
            height: 15px;
        }
    }
`

export const InlineStyle=styled.div`
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    align-items: center;
    width: 90vw;
    padding: 10px;
    >p{
        margin-right: 10px;
    }
    >button{
        display: flex;
        justify-content: space-around;
        /* width: 230px; */
        height: 50px;
        background-color: #E6AC86;
        border-radius: 20px;
        border: none;
        padding: 20px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        align-items: center;
        >img{
            border: solid 1px #A5553C;
            border-radius: 50%;
            background-color: #A5553C;
            padding: 7.5px;
            width: 30px;
            height: 30px;
            box-sizing: border-box;
        }
    }
`


export const TextoClicavel = styled.div`
  
   white-space:nowrap;
   margin-top:10px;
   display:flex !important;
   justify-content:center !important;

  

    >button{
        background-color: #E6AC86;
        border: none;
        border-radius: 10px;
        margin: 0 auto 20px auto !important;
        padding: 5px;
        font-size: 13px;
        color: white;
        width:100% !important;
        text-align: center !important;
        
    }
`

