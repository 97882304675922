import styled from "styled-components";

export const Text = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  > label {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #0e1111;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-style: normal;
    margin-bottom: 20px;
  }

  > p {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 28px;

    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-style: normal;
    color: #a5553c;
    margin-bottom: 20px;
    margin-top: 0px;
  }
  > img {
    height: 170px;
    border-radius: 15px;
    margin-bottom: 20px;
  }
`;

export const LoginDiv = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-top: 0px;
  height: 45px;
  width: 300px;
  padding: 0 15px;
  font-size: 16px;
  background: #ffffff;
  border-radius: 10px;
  border-style: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const LoginInput = styled.input`
  height: 45px;
  width: 250px;
  font-size: 16px;
  padding: 0px;
  background: #ffffff;
  border-style: none;
  border: none;
`;
