import styled from 'styled-components';

export const Box=styled.div`
    
    width: 85vw;
    height: 65vh;
    border-radius: 10px;
    padding: 10px;
    
`

export const ListElement=styled.div`
    /* border: 4px solid red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    align-items: center;
    
    >h1{
        color: #0E1111;
        font-size: 20px;
        font-weight: normal;
        /* cursor:pointer; */
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    >button{
        width: 20px;
        height: 20px;
        background-color: transparent;
        border: none;
        margin-left:20px;
        display: flex;
        align-items: center;
        >img{
        width: 15px;
        height: 15px;
        }

    }
    
`

export const Numeros=styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
    padding: 5px;
    >p{
        margin: 2px;
    }
` 


export const ListVertElement=styled.div`
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    justify-content: start;
    padding: 15px 30px 15px;
    margin-bottom: 10px;
    align-items: left;
    
    >h1{
        padding-right: 10px;
        padding-left: 10px;
        color: #0E1111;
        font-size: 18px;
        /* cursor:pointer; */
        font-weight: normal;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        }

   .perg{
       font-weight: bold;
   }

   >p {
       display: flex;
       flex-direction: row;
       justify-content: space-between;
       
        >h1{
            margin: -5px 0px;
            padding-right: 10px;
            padding-left: 10px;
            color: #0E1111;
            font-size: 18px;
            font-weight: normal;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            }

        >button{
            width: 30px;
            height: 30px;
            padding-right: 10px;
            padding-left: 10px;
            background-color: transparent;
            border: none;
            display:flex;
            align-items: center;
            >img{
                width: 10px;
                height: 10px;
            }
        }
     }

`

export const ListUsersElement=styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 10fr 10fr 5fr 1fr;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 0 30px 0;
    margin-bottom: 10px;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    
    >h1{
        color: #0E1111;
        font-size: 18px;
        font-weight: normal;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    button{
        width: 20px;
        height: 20px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        margin-left:20px ;
        >img{
        width: 15px;
        cursor: pointer;
        height: 15px;
        }
    }

`
export const ListCategory=styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* display: grid;
    grid-auto-flow: column;
    grid-template-columns: 15fr 2fr; */
    background-color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 0 30px 0;
    margin-bottom: 10px;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    
    >h1{
        color: #0E1111;
        font-size: 18px;
        font-weight: normal;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    div{
        display: flex;
       align-items:baseline;
    }

    button{
        width: 20px;
        height: 20px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        margin-left:20px ;
        >img{
        width: 15px;
        cursor: pointer;
        height: 15px;
        }
    }

`






export const Comentarios=styled.div`
    display: flex;
    flex-direction: column;
`

export const EstiloLinha = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`

export const ListHistoryElement=styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 0 30px 0;
    margin-bottom: 10px;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    
    >h1{
        color: #0E1111;
        font-size: 18px;
        font-weight: normal;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    button{
        width: 20px;
        height: 20px;
        background-color: transparent;
        border: none;
        margin-left:20px ;
        >img{
        width: 10px;
        height: 10px;
        }
    }

`